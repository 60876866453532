import { combineReducers } from 'redux'
import {
  RECEIVE_INSTALLERS,
  RECEIVE_INSTALLERS_PAGING,
  RECEIVE_RESPONSIBLE_PERSONS,
  RECEIVE_RESPONSIBLE_PERSONS_PAGING,
  RECEIVE_USER,
  RECEIVE_USERS,
  SET_IS_FETCHING,
  SET_PAGING,
  SET_QUERY_FILTER,
} from '../constants/actions'
import defaultPagingValues from 'dashboard/constants/paging'

const companyUsersReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_USERS:
      return [...action.users]
    default:
      return state
  }
}

const userReducer = (state = { user: null, isFetching: false }, action) => {
  switch (action.type) {
    case RECEIVE_USER:
      return { ...state, user: action.user }
    case SET_IS_FETCHING:
      return { ...state, isFetching: action.state }
    default:
      return state
  }
}

const installersReducer = (state = { users: [], paging: defaultPagingValues }, action) => {
  switch (action.type) {
    case RECEIVE_INSTALLERS:
      return { ...state, users: action.users }
    case RECEIVE_INSTALLERS_PAGING:
      return { ...state, paging: action.paging }
    default:
      return state
  }
}

const responsiblePersonsReducer = (
  state = { responsiblePersons: [], responsiblePersonsPaging: defaultPagingValues },
  action
) => {
  switch (action.type) {
    case RECEIVE_RESPONSIBLE_PERSONS:
      return { ...state, responsiblePersons: action.users }
    case RECEIVE_RESPONSIBLE_PERSONS_PAGING:
      return { ...state, responsiblePersonsPaging: action.paging }
    default:
      return state
  }
}

const queryFilterReducer = (query = '', action) => {
  switch (action.type) {
    case SET_QUERY_FILTER:
      return action.query
    default:
      return query
  }
}

const pagingReducer = (state = defaultPagingValues, action) => {
  switch (action.type) {
    case SET_PAGING:
      return {
        ...state,
        ...action.paging,
      }
    default:
      return state
  }
}

export default combineReducers({
  list: companyUsersReducer,
  queryFilter: queryFilterReducer,
  paging: pagingReducer,
  user: userReducer,
  installers: installersReducer,
  responsiblePersons: responsiblePersonsReducer,
})
