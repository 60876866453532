export const SET_FILTER_OPTIONS = 'dashboard/Filter/SET_FILTER_OPTIONS'
export const SET_FILTER_IS_FETCHING = 'dashboard/Filter/SET_FILTER_IS_FETCHING'
export const TOGGLE_FILTER_OPTION = 'dashboard/Filter/TOGGLE_FILTER_OPTION'
export const SET_FILTER_VALUE = 'dashboard/Filter/SET_FILTER_VALUE'
export const RESET_FILTER = 'dashboard/Filter/RESET_FILTER'
export const SELECT_ALL_IN_FILTER = 'dashboard/Filter/SELECT_ALL_IN_FILTER'
export const RESET_ALL_FILTERS = 'dashboard/Filter/RESET_ALL_FILTERS'
export const APPLY_FILTER = 'dashboard/Filter/APPLY_FILTER'
export const RESET_APPLY_FILTER = 'dashboard/Filter/RESET_APPLY_FILTER'
export const SHOW_MORE_FILTER = 'dashboard/Filter/SHOW_MORE_FILTER'
