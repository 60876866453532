import { combineReducers } from 'redux'

import items from './items'
import summary from './summary'
import filters from './filters'
import queryFilter from './queryFilter'
import mapPoints from './mapPoints'
import events from './events'
import paging from './paging'
import mapItems from './mapItems'

export default combineReducers({
  items: items,
  mapItems: mapItems,
  drumSummary: summary,
  filters: filters,
  queryFilter: queryFilter,
  mapPoints: mapPoints,
  events: events,
  paging: paging,
})
