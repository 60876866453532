import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'

import localeDataDa from '../translations/da.json'
import localeDataDe from '../translations/de.json'
import localeDataEn from '../translations/en.json'
import localeDataPl from '../translations/pl.json'
import localeDataSv from '../translations/sv.json'
import localeDataRu from '../translations/ru.json'

const localeData = {
  da: localeDataDa,
  de: localeDataDe,
  en: localeDataEn,
  pl: localeDataPl,
  sv: localeDataSv,
  ru: localeDataRu,
}

export const appLocalesShort = {
  en: 'en',
  da: 'da',
  de: 'de',
  pl: 'pl',
  sv: 'sv',
  ru: 'ru',
}

export const appLocalesFull = {
  en: 'en-GB',
  da: 'da-DK',
  de: 'de-DE',
  pl: 'pl-PL',
  sv: 'sv-SE',
  ru: 'ru-RU',
}

export const fullNameLocale = {
  en: 'English',
  da: 'Dansk',
  de: 'Deutsch',
  pl: 'Polski',
  sv: 'Svenska',
  ru: 'Русский',
}

export const appLocales = keys(appLocalesFull)

export const getMessagesForLocale = (locale) => {
  if (appLocales.indexOf(locale) === -1) {
    console.warn(`Try to use unsupported locale: ${locale}. Take default 'en' locale`)
    locale = appLocales[0]
  }

  if (isEmpty(localeData[locale])) {
    console.warn(`No messages for locale: ${locale}. Take messages for default 'en' locale`)
    return localeData[appLocales[0]]
  }

  return localeData[locale]
}

const getCurrentUserLanguage = () => {
  const language =
    localStorage.getItem('selectedLocale') ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator.userLanguage

  const locale = language.toLowerCase().split(/[_-]+/)[0]

  if (appLocales.indexOf(locale) === -1) {
    console.warn(`Trying to use unsupported locale: ${locale}. Take default 'en' locale`)
    return appLocales[0]
  }

  return locale
}

export const currentUserLanguage = getCurrentUserLanguage()
