import get from 'lodash/get'
import moment from 'moment'

import * as notificationTypes from '../constants/notificationTypes'

export const getX = (message) => {
  const units = message.notificationType === notificationTypes.DELIVERY_ARRIVE_IN_X_HOURS ? 'hours' : 'days'

  return Math.abs(
    moment(
      get(message, 'body.freeRentExpireAt', get(message, 'body.endDate', get(message, 'body.expectedDeliveryDate', 0)))
    ).diff(moment(message.createdAt), units)
  )
}

export const getValuesFromMessage = (message) => ({
  order_name: get(message, 'body.extraParams.orderExternalId', ''),
  project_name: get(message, 'body.extraParams.projectName', ''),
  vendor_name: get(message, 'body.extraParams.senderTitle', ''),
  customer_name: get(message, 'body.extraParams.recipientTitle', ''),
  partner_name: get(message, 'body.extraParams.partnerTitle', ''),
  drum_name: get(message, 'body.extraParams.drumExternalId'),
  user_join_requests: '',
  'company-link-requests': '',
  user_name: get(message, 'body.extraParams.publisherFullName', ''),
  title: get(message, 'body.title', ''),
  x: getX(message),
})

export const mapTypeToRoute = (message) => {
  let route = ''

  switch (message.notificationType) {
    case notificationTypes.DRUM_CABLE_CONSUMED:
    case notificationTypes.DRUM_CABLE_LOW:
    case notificationTypes.DRUM_READY_TO_RETURN:
    case notificationTypes.DRUM_MOVED_OUT:
    case notificationTypes.DRUM_DAMAGED:
    case notificationTypes.DRUM_REJECTED:
    case notificationTypes.DRUM_EXPIRED:
      route = `drums/${message.body.objectId}`
      break
    case notificationTypes.ORDER_CREATED:
    case notificationTypes.ORDER_DISPATCHED:
    case notificationTypes.ORDER_DELIVERED:
    case notificationTypes.ORDER_EXPIRES_IN_X_DAYS:
    case notificationTypes.ORDER_EXPIRES_TODAY:
    case notificationTypes.ORDER_EXPIRED:
      route = `orders/${message.body.objectId}/details`
      break
    case notificationTypes.DELIVERY_CREATED:
    case notificationTypes.DELIVERY_ACCEPTED:
    case notificationTypes.DELIVERY_ARRIVE_IN_X_DAYS:
    case notificationTypes.DELIVERY_ARRIVE_IN_X_HOURS:
      route = `deliveries/${message.body.objectId}/details`
      break
    case notificationTypes.PROJECT_CREATED:
    case notificationTypes.PROJECT_EXPIRES_IN_X_DAYS:
      route = `projects/${message.body.objectId}/details`
      break
    case notificationTypes.PARTNER_LINKED:
      route = 'linked-companies'
      break
    default:
      break
  }

  return route
}
