import has from 'lodash/has'

const cssAnimationDelay = 300

const getGeocoderInstance = (callback) => {
  if (has(window, 'google.maps.Geocoder')) {
    callback(new window.google.maps.Geocoder())
  } else {
    setTimeout(() => getGeocoderInstance(callback), 500)
  }
}

const getGeocoder = () =>
  new Promise((resolve) => {
    getGeocoderInstance((geocoder) => resolve(geocoder))
  })

const getAutocompleteService = (callback) => {
  if (has(window, 'google.maps.places.AutocompleteService')) {
    callback(new window.google.maps.places.AutocompleteService())
  } else {
    setTimeout(() => getAutocompleteService(callback), 500)
  }
}

export function resizeMap(delay = cssAnimationDelay) {
  if (has(window, 'google.maps.instance')) {
    setTimeout(() => {
      window.google.maps.event.trigger(window.google.maps.instance, 'resize')
    }, delay)
  }
}

export const geocodeCoordinates = (latitude, longitude) =>
  getGeocoder().then(
    (geocoder) =>
      new Promise((resolve) => {
        geocoder.geocode(
          {
            location: {
              lat: latitude,
              lng: longitude,
            },
          },
          resolve
        )
      })
  )

export const geocodeAddress = (address) =>
  getGeocoder().then(
    (geocoder) =>
      new Promise((resolve) => {
        geocoder.geocode(
          {
            address,
          },
          resolve
        )
      })
  )

export const suggestAddress = (address) =>
  new Promise((resolve) => {
    getAutocompleteService((service) => resolve(service))
  }).then(
    (service) =>
      new Promise((resolve, reject) =>
        service.getPlacePredictions(
          {
            input: address,
            types: ['address'],
          },
          (suggestions, status) => {
            if (status === 'OK') {
              resolve(suggestions)
            } else {
              reject(new Error(status))
            }
          }
        )
      )
  )
