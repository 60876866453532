import { defineMessages } from 'react-intl';

const messages = defineMessages({
  accessibilityType: {
    id: 'drum-manage.accessibility-type',
    defaultMessage: 'Accessibility type',
  },
  drum: {
    id: 'drum-manage.drum',
    defaultMessage: 'Drum',
  },
  drumDrumId: {
    id: 'drum-manage.drum-drum-id',
    defaultMessage: 'Drum ID',
  },
  drumExternalId: {
    id: 'drum-manage.drums.external-id',
    defaultMessage: 'Drum ID',
  },
  drumId: {
    id: 'drum-manage.drum-id',
    defaultMessage: 'Drum ID',
  },
  drums: {
    id: 'drum-manage.drums',
    defaultMessage: 'Drums',
  },
  drumSettings: {
    id: 'drum-manage.drum-settings',
    defaultMessage: 'Drum settings',
  },
  externalBarcode: {
    id: 'drum-manage.external-barcode',
    defaultMessage: 'Barcode',
  },
  filter: {
    id: 'drum-manage.filter',
    defaultMessage: 'Filter',
  },
  drumTotalWeight: {
    id: 'drum-manage.drums.total-weight',
    defaultMessage: 'Weight of drum with cable',
  },
  drumType: {
    id: 'drum-manage.drum-type',
    defaultMessage: 'Drum type',
  },
  tags: {
    id: 'drum-manage.tags',
    defaultMessage: 'Tags',
  },
  drumTypes: {
    id: 'drum-manage.drum-types',
    defaultMessage: 'Drum types',
  },
  drumEvents: {
    id: 'drum-manage.drum-events',
    defaultMessage: 'Drum events',
  },
  drumHistory: {
    id: 'drum-manage.drum-history',
    defaultMessage: 'History',
  },
  drumState: {
    id: 'drum-manage.drum-state',
    defaultMessage: 'Status',
  },
  drumCondition: {
    id: 'drum-manage.drum-condition',
    defaultMessage: 'Drum condition',
  },
  claimReport: {
    id: 'drum-manage.claim-report',
    defaultMessage: 'Claim report',
  },
  drumTypeTitle: {
    id: 'drum-manage.drum-types.title',
    defaultMessage: 'Drum type',
  },
  drumTypeMaterial: {
    id: 'drum-manage.drum-types.material',
    defaultMessage: 'Material',
  },
  drumTypeDrumMaterial: {
    id: 'drum-manage.drum-types.drum-material',
    defaultMessage: 'Drum material',
  },
  drumTypeMaterialWooden: {
    id: 'drum-manage.drum-types.material.wooden',
    defaultMessage: 'Wooden',
  },
  drumTypeMaterialIron: {
    id: 'drum-manage.drum-types.material.iron-range-wooden',
    defaultMessage: 'Iron range wooden',
  },
  drumTypeMaterialPlastic: {
    id: 'drum-manage.drum-types.material.plastic',
    defaultMessage: 'Plastic',
  },
  drumTypeFlangeDiameter: {
    id: 'drum-manage.drum-types.flange-diameter',
    defaultMessage: 'Flange diameter (mm)',
  },
  drumTypeCoreDiameter: {
    id: 'drum-manage.drum-types.core-diameter',
    defaultMessage: 'Core diameter (mm)',
  },
  drumTypeCentralBore: {
    id: 'drum-manage.drum-types.central-bore',
    defaultMessage: 'Central bore (mm)',
  },
  drumTypeWindingWidth: {
    id: 'drum-manage.drum-types.winding-width',
    defaultMessage: 'Winding width (mm)',
  },
  drumTypeOverallWidth: {
    id: 'drum-manage.drum-types.overall-width',
    defaultMessage: 'Overall width',
  },
  drumTypeWeight: {
    id: 'drum-manage.drum-types.drum-weight',
    defaultMessage: 'Empty drum weight',
  },
  drumTypeMaximumLoadWeight: {
    id: 'drum-manage.drum-types.maximum-load-weight',
    defaultMessage: 'Maximum load weight',
  },
  drumTypeInformation: {
    id: 'drum-manage.drum-types.information',
    defaultMessage: 'Drum type information',
  },
  drumTypeDescription: {
    id: 'drum-manage.drum-types.description',
    defaultMessage: 'Description',
  },
  cable: {
    id: 'drum-manage.cable',
    defaultMessage: 'Cable',
  },
  type: {
    id: 'drum-manage.type',
    defaultMessage: 'Type',
  },
  cableModel: {
    id: 'drum-manage.cable-types.model',
    defaultMessage: 'Model',
  },
  cableType: {
    id: 'drum-manage.cable-type',
    defaultMessage: 'Cable type',
  },
  cableTypeName: {
    id: 'drum-manage.cable-type-name',
    defaultMessage: 'Cable type name',
  },
  cableTypeInformation: {
    id: 'drum-manage.cable-types.information',
    defaultMessage: 'Cable type information',
  },
  cableTypes: {
    id: 'drum-manage.cable-types',
    defaultMessage: 'Cable types',
  },
  cableMaterial: {
    id: 'drum-manage.cable-types.material',
    defaultMessage: 'Cable material',
  },
  cableMaterialCopper: {
    id: 'drum-manage.cable-types.material.copper',
    defaultMessage: 'Copper',
  },
  cableMaterialAluminium: {
    id: 'drum-manage.cable-types.material.aluminium',
    defaultMessage: 'Aluminium',
  },
  cableDiameter: {
    id: 'drum-manage.cable-types.diameter',
    defaultMessage: 'Cable diameter',
  },
  cableWeight: {
    id: 'drum-manage.cable-types.weight',
    defaultMessage: 'Cable weight',
  },
  cableCores: {
    id: 'drum-manage.cable-types.cores',
    defaultMessage: 'Cores',
  },
  cableInitialLength: {
    id: 'drum-manage.drum-types.material.cable-initial-length',
    defaultMessage: 'Initial cable length',
  },
  cableLength: {
    id: 'cable-production.production.edit-drum.cable-length',
    defaultMessage: 'Current cable length',
  },
  cableLengthMax: {
    id: 'cable-production.production.edit-drum.cable-length-max',
    defaultMessage: 'Cable length (m) {maxLength}m max',
  },
  cableMarkers: {
    id: 'cable-production.production.edit-drum.cable-markers',
    defaultMessage: 'Cable markers',
  },
  cableStartMarker: {
    id: 'cable-production.production.edit-drum.start-marker',
    defaultMessage: 'Start marker (m)',
  },
  cableEndMarker: {
    id: 'cable-production.production.edit-drum.end-marker',
    defaultMessage: 'End marker (m)',
  },
  distribution: {
    id: 'drum-manage.distribution',
    defaultMessage: 'Distribution',
  },
  drumInfo: {
    id: 'drum-manage.drum-info',
    defaultMessage: 'Information',
  },
  createDrum: {
    id: 'drum-manage.drums.create-drum',
    defaultMessage: 'Register new drum',
  },
  editDrum: {
    id: 'drum-manage.drums.edit',
    defaultMessage: 'Edit',
  },
  deleteDrum: {
    id: 'drum-manage.drums.delete',
    defaultMessage: 'Delete',
  },
  removeDrum: {
    id: 'drum-manage.drums.remove',
    defaultMessage: 'Remove',
  },
  addNewDrumType: {
    id: 'drum-manage.drum-types.add-new',
    defaultMessage: 'Add new drum type',
  },
  createDrumType: {
    id: 'drum-manage.drum-types.create',
    defaultMessage: 'Create drum type',
  },
  editDrumType: {
    id: 'drum-manage.drum-types.edit',
    defaultMessage: 'Edit drum type',
  },
  deleteDrumType: {
    id: 'drum-manage.drum-types.delete',
    defaultMessage: 'Delete drum type',
  },
  addNewCableType: {
    id: 'drum-manage.cable-types.add-new',
    defaultMessage: 'Add new cable type',
  },
  createCableType: {
    id: 'drum-manage.cable-types.create',
    defaultMessage: 'Create cable type',
  },
  editCableType: {
    id: 'drum-manage.cable-types.edit',
    defaultMessage: 'Edit cable type',
  },
  deleteCableType: {
    id: 'drum-manage.cable-types.delete',
    defaultMessage: 'Delete cable type',
  },
  generateQrCode: {
    id: 'drum-manage.drums.generate-qr-code',
    defaultMessage: 'Generate QR-code',
  },
  generateQrCodes: {
    id: 'drum-manage.drums.generate-qr-codes',
    defaultMessage: 'Generate QR-codes',
  },
  showingWithCount: {
    id: 'drum-manage.showing-with-count',
    defaultMessage: 'Showing {showed} of {total}',
  },
  drumsSearchPlaceholder: {
    id: 'drum-manage.drums-search-placeholder',
    defaultMessage: 'Search by ID',
  },
  location: {
    id: 'drum-manage.location',
    defaultMessage: 'Location',
  },
  drumLocation: {
    id: 'drum-manage.drum-location',
    defaultMessage: 'Drum location',
  },
  currentDrumLocation: {
    id: 'drum-manage.current-drum-location',
    defaultMessage: 'Current drum location',
  },
  attachCable: {
    id: 'drum-manage.attach-cable',
    defaultMessage: 'Attach cable',
  },
  detachCable: {
    id: 'drum-manage.detach-cable',
    defaultMessage: 'Detach cable',
  },
  keepLocation: {
    id: 'drum-manage.keep-location',
    defaultMessage: 'Keep Location',
  },
  updatedAt: {
    id: 'drum-manage.updated-at',
    defaultMessage: 'Updated: {dateTime}',
  },
  createTitle: {
    id: 'drum-manage.create-title',
    defaultMessage: 'Register drum manually',
  },
  createDescription: {
    id: 'drum-manage.create-description',
    defaultMessage: 'Register new drum by entering drum data manually',
  },
  uploadDrums: {
    id: 'drum-manage.upload-drums',
    defaultMessage: 'Upload drum data via xlsx/csv',
  },
  uploadDescription: {
    id: 'drum-manage.upload-drums-description',
    defaultMessage: 'Register multiple drums from a file template',
  },
  uploadDrumData: {
    id: 'drum-manage.upload-drum-data',
    defaultMessage: 'Select drum data file',
  },
  uploadTemplate: {
    id: 'drum-manage.upload-template',
    defaultMessage: 'Download file template',
  },
  uploadTypes: {
    id: 'drum-manage.upload-types',
    defaultMessage: 'Accepted file types: .xls, .xlsx, .csv',
  },
  uploadNotice: {
    id: 'drum-manage.upload-notice',
    defaultMessage: 'Please make sure file is formatted according to the template',
  },
  uploadDrumDataSuccess: {
    id: 'drum-manage.upload-drum-data-success',
    defaultMessage: 'Drum data successfully imported',
  },
  uploadDrumDataError: {
    id: 'drum-manage.upload-drum-data-error',
    defaultMessage: 'Upload failed. Please check uploaded data and try again',
  },
  showMap: {
    id: 'drum-manage.show-map',
    defaultMessage: 'Show map',
  },
  linkDevice: {
    id: 'drum-manage.link-device',
    defaultMessage: 'Link device',
  },
  linkDeviceToDrum: {
    id: 'drum-manage.link-device-to-drum',
    defaultMessage: 'Link device to drum',
  },
  linkedDevice: {
    id: 'drum-manage.linked-device',
    defaultMessage: 'Linked device',
  },
  noDevice: {
    id: 'drum-manage.no-device',
    defaultMessage: 'No devices are linked to this drum',
  },
  cableInfo: {
    id: 'drum-manage.cable-info',
    defaultMessage: 'Cable information',
  },
  currentOwner: {
    id: 'drum-manage.current-owner',
    defaultMessage: 'Current drum owner',
  },
  addToOrder: {
    id: 'drum-manage.add-to-order',
    defaultMessage: 'Add to order',
  },
  addToProject: {
    id: 'drum-manage.add-to-project',
    defaultMessage: 'Add to project',
  },
  addDrumsToProject: {
    id: 'drum-manage.add-drums-to-project',
    defaultMessage: 'Add drums to project',
  },
  projectName: {
    id: 'drum-manage.project-name',
    defaultMessage: 'Project name',
  },
  showMore: {
    id: 'drum-manage.show-more',
    defaultMessage: 'More',
  },
  notes: {
    id: 'drum-manage.notes',
    defaultMessage: 'Additional information',
  },
  notesLabel: {
    id: 'drum-manage.notes-label',
    defaultMessage: 'Additional information for drum label',
  },
  defaultRentalPeriod: {
    id: 'drum-manage.default-rental-period',
    defaultMessage: 'Default rental period',
  },
  defaultReturnDate: {
    id: 'drum-manage.default-return-date',
    defaultMessage: 'Default return date',
  },
  periodMonths: {
    id: 'drum-manage.period-months',
    defaultMessage: '{count, plural, =0 {Not set} one {# month} other {# months}}',
  },
  addNewLocation: {
    id: 'drum-manage.add-location',
    defaultMessage: 'Add new location',
  },
  manageDrumTypes: {
    id: 'drum-manage.mgmt-drum-types',
    defaultMessage: 'Manage drum types',
  },
  manageCableTypes: {
    id: 'drum-manage.mgmt-cable-types',
    defaultMessage: 'Manage cable types',
  },
  manageDevices: {
    id: 'drum-manage.mgmt-devices',
    defaultMessage: 'Manage devices',
  },
  markAsScheduled: {
    id: 'drum-manage.mark-as-scheduled',
    defaultMessage: 'Mark as \'Scheduled for return\'',
  },
  selectDrumType: {
    id: 'drum-manage.select-drum-type',
    defaultMessage: 'Select drum type',
  },
  selectDrumLocation: {
    id: 'drum-manage.select-drum-location',
    defaultMessage: 'Select drum location',
  },
  selectCableType: {
    id: 'drum-manage.select-cable-type',
    defaultMessage: 'Select cable type',
  },
  selectRentalPeriod: {
    id: 'drum-manage.select-rental-period',
    defaultMessage: 'Select rental period',
  },
  cableAttached: {
    id: 'drum-manage.cable-attached',
    defaultMessage: 'Cable attached to drum',
  },
  enterCableLength: {
    id: 'drum-manage.enter-cable-length',
    defaultMessage: 'Enter cable length',
  },
  useCableMarkers: {
    id: 'drum-manage.use-cable-markers',
    defaultMessage: 'Use cable markers',
  },
  nDrumsSelected: {
    id: 'drum-manage.n-drums-selected',
    defaultMessage: 'drums selected',
  },
  drumsSelected: {
    id: 'drum-manage.drums-selected',
    defaultMessage: '{count, plural, =0 {drums} one {drum} other {drums}} selected',
  },
  drumTypesSelected: {
    id: 'drum-manage.drum-types-selected',
    defaultMessage: '{count, plural, =0 {drum types} one {drum type} other {drum types}} selected',
  },
  cableTypesSelected: {
    id: 'drum-manage.cable-types-selected',
    defaultMessage: '{count, plural, =0 {cable types} one {cable type} other {cable types}} selected',
  },
  setCableLength: {
    id: 'drum-manage.set-cable-length',
    defaultMessage: 'Set cable length',
  },
  total: {
    id: 'drum-manage.total',
    defaultMessage: 'Total',
  },
  moreFilters: {
    id: 'drum-manage.more-filters',
    defaultMessage: 'More filters',
  },
  havingProblems: {
    id: 'drum-manage.having-problems',
    defaultMessage: 'Having problems with upload?',
  },
  selectDrumOwnerCompany: {
    id: 'drum-manage.select-drum-owner-company',
    defaultMessage: 'Select drum owner company',
  },
  drumMustBeReturned: {
    id: 'drum-manage.drum-must-be-returned',
    defaultMessage: 'Drum must be returned',
  },
  drumOwner: {
    id: 'drum-manage.drum-owner',
    defaultMessage: 'Drum owner',
  },
  owner: {
    id: 'drum-manage.owner',
    defaultMessage: 'Owner',
  },
  tipOwner: {
    id: 'drum-manage.tip-owner',
    defaultMessage: 'Owner indicates that you own the drum. ',
  },
  tipRecipient: {
    id: 'drum-manage.tip-recipient',
    defaultMessage: 'Holder inidicates that the drum is physically used by your company. Neverthless the owner of the drum is a third party. ',
  },
  recipientMustReturn: {
    id: 'drum-manage.recipient-must-return-the-drum',
    defaultMessage: 'Recipient must return the drum',
  },
  setReturnPeriod: {
    id: 'drum-manage.set-return-period',
    defaultMessage: 'Set return period',
  },
  selectReturnDate: {
    id: 'drum-manage.select-return-date',
    defaultMessage: 'Select return date',
  },
  rentalPeriodTrigger: {
    id: 'drum-manage.rental-period-trigger',
    defaultMessage: 'Select rental period trigger',
  },
  toolTipText: {
    id: 'drum-manage.tool-tip-text',
    defaultMessage: 'Before attaching cable you must select drum type!',
  },
  drumParameters: {
    id: 'drum-manage.drum-parameters',
    defaultMessage: 'Drum parameters',
  },
  drumReturnOptions: {
    id: 'drum-manage.drum-return-options',
    defaultMessage: 'Drum return options',
  },
  holder: {
    id: 'drum-manage.drum-holder',
    defaultMessage: 'Drum holder',
  },
  returnDate: {
    id: 'drum-manage.return-date',
    defaultMessage: 'Return date',
  },
  returnDrumIn: {
    id: 'drum-manage.return-drum-in',
    defaultMessage: 'Return drum in',
  },
  returnDrumTo: {
    id: 'drum-manage.return-drum-to',
    defaultMessage: 'Return drum to',
  },
  rentalTrigger: {
    id: 'drum-manage.rental-trigger',
    defaultMessage: 'Rental period trigger',
  },
  rentalPeriod: {
    id: 'drum-manage.rental-period',
    defaultMessage: 'Rental period',
  },
  days: {
    id: 'drum-manage.days',
    defaultMessage: '{days} days',
  },
  parametersOverwritten: {
    id: 'drum-manage.paramaters-overwritten',
    defaultMessage: 'Parameters overwritten',
  },
  claimPeriod: {
    id: 'drum-manage.claim-period',
    defaultMessage: 'Claim period',
  },
  claimReason: {
    id: 'drum-manage.claim-reason',
    defaultMessage: 'Claim reason',
  },
  attachedDocuments: {
    id: 'drum-manage.attached-documents',
    defaultMessage: 'Attached documents',
  },
  cableTypeAttachmentInfo: {
    id: 'drum-manage.cable-type-attachment-info',
    defaultMessage: 'E.g., instruction manual',
  },
  cableTypeAttachmentUpload: {
    id: 'drum-manage.cable-type-attachment-upload',
    defaultMessage: 'Select file',
  },
  linkedWithDevice: {
    id: 'drum-manage.linked-with-device',
    defaultMessage: 'A device is connected to this drum',
  },
  drumsWithResponsibleMe: {
    id: 'drum-manage.drums-with-responsible-me',
    defaultMessage: 'Show only drums in my projects',
  },
  miscFilters: {
    id: 'drum-manage.misc-filters',
    defaultMessage: 'Misc.',
  },
  exportDrums: {
    id: 'drum-manage.export',
    defaultMessage: 'Export to CSV',
  },
  updateLocation: {
    id: 'drum-manage.update-location',
    defaultMessage: 'Update location',
  },
  cableTypeIncompatible: {
    id: 'cable-production.type-incompatible',
    defaultMessage: 'Selected cable type is incompatible with selected drum type!',
  },
  unassignedDrumsOnly: {
    id: 'drum-manage.unassigned-drums-only',
    defaultMessage: 'Unassigned drums only',
  },
  tipEmpty: {
    id: 'drum-manage.tip-empty',
    defaultMessage: 'There is no cable mounted to the drum and no action has been booked. ',
  },
  tipDrumWithCable: {
    id: 'drum-manage.tip-drum-with-cable',
    defaultMessage: 'No further action has been booked. Drum has cable mounted to the drum. ',
  },
  tipReadyForDelivery: {
    id: 'drum-manage.tip-ready-for-delivery',
    defaultMessage: 'The drums are ready to dispatch to the customer. ',
  },
  tipInTransit: {
    id: 'drum-manage.tip-in-transit',
    defaultMessage: 'The drums are being delivered',
  },
  tipAccepted: {
    id: 'drum-manage.tip-accepted',
    defaultMessage: 'The drum has arrived at the recipient and confirmed the delivery. ',
  },
  tipReadyForReturn: {
    id: 'drum-manage.tip-ready-for-return',
    defaultMessage: 'The drum is no longer needed and return transportation can be booked. ',
  },
  tipRejected: {
    id: 'drum-manage.tip-rejected',
    defaultMessage: 'The drum has been refused by a user. Details can be found in the claim report. ',
  }
});

export default messages;
