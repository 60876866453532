import AppPreloader from 'dashboard/components/AppPreloader/index'
import Authenticate from 'dashboard/modules/Auth/containers/Authenticate'
import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import RedirectHashPrefixedLocation from './RedirectHashPrefixedLocation'
import Loadable from 'react-loadable'

const AuthRouter = Loadable({
  loader: () => import('dashboard/Router/AuthRouter'),
  loading: AppPreloader,
})

const NotAuthRouter = Loadable({
  loader: () => import('dashboard/Router/NotAuthRouter'),
  loading: AppPreloader,
})

export default ({ history }) => {
  return (
    <ConnectedRouter history={history}>
      <React.Fragment>
        <RedirectHashPrefixedLocation />
        <Authenticate unknown={<AppPreloader />} authenticated={<AuthRouter />} notAuthenticated={<NotAuthRouter />} />
      </React.Fragment>
    </ConnectedRouter>
  )
}
