import { defineMessages } from 'react-intl';

const messages = defineMessages({
  dashboard: {
    id: 'sidebar.dashboard',
    defaultMessage: 'Dashboard',
  },
  drums: {
    id: 'sidebar.drums',
    defaultMessage: 'Drums',
  },
  handscanner: {
    id: 'sidebar.handscanner',
    defaultMessage: 'Handscanner',
  },
  userManagement: {
    id: 'sidebar.user-management',
    defaultMessage: 'User management',
  },
  companyManagement: {
    id: 'sidebar.company-management',
    defaultMessage: 'Company management',
  },
  companyProfile: {
    id: 'sidebar.company-profile',
    defaultMessage: 'Company profile',
  },
  associatedCompanies: {
    id: 'sidebar.associated-companies',
    defaultMessage: 'Linked companies',
  },
  drumManagement: {
    id: 'sidebar.drum-management',
    defaultMessage: 'Drum management',
  },
  cableProduction: {
    id: 'sidebar.cable-production',
    defaultMessage: 'Cable production',
  },
  events: {
    id: 'sidebar.events',
    defaultMessage: 'Events',
  },
  projects: {
    id: 'sidebar.projects',
    defaultMessage: 'Projects',
  },
  damagedDrums: {
    id: 'sidebar.damaged-drums',
    defaultMessage: 'Damage reports',
  },
  claimReports: {
    id: 'sidebar.claim-reports',
    defaultMessage: 'Claim reports',
  },
  orders: {
    id: 'sidebar.orders',
    defaultMessage: 'Orders',
  },
  ordersOut: {
    id: 'sidebar.order-outgoing',
    defaultMessage: 'Outgoing orders',
  },
  ordersIn: {
    id: 'sidebar.orders-outgoing',
    defaultMessage: 'Incoming orders',
  },
  deliveries: {
    id: 'sidebar.deliveries',
    defaultMessage: 'Deliveries',
  },
  administration: {
    id: 'sidebar.administration',
    defaultMessage: 'Administration',
  },
  notifications: {
    id: 'sidebar.notifications',
    defaultMessage: 'Notifications',
  },
  freeTrial: {
    id: 'sidebar.free-trial',
    defaultMessage: 'Free trial',
  },
  title: {
    id: 'sidebar.title',
    defaultMessage: 'TrackMyDrum',
  },
  help: {
    id: 'sidebar.help',
    defaultMessage: 'Help',
  },
});

export default messages;
