import { getAwsConfig } from './getConfig'

const awsConfig = getAwsConfig()
const prefix = awsConfig.endpointPrefix
const prefixLocal = awsConfig.endpointPrefixLocal

export default {
  localPrefix: prefixLocal,
  barcodes: {
    delete: `${prefix}/barcodes/delete`,
    list: `${prefix}/barcodes/list`,
    assignToDrum: `${prefix}/drums/{id}/link-with-code`,
  },
  drums: {
    list: `${prefix}/drums/list`,
    accept: `${prefix}/drums/accept`,
    markForReturnForce: `${prefix}/drums/ready-for-return-force`,
    summary: `${prefix}/drums/{id}/details`,
    events: `${prefix}/drums/{drumId}/events/list`,
    filter: `${prefix}/drums/filter`,
    filteredCompanies: `${prefix}/filtered-companies`,
    condition: `${prefix}/drums/{id}/drum-condition/{conditionId}/details`,
    updateLocation: `${prefix}/drums/{id}/update-location/{locationId}`,
  },
  companyManagement: {
    list: `${prefix}/companies`,
    item: `${prefix}/companies/{id}`,
    users: `${prefix}/company/users/list`,
    user: `${prefix}/company/user`,
    locations: `${prefix}/locations/list`,
    locationCreate: `${prefix}/locations`,
    location: `${prefix}/companies/{id}/locations/{locationId}`,
    linkPartner: `${prefix}/company/link-partner`,
    removePartner: `${prefix}/companies/partners/{id}`,
    partnerList: `${prefix}/companies/partners/list`,
    invitePartner: `${prefix}/companies/invite-partner`,
  },
  user: {
    profile: `${prefix}/me/profile`,
    edit: `${prefix}/me/profile`,
  },
  drumManagement: {
    create: `${prefix}/drums`,
    itemDetails: `${prefix}/drums/{id}/details`,
    item: `${prefix}/drums/{id}`,
    delete: `${prefix}/drums/delete`,

    cableProduction: `${prefix}/drums/{drumId}/cable`,
    cableDelete: `${prefix}/drums/{drumId}/cable`,
    cableLength: `${prefix}/drums/{drumId}/cable-length`,

    state: `${prefix}/drums-state`,
    label: `${prefix}/drums-label`,
    location: `${prefix}/drums/{drumId}/location`,
    importDrumsData: `${prefix}/import-drums`,
  },
  tags: {
    create: `${prefix}/tags`,
    list: `${prefix}/tags/list`,
  },
  userManagement: {
    listRequestsToJoinCompany: `${prefix}/company/guest-requests/list`,
    guestUsersList: `${prefix}/guests/list`,
    userRequests: `${prefix}/companies/users/requests`,
    updateRequest: `${prefix}/guests/requests/{id}`,
    editUser: `${prefix}/users/{userId}`,
    updateUser: `${prefix}/users/{userId}`,
    createUser: `${prefix}/users`,
    verifyLogin: `${prefix}/users/verify-login`,
    resendVerificationEmail: `${prefix}/users/verify-login/resend`,
    resendTempPassword: `${prefix}/users/resend-temp-pw`,
    delete: `${prefix}/users/{id}`,
  },
  cableTypes: {
    list: `${prefix}/cable-types/list`,
    item: `${prefix}/cable-types/{id}`,
    create: `${prefix}/cable-types`,
    delete: `${prefix}/cable-types/delete`,
    edit: `${prefix}/cable-types/{id}`,
    documents: `${prefix}/s3files?path=cable-types/{cableTypeId}/`,
    documentDelete: `${prefix}/s3files?path=cable-types/{cableTypeId}/{fileName}`,
  },
  drumTypes: {
    create: `${prefix}/drum-types`,
    delete: `${prefix}/drum-types/delete`,
    list: `${prefix}/drum-types/list`,
    getOrEdit: `${prefix}/drum-types/{id}`,
  },
  events: {
    list: `${prefix}/drums-events`,
  },
  orders: {
    list: `${prefix}/orders/list`,
    filter: `${prefix}/orders/filter`,
    item: `${prefix}/orders/{id}`,
    create: `${prefix}/orders`,
    drums: `${prefix}/orders/{orderId}/order-items`,
    removeDrums: `${prefix}/orders/{orderId}/order-items/delete`,
    getDrums: `${prefix}/orders/{orderId}/drums`,
    deliveryItems: `${prefix}/orders/{id}/delivery-items`,
    deliveryPlans: `${prefix}/orders/{orderId}/delivery-plans`,
    deliveryPlan: `${prefix}/orders/{orderId}/delivery-plans/{deliveryPlanId}`,
    assignResponsibleUser: `${prefix}/orders/{orderId}/assign-user`,
    recipients: `${prefix}/orders/available-recipients/list`,
    markAsCompleted: `${prefix}/orders/{id}/mark-as-completed`,
  },
  projects: {
    list: `${prefix}/projects/list`,
    item: `${prefix}/projects/{id}`,
    create: `${prefix}/projects/create`,
    delete: `${prefix}/projects/delete`,
    orders: `${prefix}/projects/{id}/orders`,
    consumption: `${prefix}/projects/{id}/consumption/list`,
    drums: `${prefix}/projects/{id}/drums`,
    assignDrums: `${prefix}/projects/{id}/assign-drums`,
  },
  deliveries: {
    documents: `${prefix}/s3files?path=deliveries/{deliveryId}/`,
    documentDelete: `${prefix}/s3files?path=deliveries/{deliveryId}/{fileName}`,
    list: `${prefix}/deliveries/list`,
    create: `${prefix}/deliveries`,
    item: `${prefix}/deliveries/{deliveryId}`,
    details: `${prefix}/deliveries/{deliveryId}/details`,
    update: `${prefix}/deliveries/{deliveryId}`,
  },
  guest: {
    list: `${prefix}/guests/requests`,
    delete: `${prefix}/guests/requests/{id}`,
  },
  notificationPreferences: {
    list: `${prefix}/me/notification-preferences`,
    edit: `${prefix}/me/notification-preferences`,
  },
  notifications: {
    getMessages: `${prefix}/me/inbox/list`,
    markRead: `${prefix}/me/inbox/`,
    markAllAsRead: `${prefix}/me/inbox/mark-all-as-read`,
    registerToken: `${prefix}/me/push-device`,
    getCount: `${prefix}/me/inbox-info`,
  },
  claims: {
    list: `${prefix}/claims/list`,
    details: `${prefix}/claims/{id}/details`,
    drumDetails: `${prefix}/claims/{id}/drumDetails`,
    orderDetails: `${prefix}/claims/{id}/orderDetails`,
    deliveryDetails: `${prefix}/claims/{id}/deliveryDetails`,
    lastDamageReport: `${prefix}/widgets/last-damage-report`,
  },
  deviceManagement: {
    list: `${prefix}/devices/list`,
    freeList: `${prefix}/devices/not-used/list`,
    item: `${prefix}/devices/{deviceId}`,
    create: `${prefix}/devices`,
    edit: `${prefix}/devices/{deviceId}`,
    delete: `${prefix}/devices/delete`,
  },
  widgets: {
    lastDamageReport: `${prefix}/widgets/last-damage-report`,
    upcomingDeliveries: `${prefix}/deliveries/list`,
    drumStatistics: `${prefix}/widgets/drum-statistics`,
    rentalExpirationStatisticsByProject: `${prefix}/widgets/rental-expiration-statistics-by-project`,
  },
}
