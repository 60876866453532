import { combineReducers } from 'redux'
import {
  DELETE_CABLE_TYPE,
  RECEIVE_CABLE_TYPE,
  RECEIVE_CABLE_TYPES,
  SET_PAGING,
  SET_QUERY_FILTER,
  SET_IS_LOADING_CABLE_TYPE,
  CABLETYPE_DOCS_RECEIVED,
  SET_IS_FETCHING_DOC,
  SET_ATTACHING_DOCUMENT_IN_PROGRESS,
} from '../constants/actions'
import defaultPagingValues from 'dashboard/constants/paging'

const listReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_CABLE_TYPES:
      return [...action.cableTypes]
    case DELETE_CABLE_TYPE:
      return state.filter((item) => item.id !== action.id)
    default:
      return state
  }
}

const currentReducer = (state = null, action) => {
  switch (action.type) {
    case RECEIVE_CABLE_TYPE:
      return { ...action.cableType }
    default:
      return state
  }
}

const queryFilterReducer = (query = '', action) => {
  switch (action.type) {
    case SET_QUERY_FILTER:
      return action.query
    default:
      return query
  }
}

const setIsLoading = (state = false, action) => {
  switch (action.type) {
    case SET_IS_LOADING_CABLE_TYPE:
      return action.state
    default:
      return state
  }
}

const pagingReducer = (state = defaultPagingValues, action) => {
  switch (action.type) {
    case SET_PAGING:
      return {
        ...state,
        ...action.paging,
      }
    default:
      return state
  }
}

const documentsReducer = (state = [], action) => {
  switch (action.type) {
    case CABLETYPE_DOCS_RECEIVED:
      return action.documents
    default:
      return state
  }
}

const isFetchingDocumentsReducer = (state = false, action) => {
  switch (action.type) {
    case SET_IS_FETCHING_DOC:
      return action.state
    default:
      return state
  }
}

const documentUploadInProgressReducer = (state = false, action) => {
  switch (action.type) {
    case SET_ATTACHING_DOCUMENT_IN_PROGRESS:
      return action.state
    default:
      return state
  }
}

export default combineReducers({
  list: listReducer,
  isLoading: setIsLoading,
  current: currentReducer,
  queryFilter: queryFilterReducer,
  paging: pagingReducer,
  documents: documentsReducer,
  isFetchingDocuments: isFetchingDocumentsReducer,
  documentUploadInProgress: documentUploadInProgressReducer,
})
