import { getConfig } from 'dashboard/etc/getConfig'
import * as ReactGA from 'react-ga'

const config = getConfig()

ReactGA.initialize(config.analytics.trackingCode)

const gaPageViewMiddleware = (store) => (next) => (action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    if (action.payload.pathname) {
      ReactGA.pageview(action.payload.pathname)
    }
  }

  return next(action)
}

export default gaPageViewMiddleware
