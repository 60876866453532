import { defineMessages } from 'react-intl';

const messages = defineMessages({
  open: {
    id: 'projectStates.OPEN',
    defaultMessage: 'Open',
  },
  ongoing: {
    id: 'projectStates.ONGOING',
    defaultMessage: 'Ongoing',
  },
  outdated: {
    id: 'projectStates.OUTDATED',
    defaultMessage: 'Outdated',
  },
});

export default messages;
