import { defineMessages } from 'react-intl';

const messages = defineMessages({
  admin: {
    id: 'roles.admin',
    defaultMessage: 'Admin',
  },
  guest: {
    id: 'roles.guest',
    defaultMessage: 'Guest user',
  },
  manager: {
    id: 'roles.manager',
    defaultMessage: 'Manager',
  },
  master: {
    id: 'roles.master',
    defaultMessage: 'Master admin',
  },
});

export default messages;
