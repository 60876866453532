import { defineMessages } from 'react-intl';

const messages = defineMessages({
  emptyDrumAccepted: {
    id: 'drum-rental-period-trigger.empty-drum-accepted',
    defaultMessage: 'Empty drum accepted',
  },
  drumWithCableAccepted: {
    id: 'drum-rental-period-trigger.drum-with-cable-accepted',
    defaultMessage: 'Drum with cable accepted',
  },
});

export default messages;
