import { RECEIVE_DRUM_EVENTS } from 'dashboard/constants/actions'

const defaultEvents = {
  items: [],
  count: 0,
  page: 0,
  pages: 0,
}

export default (events = defaultEvents, action) => {
  switch (action.type) {
    case RECEIVE_DRUM_EVENTS:
      return { ...action.events }
    default:
      return events
  }
}
