import { defineMessages } from 'react-intl';

const messages = defineMessages({
  NEW_USER_REQUEST: {
    id: 'inbox-notifications-common.NEW_USER_REQUEST',
    defaultMessage: 'There\'s a new user {user_name} requesting to join your company. Please review them in {user_join_requests}.',
  },
  USER_REQUEST_ACCEPTED: {
    id: 'inbox-notifications-common.USER_REQUEST_ACCEPTED',
    defaultMessage: 'You have a new user {user_name} that successfully joined your company.',
  },
  USER_REQUEST_REJECTED: {
    id: 'inbox-notifications-common.USER_REQUEST_REJECTED',
    defaultMessage: 'The user\'s {user_name} request to join your company has been rejected.',
  },
  USER_REQUEST_CANCELLED: {
    id: 'inbox-notifications-common.USER_REQUEST_CANCELLED',
    defaultMessage: 'The user\'s {user_name} request to join your company has been cancelled.',
  },
  PROJECT_CREATED: {
    id: 'inbox-notifications-common.PROJECT_CREATED',
    defaultMessage: 'A new project {project_name} has been created',
  },
  PROJECT_EXPIRES_IN_X_DAYS: {
    id: 'inbox-notifications-common.PROJECT_EXPIRES_IN_X_DAYS',
    defaultMessage: 'The project {project_name} is expiring in {x} days.',
  },
  PARTNER_LINKED: {
    id: 'inbox-notifications-common.PARTNER_LINKED',
    defaultMessage: '{partner_name} accepted your invitation and is now linked.',
  },
});

export default messages;
