import { defineMessages } from 'react-intl';

const messages = defineMessages({
  inTransit: {
    id: 'delivery-states.in-transit',
    defaultMessage: 'In Transit',
  },
  delivered: {
    id: 'delivery-states.delivered',
    defaultMessage: 'Delivered',
  },
  partiallyDelivered: {
    id: 'delivery-states.partially-delivered',
    defaultMessage: 'Partially Delivered',
  },
});

export default messages;
