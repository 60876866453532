import endpoints from '../../../etc/endpoints'
import fetch from '../../../services/fetch'
import pick from 'lodash/pick'

export const requestJoinRequests = (params) => {
  return fetch.post(endpoints.userManagement.listRequestsToJoinCompany, {
    body: JSON.stringify(params),
  })
}

export const updateJoinRequest = (id, newState) =>
  fetch.put(endpoints.userManagement.updateRequest.replace('{id}', id), {
    body: JSON.stringify({
      state: newState,
    }),
  })

export const getGuestUsersFromCompany = (params) => {
  return fetch.post(endpoints.userManagement.guestUsersList, {
    body: JSON.stringify(params),
  })
}

export const getUserList = (params) => {
  return fetch.post(endpoints.companyManagement.users, {
    body: JSON.stringify(params),
  })
}

export const getUser = (id) => fetch(endpoints.userManagement.editUser.replace('{userId}', id))

export const updateUser = (data) =>
  fetch.put(endpoints.userManagement.updateUser.replace('{userId}', data.id), {
    body: JSON.stringify(pick(data, ['firstName', 'lastName', 'phone', 'lang', 'info', 'role'])),
  })

export const verifyLogin = (code, recaptchaToken) =>
  fetch.put(endpoints.userManagement.verifyLogin, {
    body: JSON.stringify({ code, recaptchaToken, fromMobile: false }),
  })

export const resendVerificationEmail = () =>
  fetch.post(endpoints.userManagement.resendVerificationEmail, {
    body: JSON.stringify({ fromMobile: false }),
  })

export const createUser = (data) =>
  fetch.post(endpoints.userManagement.createUser, {
    body: JSON.stringify(pick(data, ['firstName', 'lastName', 'phone', 'lang', 'info', 'role', 'email'])),
  })

export const deleteUserRequest = (userId) => fetch.delete(endpoints.userManagement.delete.replace('{id}', userId))
