import {
  IS_FETCHING_CLAIMS,
  IS_FETCHING_CLAIM,
  RECEIVE_CLAIMS,
  RECEIVE_CLAIM,
  SET_PAGING,
  SET_QUERY_FILTER,
  IS_FETCHING_CLAIM_DRUM,
  RECEIVE_CLAIM_DRUM,
  RECEIVE_CLAIM_ORDER,
  IS_FETCHING_CLAIM_ORDER,
  RECEIVE_CLAIM_DELIVERY,
  IS_FETCHING_CLAIM_DELIVERY,
} from '../constants/actions'
import defaultPagingState from 'dashboard/constants/paging'

const defaultState = {
  list: [],
  item: null,
  claimDrum: null,
  claimOrder: null,
  claimDelivery: null,
  isFetchingList: false,
  isFetchingItem: false,
  isFetchingClaimDrum: false,
  isFetchingClaimOrder: false,
  isFetchingClaimDelivery: false,
  paging: defaultPagingState,
  queryFilter: '',
  filters: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_CLAIMS:
      return {
        ...state,
        list: action.claims,
      }
    case RECEIVE_CLAIM:
      return {
        ...state,
        item: action.item,
      }
    case RECEIVE_CLAIM_DRUM:
      return {
        ...state,
        claimDrum: action.claimDrum,
      }
    case RECEIVE_CLAIM_ORDER:
      return {
        ...state,
        claimOrder: action.claimOrder,
      }
    case RECEIVE_CLAIM_DELIVERY:
      return {
        ...state,
        claimDelivery: action.claimDelivery,
      }
    case IS_FETCHING_CLAIMS:
      return {
        ...state,
        isFetchingList: action.state,
      }
    case IS_FETCHING_CLAIM:
      return {
        ...state,
        isFetchingItem: action.state,
      }
    case IS_FETCHING_CLAIM_DRUM:
      return {
        ...state,
        isFetchingClaimDrum: action.state,
      }
    case IS_FETCHING_CLAIM_ORDER:
      return {
        ...state,
        isFetchingClaimOrder: action.state,
      }
    case IS_FETCHING_CLAIM_DELIVERY:
      return {
        ...state,
        isFetchingClaimDelivery: action.state,
      }
    case SET_PAGING:
      return {
        ...state,
        paging: action.paging,
      }
    case SET_QUERY_FILTER:
      return {
        ...state,
        queryFilter: action.query,
      }
    default:
      return state
  }
}
