import { combineReducers } from 'redux'
import {
  SET_COMPANY,
  SET_LOCATIONS,
  UPDATE_LOCATION,
  SET_QUERY_FILTER,
  SET_PAGING,
  RECEIVE_COMPANY_USERS,
  SET_EMPLOYEES_PAGING,
  SET_EMPLOYEES_QUERY_FILTER,
} from '../constants/actions'
import defaultPagingValues from 'dashboard/constants/paging'

const queryFilterReducer = (query = '', action) => {
  switch (action.type) {
    case SET_QUERY_FILTER:
      return action.query
    default:
      return query
  }
}

const pagingReducer = (state = defaultPagingValues, action) => {
  switch (action.type) {
    case SET_PAGING:
      return {
        ...state,
        ...action.paging,
      }
    default:
      return state
  }
}

const locationsListReducer = (state = [], action) => {
  switch (action.type) {
    case SET_LOCATIONS:
      return [...action.locations]
    case UPDATE_LOCATION:
      const location = action.location
      const list = state
      const exists = Boolean(list.find((item) => item.id === location.id))
      return exists ? list.map((item) => (item.id === location.id ? location : item)) : [...list, location]
    default:
      return state
  }
}

export const companyLocationsReducer = combineReducers({
  list: locationsListReducer,
  queryFilter: queryFilterReducer,
  paging: pagingReducer,
})

export const companyReducer = (state = null, action) => {
  switch (action.type) {
    case SET_COMPANY:
      return {
        ...action.company,
      }
    default:
      return state
  }
}

const employeesListReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_COMPANY_USERS:
      return action.users
    default:
      return state
  }
}

const employeesPagingReducer = (state = defaultPagingValues, action) => {
  switch (action.type) {
    case SET_EMPLOYEES_PAGING:
      return {
        ...state,
        ...action.paging,
      }
    default:
      return state
  }
}

const employeesQueryReducer = (query = '', action) => {
  switch (action.type) {
    case SET_EMPLOYEES_QUERY_FILTER:
      return action.query
    default:
      return query
  }
}

export const employeesReducer = combineReducers({
  list: employeesListReducer,
  paging: employeesPagingReducer,
  queryFilter: employeesQueryReducer,
})
