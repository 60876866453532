import { defineMessages } from 'react-intl';

const messages = defineMessages({
  commonMessage: {
    id: 'error.common-message',
    defaultMessage: 'Something went wrong. Please check your data',
  },
  badRequest: {
    id: 'error.bad-request',
    defaultMessage: 'Bad request',
  },
  accessDenied: {
    id: 'error.access-denied',
    defaultMessage: 'Access Denied',
  },
  drumNotFound: {
    id: 'error.drum-not-found',
    defaultMessage: 'Drum Not Found',
  },
  drumUsedInOrder: {
    id: 'error.drum-used-in-order',
    defaultMessage: 'Drum is assigned to an existing order',
  },
  drumTypeCantBeDeleted: {
    id: 'error.drum-type-cant-be-deleted',
    defaultMessage: 'Can not delete drum type',
  },
  cableTypeCantBeDeleted: {
    id: 'error.cable-type-cant-be-deleted',
    defaultMessage: 'Can not delete cable',
  },
  companyLocationNotFound: {
    id: 'error.company-location-not-found',
    defaultMessage: 'Company Location Not Found',
  },
  companyLastLocation: {
    id: 'error.company-last-location',
    defaultMessage: 'Can\'t delete last company location',
  },
  companyCodeExist: {
    id: 'error.company-code-exist',
    defaultMessage: 'Company code exist',
  },
  shortPassword: {
    id: 'error.password-length',
    defaultMessage: 'Password must contain at least 8 characters',
  },
  passwordDoesNotMatch: {
    id: 'error.password-does-not-match',
    defaultMessage: 'Password does not match the confirm password',
  },
  incorrectEmail: {
    id: 'error.incorrect-email',
    defaultMessage: 'Incorrect email address',
  },
  incorrectPhone: {
    id: 'error.incorrect-phone',
    defaultMessage: 'Incorrect phone number',
  },
  incorrectDate: {
    id: 'error.incorrect-date',
    defaultMessage: 'Incorrect date',
  },
  incorrectDateTime: {
    id: 'error.incorrect-date-time',
    defaultMessage: 'Incorrect date and time',
  },
  incorrectPositiveInteger: {
    id: 'error.incorrect-positive-integer',
    defaultMessage: 'Only Positive Integers',
  },
  valueCannotBeZero: {
    id: 'error.incorrect-non-zero',
    defaultMessage: 'Value must be greater than \'0\'.',
  },
  isNotFilled: {
    id: 'error.is-not-filled',
    defaultMessage: 'Is not filled',
  },
  required: {
    id: 'error.required',
    defaultMessage: 'Required',
  },
  internalServerError: {
    id: 'error.internal-server-error',
    defaultMessage: 'Internal server error',
  },
  noCompanyWithSuchCodeError: {
    id: 'error.no-company-with-such-code-error',
    defaultMessage: 'Company with current code has not been found',
  },
  companyRequestIsNotAllowed: {
    id: 'error.company-request-is-not-allowed',
    defaultMessage: 'Company Request with these companies is not allowed',
  },
  companyRequestLinkingToItself: {
    id: 'error.company-request-linking-to-itself',
    defaultMessage: 'Company sends request on linking to itself',
  },
  companyRequestAlreadyExists: {
    id: 'error.company-request-already-exists',
    defaultMessage: 'Company Request with these companies already exists',
  },
  orderExistsForProject: {
    id: 'error.order-exists-for-project',
    defaultMessage: 'Cannot delete project: Order exists for project',
  },
  cableMassTooBig: {
    id: 'error.cable-mass-too-big',
    defaultMessage: 'Cable mass is too big',
  },
  itemAlreadyExistsInTheDatabase: {
    id: 'error.backend.item-already-exists-in-the-database',
    defaultMessage: '{x} already exists in the database',
  },
  awsAliasExistsException: {
    id: 'error.aws.alias-exists-exception',
    defaultMessage: 'The email or phone number you have entered is already linked to another account.',
  },
  awsCodeDeliveryFailureException: {
    id: 'error.aws.code-delivery-failure-exception',
    defaultMessage: 'The verification code was not delivered',
  },
  awsCodeMismatchException: {
    id: 'error.aws.code-mismatch-exception',
    defaultMessage: 'You have entered an invalid code',
  },
  awsExpiredCodeException: {
    id: 'error.aws.expired-code-exception',
    defaultMessage: 'This code has expired. Please send another request.',
  },
  awsInternalErrorException: {
    id: 'error.aws.internal-error-exception',
    defaultMessage: 'There is a system error. Please try again later. We apologize.',
  },
  awsInvalidEmailRoleAccessPolicyException: {
    id: 'error.aws.invalid-email-role-access-policy-exception',
    defaultMessage: 'Bad Request. Enter a valid email address',
  },
  awsInvalidLambdaResponseException: {
    id: 'error.aws.invalid-lambda-response-exception',
    defaultMessage: 'There is a login authentication error. Try login again.',
  },
  awsInvalidParameterException: {
    id: 'error.aws.invalid-parameter-exception',
    defaultMessage: 'You have entered an invalid Parameter.',
  },
  awsUnverifiedEmailException: {
    id: 'error.aws.unverified-email-exception',
    defaultMessage: 'You must verify your email first.',
  },
  awsInvalidPasswordException: {
    id: 'error.aws.invalid-password-or-email-exception',
    defaultMessage: 'Invalid email or password.',
  },
  awsTemporaryPasswordResent: {
    id: 'error.aws.temporary-password-resent',
    defaultMessage: 'User password cannot be reset in the current state.',
  },
  awsCannotResetPassContactSupport: {
    id: 'error.aws.cannot-reset-pass-contact-support',
    defaultMessage: 'User password cannot be reset! Please contact support.',
  },
  awsInvalidSmsRoleAccessPolicyException: {
    id: 'error.aws.invalid-sms-role-access-policy-exception',
    defaultMessage: 'Confirmation code could not be sent',
  },
  awsInvalidSmsRoleTrustRelationshipException: {
    id: 'error.aws.invalid-sms-role-trust-relationship-exception',
    defaultMessage: 'There is a critical error. The system administrator has been notified.',
  },
  awsInvalidUserPoolConfigurationException: {
    id: 'error.aws.invalid-user-pool-configuration-exception',
    defaultMessage: 'Sign up Error',
  },
  awsLimitExceededException: {
    id: 'error.aws.limit-exceeded-exception',
    defaultMessage: 'Error: Exceeded Resource Limit',
  },
  awsMFAMethodNotFoundException: {
    id: 'error.aws.mfa-method-not-found-exception',
    defaultMessage: 'Multifactor authentication Error',
  },
  awsNotAuthorizedException: {
    id: 'error.aws.not-authorized-exception',
    defaultMessage: 'You do not have the required permissions.',
  },
  awsTempPasswordExpired: {
    id: 'error.aws.temp-password-expired',
    defaultMessage: 'Your temporary password has expired. A new one has been sent to your email.',
  },
  awsWebsiteAccessNotPermitted: {
    id: 'error.aws.website-access-not-permitted',
    defaultMessage: 'Website access not permitted.',
  },
  awsPasswordAttemptsExceededException: {
    id: 'error.aws.password-attempts-exceeded-exception',
    defaultMessage: 'Password attempts exceeded.',
  },
  awsPasswordResetRequiredException: {
    id: 'error.aws.password-reset-required-exception',
    defaultMessage: 'Please reset your password',
  },
  awsPreconditionNotMetException: {
    id: 'error.aws.precondition-not-met-exception',
    defaultMessage: 'Error: Missing entry',
  },
  awsResourceNotFoundException: {
    id: 'error.aws.resource-not-found-exception',
    defaultMessage: 'The system could not locate the requested resource',
  },
  awsTooManyFailedAttemptsException: {
    id: 'error.aws.too-many-failed-attempts-exception',
    defaultMessage: 'Too many attempts.',
  },
  awsTooManyRequestsException: {
    id: 'error.aws.too-many-requests-exception',
    defaultMessage: 'Too many requests.',
  },
  awsUnexpectedLambdaException: {
    id: 'error.aws.unexpected-lambda-exception',
    defaultMessage: 'Error: Wrong entry',
  },
  awsUnsupportedUserStateException: {
    id: 'error.aws.unsupported-user-state-exception',
    defaultMessage: 'System Error: Failed to connect to server.',
  },
  awsUserImportInProgressException: {
    id: 'error.aws.user-import-in-progress-exception',
    defaultMessage: 'Changes could not be saved. System operation ongoing.',
  },
  awsUserLambdaValidationException: {
    id: 'error.aws.user-lambda-validation-exception',
    defaultMessage: 'Request could not be completed. Please contact our support.',
  },
  awsUsernameExistsException: {
    id: 'error.aws.username-exists-exception',
    defaultMessage: 'The username you have entered already exists.',
  },
  awsUserNotConfirmedException: {
    id: 'error.aws.user-not-confirmed-exception',
    defaultMessage: 'The new user is not confirmed.',
  },
  awsUserNotFoundException: {
    id: 'error.aws.user-not-found-exception',
    defaultMessage: 'The username you have entered does not exist.',
  },
  awsUserEmailExists: {
    id: 'error.aws.user-email-exists',
    defaultMessage: 'The email you have entered already exists.',
  },
  incorrectFormat: {
    id: 'error.incorrect-format',
    defaultMessage: 'Incorrect format',
  },
  mustNotBeLess: {
    id: 'error.must-not-be-less',
    defaultMessage: 'Must not be less than {x}',
  },
  mustNotBeMore: {
    id: 'error.must-not-be-more',
    defaultMessage: 'Must not be more than {x}',
  },
  onlyNumbers: {
    id: 'error.only-numbers-allowed',
    defaultMessage: 'Only numbers are allowed',
  },
  mustBeChecked: {
    id: 'error.must-be-checked',
    defaultMessage: 'Must be checked',
  },
  pageNotFound: {
    id: 'error.page-not-found',
    defaultMessage: '404: page was not found',
  },
  pageNotFoundSubtext: {
    id: 'error.page-not-found-subtext',
    defaultMessage: 'I am sorry user, I can\'t do that...',
  },
  pageNotFoundExplanation: {
    id: 'error.page-not-found-explanation',
    defaultMessage: 'The page you are looking for doesn\'t exist.',
  },
  recaptchaFailed: {
    id: 'error.recaptcha-failed',
    defaultMessage: 'ReCaptcha verification failed, there might be something wrong with your network connection.',
  },
});

export default messages;
