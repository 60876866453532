import { defineMessages } from 'react-intl';

const messages = defineMessages({
  events: {
    id: 'events.events',
    defaultMessage: 'Events',
  },
  eventType: {
    id: 'events.event-type',
    defaultMessage: 'Event type',
  },
  dateTime: {
    id: 'events.date-time',
    defaultMessage: 'Date/time',
  },
  address: {
    id: 'events.address',
    defaultMessage: 'Address: {address}',
  },
  typeStatus: {
    id: 'events.type.status',
    defaultMessage: 'Status',
  },
  typeLocation: {
    id: 'events.type.location',
    defaultMessage: 'Location',
  },
  typeSalesAndLogistics: {
    id: 'events.type.sales-and-logistics',
    defaultMessage: 'Sales & logistics',
  },
  typeCableLength: {
    id: 'events.type.cable-length',
    defaultMessage: 'Cable length',
  },
  typeDrumRentExpiration: {
    id: 'events.type.drum-rent-expiration',
    defaultMessage: 'Drum rent expiration',
  },
  typeDrumCondition: {
    id: 'events.type.drum-condition',
    defaultMessage: 'Drum condition',
  },
  logisticsCompany: {
    id: 'events.logistics-company',
    defaultMessage: 'Logistics company',
  },
  vendorLocation: {
    id: 'events.vendor-location',
    defaultMessage: 'Vendor location',
  },
  verbChanged: {
    id: 'events.verb-changed',
    defaultMessage: 'changed',
  },
});

export default messages;
