import { CHANGE_LOCALE } from 'dashboard/constants/actions'

import { currentUserLanguage, getMessagesForLocale } from 'dashboard/i18n'

const initialState = {
  locale: currentUserLanguage,
  messages: getMessagesForLocale(currentUserLanguage),
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_LOCALE:
      return { ...state, locale: action.locale, messages: getMessagesForLocale(action.locale) }
    default:
      return state
  }
}
