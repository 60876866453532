import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { authenticate } from '../store/actions'

class Authenticate extends React.Component {
  static propTypes = {
    isAuthenticated: PropTypes.any,
    unknown: PropTypes.node,
    authenticated: PropTypes.node,
    notAuthenticated: PropTypes.node,
    authenticate: PropTypes.func.isRequired,
  }

  state = {
    checkedAuthenticate: false,
  }

  componentDidMount() {
    this.props.authenticate(this.props.partnerKey).then(() => {
      this.setState({
        checkedAuthenticate: true,
      })
    })
  }

  renderComponent(Component) {
    return React.isValidElement(Component) ? Component : <Component />
  }

  render() {
    const { isAuthenticated, unknown = null, authenticated = null, notAuthenticated = null } = this.props
    return !this.state.checkedAuthenticate
      ? this.renderComponent(unknown)
      : isAuthenticated
      ? this.renderComponent(authenticated)
      : this.renderComponent(notAuthenticated)
  }
}

const mapStateToProps = (state, props) => ({
  isAuthenticated: state.user.isAuthenticated,
  partnerKey: props.location.search.includes('?partnerKey=') ? props.location.search.split('?partnerKey=').pop() : null,
})

const mapDispatchToProps = (dispatch) => ({
  authenticate: (partnerKey) => dispatch(authenticate(partnerKey)),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Authenticate))
