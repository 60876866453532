import { combineReducers } from 'redux'
import { SET_REQUEST, SET_QUERY_FILTER, SET_PAGING } from '../constants/actions'
import defaultPagingValues from '../../../constants/paging'

const listReducer = (state = [], action) => {
  switch (action.type) {
    case SET_REQUEST:
      return [...action.requests]
    default:
      return state
  }
}

const queryFilterReducer = (query = '', action) => {
  switch (action.type) {
    case SET_QUERY_FILTER:
      return action.query
    default:
      return query
  }
}

const pagingReducer = (state = defaultPagingValues, action) => {
  switch (action.type) {
    case SET_PAGING:
      return {
        ...state,
        ...action.paging,
      }
    default:
      return state
  }
}

export default combineReducers({
  requests: listReducer,
  queryFilter: queryFilterReducer,
  paging: pagingReducer,
})
