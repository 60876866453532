import { AuthenticationDetails, CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js'
import { config, CognitoIdentityCredentials } from 'aws-sdk/index'
import { fetchNoAuth } from 'dashboard/services/fetch'
import { getAwsConfig } from 'dashboard/etc/getConfig'
import endpoints from 'dashboard/etc/endpoints'

import {
  CONFIRM_REGISTRATION_REQUIRED,
  NEW_PASSWORD_REQUIRED,
  RESET_PASSWORD_REQUIRED,
  AUTHENTICATED,
  PASSWORD_RESET,
} from './constants/awsCognito'

const awsConfig = getAwsConfig()
const isExpired = (credentials) => Date.now() < credentials.expireTime - 60000

export const setAwsRegion = (region) =>
  config.update({
    region: region,
  })

const setAwsCredentials = (credentials) => (config.credentials = credentials)

const getCognitoUserPool = () =>
  new CognitoUserPool({
    UserPoolId: awsConfig.cognito.USER_POOL_ID,
    ClientId: awsConfig.cognito.APP_CLIENT_ID,
  })

const getCurrentUser = (pool) => pool.getCurrentUser()

const getCognitoUser = (username, userPool) =>
  new CognitoUser({
    Username: username,
    Pool: userPool,
  })

const getSession = (cognitoUser, next) =>
  cognitoUser.getSession((error, session) => {
    if (error) {
      throw error
    }
    next(session)
  })

const getJwtTokenFromSession = (session) => session.getIdToken().getJwtToken()

const getAwsCredentials = (userToken) => {
  const authenticator = `cognito-idp.${awsConfig.cognito.REGION}.amazonaws.com/${awsConfig.cognito.USER_POOL_ID}`

  return new CognitoIdentityCredentials({
    IdentityPoolId: awsConfig.cognito.IDENTITY_POOL_ID,
    Logins: {
      [authenticator]: userToken,
    },
  })
}

const fetchCognitoUserPool = () => Promise.resolve(getCognitoUserPool())

const fetchCognitoUser = (username) => fetchCognitoUserPool().then((pool) => getCognitoUser(username, pool))

const fetchCurrentUser = () =>
  fetchCognitoUserPool()
    .then((pool) => getCurrentUser(pool))
    .then((cognitoUser) => cognitoUser || Promise.reject(new Error('Empty user')))
    .then((cognitoUser) => fetchSession(cognitoUser).then(() => Promise.resolve(cognitoUser)))

const fetchSession = (cognitoUser) => new Promise((resolve) => getSession(cognitoUser, resolve))

const fetchCurrentUserSession = () => fetchCurrentUser().then((cognitoUser) => fetchSession(cognitoUser))

export const fetchCurrentUserToken = () => fetchCurrentUserSession().then((session) => getJwtTokenFromSession(session))

// re: https://gist.github.com/kndt84/5be8e86a15468ed1c8fc3699429003ad
const refreshSession = () =>
  new Promise((resolve, reject) =>
    fetchCurrentUser().then((cognitoUser) => {
      const refreshToken = cognitoUser.getSignInUserSession().refreshToken
      cognitoUser.refreshSession(refreshToken, (err) => {
        if (err) {
          reject(err)
        }
        resolve()
      })
    })
  )

const authenticateUser = (cognitoUser, authenticationDetails) =>
  new Promise((resolve, reject) => {
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH')
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        const authenticationPromise = Promise.resolve(setAwsRegion(awsConfig.cognito.REGION))
          .then(() => session.getIdToken().getJwtToken())
          .then((token) => getAwsCredentials(token))
          .then((credentials) => setAwsCredentials(credentials))
          .then(() => refreshSession())
          .then(() => ({
            state: AUTHENTICATED,
          }))

        resolve(authenticationPromise)
      },
      onFailure: (error) => {
        reject(error)
      },
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        delete userAttributes.email_verified
        const attributesData = Object.keys(requiredAttributes).reduce(
          (result, key) => (result[key] = userAttributes[key] || null),
          []
        )

        resolve({
          state: NEW_PASSWORD_REQUIRED,
          cognitoUser: cognitoUser,
          attributes: attributesData,
        })
      },
    })
  })

const authenticate = () => {
  if (config.credentials && isExpired(config.credentials)) {
    return Promise.resolve(config.credentials)
  }

  return Promise.resolve(setAwsRegion(awsConfig.cognito.REGION))
    .then(() => fetchCurrentUserToken())
    .then((token) => getAwsCredentials(token))
    .then((credentials) => setAwsCredentials(credentials))
}

const signIn = (username, password, validationData) => {
  const authenticationDetails = new AuthenticationDetails({
    Username: username,
    Password: password,
    ValidationData: { ...validationData, fromMobile: 'false' },
  })

  return fetchCognitoUser(username).then((cognitoUser) => authenticateUser(cognitoUser, authenticationDetails))
}

const signOut = () => {
  // Force clear cache cause of aws library don't do that on cognito sign out
  if (config.credentials && config.credentials.clearCachedId) {
    config.credentials.clearCachedId()
  }

  return fetchCurrentUser()
    .then((cognitoUser) => cognitoUser.signOut())
    .catch((error) => console.error(error))
}

const newPasswordChallengeComplete = (cognitoUser, newPassword, attributes) =>
  new Promise((resolve, reject) => {
    cognitoUser.completeNewPasswordChallenge(newPassword, attributes, {
      onSuccess: (session) => resolve(session),
      onFailure: (error) => reject(error),
    })
  })

const forgotPassword = (username) => {
  return fetchCognitoUser(username).then(
    (cognitoUser) =>
      new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
          onSuccess: (data) => console.info('CodeDeliveryData from forgotPassword: ', data),
          onFailure: (error) => reject(error),
          inputVerificationCode: (data) => {
            resolve({
              state: RESET_PASSWORD_REQUIRED,
              cognitoUser: cognitoUser,
            })
          },
        })
      })
  )
}

const resetPassword = (username, verificationCode, newPassword) =>
  fetchCognitoUser(username).then(
    (cognitoUser) =>
      new Promise((resolve, reject) =>
        cognitoUser.confirmPassword(verificationCode, newPassword, {
          onSuccess: () => resolve({ state: PASSWORD_RESET }),
          onFailure: (error) => reject(error),
        })
      )
  )

const resendTempPassword = (username) => {
  return fetchNoAuth.post(endpoints.userManagement.resendTempPassword, {
    body: JSON.stringify({
      email: username,
    }),
  })
}

const registerUser = ({ email, password, firstName, lastName, phone, lang, validationData }) =>
  fetchCognitoUserPool()
    .then(
      (pool) =>
        new Promise((resolve, reject) => {
          pool.signUp(
            email,
            password,
            [
              {
                Name: 'email',
                Value: email,
              },
            ],
            [
              {
                Name: 'firstName',
                Value: firstName,
              },
              {
                Name: 'lastName',
                Value: lastName,
              },
              {
                Name: 'phone',
                Value: phone,
              },
              {
                Name: 'lang',
                Value: lang,
              },
              {
                Name: 'recaptchaToken',
                Value: validationData.recaptchaToken,
              },
            ],
            (error, data) => {
              if (error) {
                reject(error)
                return
              }
              resolve(data)
            }
          )
        })
    )
    .then((userConfirmed) => {
      if (!userConfirmed) {
        return {
          state: CONFIRM_REGISTRATION_REQUIRED,
        }
      }
    })

const confirmRegistration = (username, confirmCode) =>
  fetchCognitoUser(username).then(
    (cognitoUser) =>
      new Promise((resolve, reject) => {
        cognitoUser.confirmRegistration(confirmCode, true, (error, result) => {
          if (error) {
            reject(error)
            return
          }
          resolve(result)
        })
      })
  )

const resendConfirmationCode = (username) =>
  fetchCognitoUser(username).then(
    (cognitoUser) =>
      new Promise((resolve, reject) => {
        cognitoUser.resendConfirmationCode((error, result) => {
          if (error) {
            reject(error)
            return
          }
          resolve(result)
        })
      })
  )

const changePassword = (oldPassword, newPassword) =>
  fetchCurrentUser().then(
    (cognitoUser) =>
      new Promise((resolve, reject) => {
        cognitoUser.changePassword(oldPassword, newPassword, (error, result) => {
          if (error) {
            reject(error)
            return
          }
          resolve(result)
        })
      })
  )

const getCurrentUserAttributes = () =>
  fetchCurrentUser()
    .then(
      (cognitoUser) =>
        new Promise((resolve, reject) => {
          cognitoUser.getUserAttributes((error, attributes) => {
            if (error) {
              reject(error)
              return
            }
            resolve(attributes)
          })
        })
    )
    .catch((error) => console.log(error))

const getCurrentUserGroups = () =>
  fetchCurrentUserSession().then((session) => session.getIdToken().payload['cognito:groups'])

const getCompanyId = () =>
  fetchCurrentUserSession().then((session) => session.getIdToken().payload['custom:company_id'])

const updateCurrentUserAttributes = (attributes) =>
  fetchCurrentUser()
    .then(
      (cognitoUser) =>
        new Promise((resolve, reject) => {
          cognitoUser.updateAttributes(attributes, (error, result) => {
            if (error) {
              reject(error)
              return
            }
            resolve(result)
          })
        })
    )
    .then(() => refreshSession())

export {
  authenticate,
  signIn,
  signOut,
  newPasswordChallengeComplete,
  forgotPassword,
  resetPassword,
  registerUser,
  confirmRegistration,
  resendConfirmationCode,
  changePassword,
  updateCurrentUserAttributes,
  getCurrentUserAttributes,
  getCurrentUserGroups,
  getCompanyId,
  refreshSession,
  resendTempPassword,
}
