import endpoints from '../../../etc/endpoints'
import fetch from '../../../services/fetch'

export const getMessages = (params) => {
  return fetch.post(endpoints.notifications.getMessages, { body: JSON.stringify(params) })
}

export const markRead = (ids) =>
  fetch.put(endpoints.notifications.markRead, { body: JSON.stringify({ ids: ids, read: true }) })

export const markAllAsRead = () => fetch.post(endpoints.notifications.markAllAsRead)

export const registerToken = (token) =>
  fetch.post(endpoints.notifications.registerToken, { body: JSON.stringify({ deviceToken: token }) })
