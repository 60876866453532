export const IS_FETCHING_ORDERS = 'dashboard/Orders/IS_FETCHING_ORDERS'
export const IS_FETCHING_ORDER = 'dashboard/Orders/IS_FETCHING_ORDER'
export const IS_FETCHING_DELIVERIES = 'dashboard/Orders/IS_FETCHING_DELIVERIES'
export const RECEIVE_ORDERS = 'dashboard/Orders/RECEIVE_ORDERS'
export const RECEIVE_ORDER = 'dashboard/Orders/RECEIVE_ORDER'
export const RECEIVE_PROJECTS = 'dashboard/Orders/RECEIVE_PROJECTS'
export const RECEIVE_DELIVERIES = 'dashboard/Orders/RECEIVE_DELIVERIES'
export const RECEIVE_VENDOR_LOCATIONS = 'dashboard/Orders/RECEIVE_VENDOR_LOCATIONS'
export const RECEIVE_CUSTOMER_INFO = 'dashboard/Orders/RECEIVE_CUSTOMER_INFO'
export const RECEIVE_COMPANY_USERS = 'dashboard/Orders/RECEIVE_COMPANY_USERS'
export const RESPONSIBLE_PERSON_ERROR = 'dashboard/Orders/RESPONSIBLE_PERSON_ERROR'
export const RESET_RESPONSIBLE_PERSON_ERROR = 'dashboard/Orders/RESET_RESPONSIBLE_PERSON_ERROR'
export const SET_PAGING = 'dashboard/Orders/SET_PAGING'
export const SET_QUERY_FILTER = 'dashboard/Orders/SET_QUERY_FILTER'
export const SHOW_DRUMS_MODAL = 'dashboard/Orders/SHOW_DRUMS_MODAL'
export const HIDE_DRUMS_MODAL = 'dashboard/Orders/HIDE_DRUMS_MODAL'

export const SET_DRUMS_IS_FETCHING = 'dashboard/Orders/SET_DRUMS_IS_FETCHING'
export const RECEIVE_DRUMS = 'dashboard/Orders/RECEIVE_DRUMS'
export const SET_DRUMS_PAGING = 'dashboard/Orders/SET_DRUMS_PAGING'
export const SET_DRUMS_QUERY = 'dashboard/Orders/SET_DRUMS_QUERY'
