import get from 'lodash/get'

export const drumSummaryToShort = (summary) => {
  const short = {
    id: get(summary, 'drum.drumItemId'),
    ownership: get(summary, 'summary.drum.ownership'),
    device: get(summary, 'device'),
    cableAmount: get(summary, 'cableAmount'),
    state: get(summary, 'state'),
    customer: get(summary, 'customer'),
  }

  Object.keys(short).forEach((key) => {
    if (typeof short[key] === 'undefined') {
      delete short[key]
    }
  })

  return short
}
