export const RECEIVE_JOIN_REQUESTS = 'dashboard/UserManagement/RECEIVE_JOIN_REQUESTS'
export const RECEIVE_USERS = 'dashboard/UserManagement/RECEIVE_USERS'

export const SET_PAGING = 'dashboard/UserManagement/SET_PAGING'
export const SET_QUERY_FILTER = 'dashboard/UserManagement/SET_QUERY_FILTER'
export const RECEIVE_USER = 'dashboard/UserManagement/RECEIVE_USER'
export const SET_IS_FETCHING = 'dashboard/UserManagement/SET_IS_FETCHING'

export const RECEIVE_INSTALLERS = 'dashboard/UserManagement/RECEIVE_INSTALLERS'
export const RECEIVE_INSTALLERS_PAGING = 'dashboard/UserManagement/RECEIVE_INSTALLERS_PAGING'

export const RECEIVE_RESPONSIBLE_PERSONS_PAGING = 'dashboard/UserManagement/RECEIVE_RESPONSIBLE_PERSONS_PAGING'
export const RECEIVE_RESPONSIBLE_PERSONS = 'dashboard/UserManagement/RECEIVE_RESPONSIBLE_PERSONS'
