export const RECEIVE_PROJECTS = 'dashboard/Projects/RECEIVE_PROJECTS'
export const RECEIVE_PROJECT = 'dashboard/Projects/RECEIVE_PROJECT'
export const RECEIVE_ORDERS = 'dashboard/Projects/RECEIVE_ORDERS'
export const RECEIVE_CONSUMPTION = 'dashboard/Projects/RECEIVE_CONSUMPTION'
export const RECEIVE_COMPANY_LOCATIONS = 'dashboard/Projects/RECEIVE_COMPANY_LOCATIONS'
export const RECEIVE_COMPANY_USERS = 'dashboard/Projects/RECEIVE_COMPANY_USERS'

export const IS_FETCHING_PROJECTS = 'dashboard/Projects/IS_FETCHING_PROJECTS'
export const IS_FETCHING_PROJECT = 'dashboard/Projects/IS_FETCHING_PROJECT'
export const IS_FETCHING_ORDERS = 'dashboard/Projects/IS_FETCHING_ORDERS'
export const IS_FETCHING_CONSUMPTION = 'dashboard/Projects/IS_FETCHING_CONSUMPTION'

export const SET_PAGING = 'dashboard/Projects/SET_PAGING'
export const SET_QUERY_FILTER = 'dashboard/Projects/SET_QUERY_FILTER'

export const RECEIVE_DRUMS = 'dashboard/Projects/RECEIVE_DRUMS'
export const SET_DRUMS_PAGING = 'dashboard/Projects/SET_DRUMS_PAGING'
export const SET_DRUMS_QUERY = 'dashboard/Projects/SET_DRUMS_QUERY'

export const SET_ORDERS_QUERY = 'dashboard/Projects/SET_ORDERS_QUERY'

export const RESPONSIBLE_PERSON_ERROR = 'dashboard/Orders/RESPONSIBLE_PERSON_ERROR'
export const RESET_RESPONSIBLE_PERSON_ERROR = 'dashboard/Orders/RESET_RESPONSIBLE_PERSON_ERROR'

export const TOGGLE_PROJECT_CHECKBOX = 'dashboard/Projects/TOGGLE_PROJECT_CHECKBOX'
