import { defineMessages } from 'react-intl';

const messages = defineMessages({
  settings: {
    id: 'notifications.settings',
    defaultMessage: 'Notification Settings',
  },
  settingsSaved: {
    id: 'notifications.settings-saved',
    defaultMessage: 'Notification settings have been saved',
  },
  message: {
    id: 'notifications.message',
    defaultMessage: 'Message',
  },
  type: {
    id: 'notifications.type',
    defaultMessage: 'Notification type',
  },
  received: {
    id: 'notifications.received',
    defaultMessage: 'Received',
  },
  email: {
    id: 'notifications.email',
    defaultMessage: 'Email',
  },
  push: {
    id: 'notifications.push',
    defaultMessage: 'Push',
  },
  tipPush: {
    id: 'notifications.tip-push',
    defaultMessage: 'Push notifications are messages that can be sent directly to a user\'s mobile device, when the TrackMyDrum app has been downloaded. ',
  },
  web: {
    id: 'notifications.web',
    defaultMessage: 'Web notifications',
  },
  activateAll: {
    id: 'notifications.activateAll',
    defaultMessage: 'Activate All',
  },
  deactivateAll: {
    id: 'notifications.deactivateAll',
    defaultMessage: 'Deactivate all',
  },
  enterCommaSeparatedValues: {
    id: 'notifications.enter-comma-separated-values',
    defaultMessage: 'Enter comma-separated values',
  },
  markAllAsRead: {
    id: 'notifications.mark-all-as-read',
    defaultMessage: 'Mark all as read',
  },
  DELIVERIES: {
    id: 'notifications.DELIVERIES',
    defaultMessage: 'DELIVERIES',
  },
  DRUMS: {
    id: 'notifications.DRUMS',
    defaultMessage: 'DRUMS',
  },
  GENERAL: {
    id: 'notifications.GENERAL',
    defaultMessage: 'GENERAL',
  },
  ORDERS: {
    id: 'notifications.ORDERS',
    defaultMessage: 'ORDERS',
  },
  PROJECTS: {
    id: 'notifications.PROJECTS',
    defaultMessage: 'PROJECTS',
  },
  NEW_USER_REQUEST: {
    id: 'notifications.NEW_USER_REQUEST',
    defaultMessage: 'When a new user request to join my company',
  },
  USER_REQUEST_ACCEPTED: {
    id: 'notifications.USER_REQUEST_ACCEPTED',
    defaultMessage: 'When a new user join request accepted',
  },
  USER_REQUEST_REJECTED: {
    id: 'notifications.USER_REQUEST_REJECTED',
    defaultMessage: 'When a new user join request rejected',
  },
  USER_REQUEST_CANCELLED: {
    id: 'notifications.USER_REQUEST_CANCELLED',
    defaultMessage: 'When a new user join request canceled',
  },
  NEW_COMPANY_ASSOCIATED: {
    id: 'notifications.NEW_COMPANY_ASSOCIATED',
    defaultMessage: 'When a new company links to my company',
  },
  DRUM_DAMAGED: {
    id: 'notifications.DRUM_DAMAGED',
    defaultMessage: 'When a damage condition is reported',
  },
  DRUM_READY_TO_RETURN: {
    id: 'notifications.DRUM_READY_TO_RETURN',
    defaultMessage: 'When a drum is ready to return',
  },
  DRUM_READY_TO_RETURN_FORCE: {
    id: 'notifications.DRUM_READY_TO_RETURN_FORCE',
    defaultMessage: 'When a drum is ready to return (forced)',
  },
  DRUM_REJECTED: {
    id: 'notifications.DRUM_REJECTED',
    defaultMessage: 'When a drum is rejected upon delivery',
  },
  DRUM_CABLE_CONSUMED: {
    id: 'notifications.DRUM_CABLE_CONSUMED',
    defaultMessage: 'When a drum\'s cable is consumed',
  },
  DRUM_CABLE_LOW: {
    id: 'notifications.DRUM_CABLE_LOW',
    defaultMessage: 'When a drum\'s remaining cable length is below {x}%',
  },
  DRUM_EXPIRED: {
    id: 'notifications.DRUM_EXPIRED',
    defaultMessage: 'When a drums expiration date is within 1 month',
  },
  DRUM_MOVED_OUT: {
    id: 'notifications.DRUM_MOVED_OUT',
    defaultMessage: 'When a drum is being moved out {x} meters from project location',
  },
  PROJECT_CREATED: {
    id: 'notifications.PROJECT_CREATED',
    defaultMessage: 'When a new project is created',
  },
  PROJECT_EXPIRES_IN_X_DAYS: {
    id: 'notifications.PROJECT_EXPIRES_IN_X_DAYS',
    defaultMessage: 'When a project is expiring in {x} days',
  },
  ORDER_CREATED: {
    id: 'notifications.ORDER_CREATED',
    defaultMessage: 'When a new order is created',
  },
  ORDER_DISPATCHED: {
    id: 'notifications.ORDER_DISPATCHED',
    defaultMessage: 'When an order is dispatched',
  },
  ORDER_DELIVERED: {
    id: 'notifications.ORDER_DELIVERED',
    defaultMessage: 'When an order is delivered',
  },
  ORDER_EXPIRES_IN_X_DAYS: {
    id: 'notifications.ORDER_EXPIRES_IN_X_DAYS',
    defaultMessage: 'When an order is expiring in {x} days',
  },
  ORDER_EXPIRES_TODAY: {
    id: 'notifications.ORDER_EXPIRES_TODAY',
    defaultMessage: 'When an order is expiring today',
  },
  ORDER_EXPIRED: {
    id: 'notifications.ORDER_EXPIRED',
    defaultMessage: 'Every {x} days after an order has expired',
  },
  DELIVERY_CREATED: {
    id: 'notifications.DELIVERY_CREATED',
    defaultMessage: 'When a new delivery is created',
  },
  DELIVERY_ACCEPTED: {
    id: 'notifications.DELIVERY_ACCEPTED',
    defaultMessage: 'When a delivery is fully/partially accepted by customer',
  },
  DELIVERY_ARRIVE_IN_X_DAYS: {
    id: 'notifications.DELIVERY_ARRIVE_IN_X_DAYS',
    defaultMessage: 'When a delivery is arriving in {x} days',
  },
  DELIVERY_ARRIVE_IN_X_HOURS: {
    id: 'notifications.DELIVERY_ARRIVE_IN_X_HOURS',
    defaultMessage: 'When a delivery is arriving in {x} hours',
  },
  EMAIL_DIGEST_DAILY: {
    id: 'notifications.EMAIL_DIGEST_DAILY',
    defaultMessage: 'Daily email digest',
  },
  PARTNER_LINKED: {
    id: 'notifications.PARTNER_LINKED',
    defaultMessage: 'When another company accepts invitation',
  },
  preferences: {
    id: 'notifications.preferences',
    defaultMessage: 'Preferences',
  },
});

export default messages;
