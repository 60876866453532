import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const RedirectHashPrefixedLocation = () => (
  <Route
    render={({ location }) => {
      if (location.hash.indexOf('#!/') === 0) {
        return <Redirect to={location.hash.substring(2)} />
      }
      return null
    }}
  />
)

export default RedirectHashPrefixedLocation
