export const GEOFENCE_OUT = 'GEOFENCE_OUT'
export const GEOFENCE_IN = 'GEOFENCE_IN'
export const IN_TRANSIT = 'IN_TRANSIT'
export const READY_FOR_DELIVERY = 'READY_FOR_DELIVERY'
export const DELIVERED = 'DELIVERED'
export const NOT_DELIVERED = 'NOT_DELIVERED'
export const DELIVERED_AT_PLANT = 'DELIVERED_AT_PLANT'
export const READY_FOR_COLLECTING = 'READY_FOR_COLLECTING'
export const COLLECTED = 'COLLECTED'
export const NOT_COLLECTED = 'NOT_COLLECTED'
export const AUDIT = 'AUDIT'

export const STATUS = 'STATUS'
export const LOCATION = 'LOCATION'
export const SALES_AND_LOGISTICS = 'SALES_AND_LOGISTICS'
export const CABLE_LENGTH = 'CABLE_LENGTH'
export const CABLE_LENGTH_CHANGED = 'CABLE_LENGTH_CHANGED'
export const DRUM_CONDITION = 'DRUM_CONDITION'
export const DRUM_RENT_EXPIRATION = 'DRUM_RENT_EXPIRATION'
export const CABLE_SPLIT_TARGET = 'CABLE_SPLIT_TARGET'
export const CABLE_SPLIT_SOURCE = 'CABLE_SPLIT_SOURCE'
