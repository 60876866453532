import { RECEIVE_MAP_DRUMS } from 'dashboard/constants/actions'

export default (items = [], action) => {
  switch (action.type) {
    case RECEIVE_MAP_DRUMS:
      return [...action.drums]
    default:
      return items
  }
}
