import { defineMessages } from 'react-intl';

const messages = defineMessages({
  searchPlaceholder: {
    id: 'deliveries.search-placeholder',
    defaultMessage: 'Search by address',
  },
  deliveries: {
    id: 'deliveries.deliveries',
    defaultMessage: 'Deliveries',
  },
  delivery: {
    id: 'deliveries.delivery',
    defaultMessage: 'Delivery',
  },
  editDelivery: {
    id: 'deliveries.edit',
    defaultMessage: 'Edit delivery',
  },
  createNewDelivery: {
    id: 'deliveries.create-new-delivery',
    defaultMessage: 'Create new delivery',
  },
  forExampleDeliveryNote: {
    id: 'deliveries.for-example-delivery-note',
    defaultMessage: 'e.g., Delivery note',
  },
  drums: {
    id: 'deliveries.drums',
    defaultMessage: 'Drums',
  },
  customerLocation: {
    id: 'deliveries.customer-location',
    defaultMessage: 'Customer location',
  },
  shippingTime: {
    id: 'deliveries.shipping-time',
    defaultMessage: 'Expected delivery date',
  },
  project: {
    id: 'deliveries.project',
    defaultMessage: 'Project',
  },
  order: {
    id: 'deliveries.order',
    defaultMessage: 'Order ID',
  },
  state: {
    id: 'deliveries.state',
    defaultMessage: 'Status',
  },
  deliveryState: {
    id: 'deliveries.delivery-state',
    defaultMessage: 'Delivery status',
  },
  deliveryActions: {
    id: 'deliveries.delivery-actions',
    defaultMessage: 'Delivery actions',
  },
  pickupLocation: {
    id: 'deliveries.pickup-location',
    defaultMessage: 'Pickup Location',
  },
  deliveryLocation: {
    id: 'deliveries.delivery-location',
    defaultMessage: 'Delivery Location',
  },
  vehicleType: {
    id: 'deliveries.vehicle-type',
    defaultMessage: 'Vehicle Type',
  },
  pickupDate: {
    id: 'deliveries.pickup-date',
    defaultMessage: 'Pickup Date',
  },
  expectedDeliveryDate: {
    id: 'deliveries.expected-delivery-date',
    defaultMessage: 'Expected Delivery Date',
  },
  expectedDeliveryDateError: {
    id: 'deliveries.expected-delivery-date-error',
    defaultMessage: 'Please Select Date',
  },
  vehicleRegistrationNumber: {
    id: 'deliveries.vehicle-registration-number',
    defaultMessage: 'Vehicle Registration Number',
  },
  driversFullName: {
    id: 'deliveries.drivers-full-name',
    defaultMessage: 'Driver\'s Full Name',
  },
  driversContactDetails: {
    id: 'deliveries.drivers-contact-details',
    defaultMessage: 'Driver\'s Contact Details',
  },
  otherInformation: {
    id: 'deliveries.other-information',
    defaultMessage: 'Additional information',
  },
  note: {
    id: 'deliveries.note',
    defaultMessage: 'Note',
  },
  details: {
    id: 'deliveries.details',
    defaultMessage: 'Delivery Details',
  },
  drumsInDelivery: {
    id: 'deliveries.drums-in-delivery',
    defaultMessage: 'Drums in delivery',
  },
  backButton: {
    id: 'deliveries.back-button',
    defaultMessage: 'Back to deliveries list',
  },
  toVendor: {
    id: 'deliveries.to-vendor',
    defaultMessage: 'To vendor',
  },
  toCustomer: {
    id: 'deliveries.to-customer',
    defaultMessage: 'To customer',
  },
  IN: {
    id: 'deliveries.in',
    defaultMessage: 'Incoming',
  },
  tipIN: {
    id: 'deliveries.tip-in',
    defaultMessage: 'Delivery is shipped to your company. ',
  },
  OUT: {
    id: 'deliveries.out',
    defaultMessage: 'Outgoing',
  },
  tipOUT: {
    id: 'deliveries.tip-out',
    defaultMessage: 'Delivery is shipped to from your company to a third party.',
  },
  INTERNAL: {
    id: 'deliveries.internal',
    defaultMessage: 'Internal',
  },
  tipINTERNAL: {
    id: 'deliveries.tip-internal',
    defaultMessage: 'Delivery within your own company (between warehouses, projects, etc.)',
  },
  direction: {
    id: 'deliveries.direction',
    defaultMessage: 'Direction',
  },
  from: {
    id: 'deliveries.from',
    defaultMessage: 'Dispatch address',
  },
  to: {
    id: 'deliveries.to',
    defaultMessage: 'Delivery address',
  },
  recipient: {
    id: 'deliveries.recipient',
    defaultMessage: 'Recipient',
  },
  recipientCompany: {
    id: 'deliveries.recipient.company',
    defaultMessage: 'Recipient company',
  },
  customer: {
    id: 'deliveries.customer',
    defaultMessage: 'Recipient company',
  },
  sender: {
    id: 'deliveries.sender',
    defaultMessage: 'Sender',
  },
  deliveryTo: {
    id: 'deliveries.delivery-to',
    defaultMessage: 'Delivery to',
  },
  driverAndVehicle: {
    id: 'deliveries.driver-and-vehicle',
    defaultMessage: 'Driver and Vehicle',
  },
  logisticsPartner: {
    id: 'deliveries.logistics-partner',
    defaultMessage: 'Logistics partner',
  },
  logisticsPartnerInfo: {
    id: 'deliveries.logistics-partner-info',
    defaultMessage: 'Logistics partner information',
  },
  additionalInformation: {
    id: 'deliveries.additional-information',
    defaultMessage: 'Additional information',
  },
  attachedDocuments: {
    id: 'deliveries.attached-documents',
    defaultMessage: 'Attached documents',
  },
  attachedDeliveryNotes: {
    id: 'deliveries.attached-delivery-notes',
    defaultMessage: 'Attached delivery notes',
  },
  deliveryInformation: {
    id: 'deliveries.delivery-information',
    defaultMessage: 'Delivery information',
  },
  inTransitOutgoing: {
    id: 'deliveries.in-transit-outgoing',
    defaultMessage: 'In transit (outgoing)',
  },
  inTransitIncoming: {
    id: 'deliveries.in-transit-incoming',
    defaultMessage: 'In transit (incoming)',
  },
  deliveredOn: {
    id: 'deliveries.delivered-on',
    defaultMessage: 'Accepted on',
  },
  iamOwner: {
    id: 'deliveries.iam-owner',
    defaultMessage: 'Owner',
  },
  iamHolder: {
    id: 'deliveries.iam-holder',
    defaultMessage: 'Holder',
  },
});

export default messages;
