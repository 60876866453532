import {
  HIDE_DRUMS_MODAL,
  IS_FETCHING_DELIVERIES,
  IS_FETCHING_ORDER,
  IS_FETCHING_ORDERS,
  RECEIVE_COMPANY_USERS,
  RECEIVE_CUSTOMER_INFO,
  RECEIVE_DELIVERIES,
  RECEIVE_ORDER,
  RECEIVE_ORDERS,
  RECEIVE_PROJECTS,
  RECEIVE_VENDOR_LOCATIONS,
  SET_DRUMS_IS_FETCHING,
  SET_PAGING,
  SET_QUERY_FILTER,
  SHOW_DRUMS_MODAL,
  RECEIVE_DRUMS,
  SET_DRUMS_PAGING,
  SET_DRUMS_QUERY,
  RESPONSIBLE_PERSON_ERROR,
  RESET_RESPONSIBLE_PERSON_ERROR,
} from '../constants/actions'
import defaultPagingState from 'dashboard/constants/paging'

const defaultState = {
  list: [],
  item: null,
  isFetchingList: false,
  isFetchingDeliveries: false,
  paging: defaultPagingState,
  queryFilter: '',
  filters: {},
  projects: [],
  deliveries: [],
  vendorLocations: [],
  customerInfo: {},
  drumsModalIsOpen: false,
  drumsIsFetching: false,
  drums: [],
  drumsPaging: defaultPagingState,
  drumsQueryFilter: '',
  responsiblePersonError: false,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_ORDERS:
      return {
        ...state,
        list: action.orders,
      }
    case RECEIVE_ORDER:
      return {
        ...state,
        item: action.item,
      }
    case RESPONSIBLE_PERSON_ERROR:
      return {
        ...state,
        responsiblePersonError: true,
      }
    case RESET_RESPONSIBLE_PERSON_ERROR:
      return {
        ...state,
        responsiblePersonError: false,
      }
    case IS_FETCHING_ORDERS:
      return {
        ...state,
        isFetchingList: action.state,
      }
    case IS_FETCHING_ORDER:
      return {
        ...state,
        isFetchingItem: action.state,
      }
    case IS_FETCHING_DELIVERIES:
      return {
        ...state,
        isFetchingDeliveries: action.state,
      }
    case SET_PAGING:
      return {
        ...state,
        paging: action.paging,
      }
    case SET_QUERY_FILTER:
      return {
        ...state,
        queryFilter: action.query,
      }
    case SHOW_DRUMS_MODAL:
      return {
        ...state,
        drumsModalIsOpen: true,
      }
    case HIDE_DRUMS_MODAL:
      return {
        ...state,
        drumsModalIsOpen: false,
      }
    case SET_DRUMS_IS_FETCHING:
      return {
        ...state,
        drumsIsFetching: action.drumsIsFetching,
      }
    case RECEIVE_PROJECTS:
      return {
        ...state,
        projects: action.projects,
      }
    case RECEIVE_VENDOR_LOCATIONS:
      return {
        ...state,
        vendorLocations: action.vendorLocations,
      }
    case RECEIVE_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.customerInfo,
      }
    case RECEIVE_DELIVERIES:
      return {
        ...state,
        deliveries: action.deliveries,
      }
    case RECEIVE_COMPANY_USERS:
      return {
        ...state,
        companyUsers: action.users,
      }
    case RECEIVE_DRUMS:
      return {
        ...state,
        drums: action.drums,
      }
    case SET_DRUMS_PAGING:
      return {
        ...state,
        drumsPaging: action.paging,
      }
    case SET_DRUMS_QUERY:
      return {
        ...state,
        drumsQueryFilter: action.query,
      }
    default:
      return state
  }
}
