import { ALUMINIUM, COPPER } from '../constants/cableMaterials'
import { IRON_RANGE_WOODEN, PLASTIC, WOODEN } from '../constants/drumMaterials'
import {
  STATUS,
  LOCATION,
  SALES_AND_LOGISTICS,
  CABLE_LENGTH,
  DRUM_RENT_EXPIRATION,
  DRUM_CONDITION,
  CABLE_SPLIT_TARGET,
  CABLE_SPLIT_SOURCE,
  CABLE_LENGTH_CHANGED,
} from '../constants/events'
import * as accessibilityTypes from '../constants/accessibilityTypes'
import * as deliveryStates from '../modules/Deliveries/constants/states'
import * as drumStates from '../constants/drumStates'
import * as freeRentalExpire from '../constants/freeRentalExpire'
import * as notificationTypes from '../constants/notificationTypes'
import * as orderItemStates from '../constants/orderItemStates'
import * as orderStates from '../modules/Orders/constants/states'
import * as projectStates from '../modules/Projects/constants/state'
import * as rentalPeriodTriggers from '../modules/DrumManagement/constants/rentalPeriodTrigger'
import * as roles from '../constants/roles'
import deliveryMessages from '../translations/deliveries'
import deliveryStateMessages from '../translations/deliveryStates'
import drumMangeMessage from '../translations/drumManage'
import drumStateMessages from '../translations/drumStates'
import eventTypeMessages from '../translations/events'
import mapSwitcherMessages from '../translations/mapSwitcher'
import notificationTypesMessages from '../translations/notificationTypes'
import orderItemStateMessages from '../translations/orderItemStates'
import projectStatesMessages from '../translations/projectStates'
import rentalPeriodTriggerMessages from '../translations/rentalPeriodTriggers'
import rolesMessages from '../translations/roles'
import notificationMessages from '../translations/notification'

const snakeToSentenceCase = (constantValue) => {
  let constPart = constantValue.toString().replace(/_/g, ' ').toLowerCase()
  return constPart.charAt(0).toUpperCase() + constPart.slice(1)
}

const beautifyConstant = (constantValue) => {
  const translationShape = getTranslationShapeForConstant(constantValue)

  if (translationShape) {
    return translate(translationShape)
  } else if (constantValue) {
    return snakeToSentenceCase(constantValue)
  }
  return ''
}

let formatMessage = () => null

beautifyConstant.setFormatter = (formatter) => (formatMessage = formatter)

// TODO replace switch statement with object literals

const getTranslationShapeForConstant = (constantValue) => {
  switch (constantValue) {
    case drumStates.ACCEPTED:
      return drumStateMessages.accepted
    /* case drumStates.EMPTY:
      return drumStateMessages.empty */
    case drumStates.IN_TRANSIT:
      return drumStateMessages.inTransit
    /* case drumStates.NEW:
      return drumStateMessages.new */
    case drumStates.READY_FOR_DELIVERY:
      return drumStateMessages.readyForPickUp
    case drumStates.READY_FOR_RETURN:
      return drumStateMessages.readyForReturn
    case drumStates.WITH_CABLE:
      return drumStateMessages.withCable
    case drumStates.TO_OWNER:
      return drumStateMessages.incomingDrums
    case drumStates.TO_RECIPIENT:
      return drumStateMessages.outgoingDrums
    case IRON_RANGE_WOODEN:
      return drumMangeMessage.drumTypeMaterialIron
    case WOODEN:
      return drumMangeMessage.drumTypeMaterialWooden
    case PLASTIC:
      return drumMangeMessage.drumTypeMaterialPlastic
    case ALUMINIUM:
      return drumMangeMessage.cableMaterialAluminium
    case COPPER:
      return drumMangeMessage.cableMaterialCopper
    case STATUS:
      return eventTypeMessages.typeStatus
    case LOCATION:
      return eventTypeMessages.typeLocation
    case SALES_AND_LOGISTICS:
      return eventTypeMessages.typeSalesAndLogistics
    case DRUM_RENT_EXPIRATION:
      return eventTypeMessages.typeDrumRentExpiration
    case DRUM_CONDITION:
      return eventTypeMessages.typeDrumCondition
    case CABLE_LENGTH:
    case CABLE_LENGTH_CHANGED:
    case CABLE_SPLIT_TARGET:
    case CABLE_SPLIT_SOURCE:
      return eventTypeMessages.typeCableLength
    case notificationTypes.WELCOME_TO_THE_SYSTEM:
      return notificationTypesMessages.welcomeToTheSystem
    case notificationTypes.NEW_COMPANY_ASSOCIATED:
      return notificationTypesMessages.newCompanyAssociated
    case notificationTypes.NEW_USER_REQUEST:
      return notificationTypesMessages.newUserRequest
    case notificationTypes.USER_REQUEST_ACCEPTED:
      return notificationTypesMessages.userRequestAccepted
    case notificationTypes.USER_REQUEST_CANCELLED:
      return notificationTypesMessages.userRequestCancelled
    case notificationTypes.USER_REQUEST_REJECTED:
      return notificationTypesMessages.userRequestRejected
    case notificationTypes.DRUM_REJECTED:
      return notificationTypesMessages.drumRejected
    case notificationTypes.DRUM_READY_TO_RETURN:
      return notificationTypesMessages.drumReadyToReturn
    case notificationTypes.DRUM_DAMAGED:
      return notificationTypesMessages.drumDamaged
    case notificationTypes.DRUM_EXPIRED:
      return notificationTypesMessages.drumAboutToExpire
    case notificationTypes.DRUM_MOVED_OUT:
      return notificationTypesMessages.drumMovedOut
    case notificationTypes.DRUM_CABLE_CONSUMED:
      return notificationTypesMessages.drumCableConsumed
    case notificationTypes.CABLE_SPLIT_SOURCE:
      return notificationTypesMessages.drumCableSplit
    case notificationTypes.CABLE_SPLIT_TARGET:
      return notificationTypesMessages.drumCableSplit
    case notificationTypes.DRUM_CABLE_LOW:
      return notificationTypesMessages.drumCableLow
    case notificationTypes.ORDER_CREATED:
      return notificationTypesMessages.orderCreated
    case notificationTypes.ORDER_EXPIRED:
      return notificationTypesMessages.orderExpired
    case notificationTypes.ORDER_EXPIRES_TODAY:
      return notificationTypesMessages.orderExpiresToday
    case notificationTypes.ORDER_EXPIRES_IN_X_DAYS:
      return notificationTypesMessages.orderExpiresInXDays
    case notificationTypes.ORDER_DELIVERED:
      return notificationTypesMessages.orderDelivered
    case notificationTypes.ORDER_DISPATCHED:
      return notificationTypesMessages.orderDispatched
    case notificationTypes.DELIVERY_ACCEPTED:
      return notificationTypesMessages.deliveryAccepted
    case notificationTypes.DELIVERY_CREATED:
      return notificationTypesMessages.deliveryCreated
    case notificationTypes.DELIVERY_ARRIVE_IN_X_HOURS:
      return notificationTypesMessages.deliveryArriveInXHours
    case notificationTypes.DELIVERY_ARRIVE_IN_X_DAYS:
      return notificationTypesMessages.deliveryArriveInXDays
    case notificationTypes.PROJECT_CREATED:
      return notificationTypesMessages.projectCreated
    case notificationTypes.PROJECT_EXPIRES_IN_X_DAYS:
      return notificationTypesMessages.projectExpiresInXDays
    case notificationTypes.PARTNER_LINKED:
      return notificationTypesMessages.partnerLinked
    case orderItemStates.ACCEPTED:
      return orderItemStateMessages.accepted
    case orderItemStates.CREATED:
      return orderItemStateMessages.created
    case orderItemStates.REJECTED:
      return orderItemStateMessages.rejected
    case orderStates.CLOSED:
      return orderItemStateMessages.closed
    case orderStates.OPEN:
      return orderItemStateMessages.open
    case orderStates.RELEASED:
      return orderItemStateMessages.released
    case projectStates.OPEN:
      return projectStatesMessages.open
    case projectStates.ONGOING:
      return projectStatesMessages.ongoing
    case projectStates.OUTDATED:
      return projectStatesMessages.outdated
    case deliveryStates.DELIVERED:
      return deliveryStateMessages.delivered
    case deliveryStates.IN_TRANSIT:
      return deliveryStateMessages.inTransit
    case deliveryStates.PARTIALLY_DELIVERED:
      return deliveryStateMessages.partiallyDelivered
    /* case deliveryDirections.TO_CUSTOMER:
      return deliveryMessages.toCustomer
    case deliveryDirections.TO_VENDOR:
      return deliveryMessages.toVendor */
    case freeRentalExpire.MORE_30_DAYS:
      return mapSwitcherMessages.longExpiry
    case freeRentalExpire.LESS_30_DAYS:
      return mapSwitcherMessages.shortExpiry
    case freeRentalExpire.EXPIRED:
      return mapSwitcherMessages.expired
    case freeRentalExpire.NO_EXPIRY:
      return mapSwitcherMessages.noExpiry
    case accessibilityTypes.OWNER:
      return deliveryMessages.iamOwner
    case accessibilityTypes.RECIPIENT:
      return deliveryMessages.iamHolder
    case rentalPeriodTriggers.EMPTY_DRUM_ACCEPTED:
      return rentalPeriodTriggerMessages.emptyDrumAccepted
    case rentalPeriodTriggers.DRUM_WITH_CABLE_ACCEPTED:
      return rentalPeriodTriggerMessages.drumWithCableAccepted
    case roles.ADMIN:
      return rolesMessages.admin
    case roles.GUEST:
      return rolesMessages.guest
    case roles.MANAGER:
      return rolesMessages.manager
    case notificationMessages.settingsSaved:
      return notificationMessages.settingsSaved
    /* case roles.MASTER:
      return rolesMessages.master */
    default:
      return null
  }
}

const translate = (props) => formatMessage(props)

export default beautifyConstant

export const getTranslationShape = (constantValue) => {
  return getTranslationShapeForConstant(constantValue)
}
