import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

function mapStateToProps({ locales }) {
  const { locale, messages } = locales

  return { locale, messages, key: locale }
}

export default connect(mapStateToProps)(IntlProvider)
