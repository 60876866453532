import { CHANGE_LOCALE } from 'dashboard/constants/actions'

const changeLocaleAction = (locale) => {
  return {
    type: CHANGE_LOCALE,
    locale: locale,
  }
}

export const changeLocale = (languageLocale) => (dispatch) => {
  localStorage.setItem('selectedLocale', languageLocale)
  dispatch(changeLocaleAction(languageLocale))
}
