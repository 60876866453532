export const SET_COMPANY = 'dashboard/CompanyProfile/SET_COMPANY'
export const SET_LOCATIONS = 'dashboard/CompanyProfile/SET_LOCATIONS'
export const UPDATE_LOCATION = 'dashboard/CompanyProfile/UPDATE_LOCATION'
export const SET_LOCATION_COORDS = 'dashboard/CompanyProfile/SET_LOCATION_COORDS'
export const SET_LOCATION_ADDRESS = 'dashboard/CompanyProfile/SET_LOCATION_ADDRESS'

export const SET_PAGING = 'dashboard/CompanyProfile/SET_PAGING'
export const SET_QUERY_FILTER = 'dashboard/CompanyProfile/SET_QUERY_FILTER'

export const RECEIVE_COMPANY_USERS = 'dashboard/CompanyProfile/RECEIVE_COMPANY_USERS'
export const SET_EMPLOYEES_PAGING = 'dashboard/CompanyProfile/SET_EMPLOYEES_PAGING'
export const SET_EMPLOYEES_QUERY_FILTER = 'dashboard/CompanyProfile/SET_EMPLOYEES_QUERY_FILTER'
