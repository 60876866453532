import { RECEIVED_LOCATIONS, RECEIVED_CABLES } from '../constants/actions'

const defaultState = {
  cables: [],
  locations: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVED_CABLES:
      return {
        ...state,
        cables: action.cables,
      }
    case RECEIVED_LOCATIONS:
      return {
        ...state,
        locations: action.locations,
      }
    default:
      return state
  }
}
