import React from 'react'
import './style.scss'
import Spinner from 'react-spinkit'
import isFunction from 'lodash/isFunction'

const spinnerStyle = {
  width: '50px',
  height: '50px',
  margin: 'auto',
}

const spinnerColor = '#ffffff'

const AppPreloader = ({ error, retry }) => {
  if (error?.name === 'ChunkLoadError' || error?.code === 'CSS_CHUNK_LOAD_FAILED') {
    return (
      <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
        <h5>Looks like you are offline. Please check your network connectivity and try again.</h5>
      </div>
    )
  }
  if (error?.code === 'UserLambdaValidationException') {
    return (
      <div style={{ textAlign: 'center', paddingTop: '1rem' }}>
        <h2>Authentication error. Please try to reload the page, or clear your browser cache</h2>
        <h2>If the issue persists, notify us at dev@thinkt.digital</h2>
      </div>
    )
  }
  if (error && isFunction(retry)) {
    console.log(error)
    setTimeout(() => retry(), 500)
  }

  return (
    <div className="app-preloader">
      <Spinner name="cube-grid" color={spinnerColor} style={spinnerStyle} />
    </div>
  )
}

export default AppPreloader
