import endpoints from 'dashboard/etc/endpoints'
import fetch from 'dashboard/services/fetch'
import { geocodeCoordinates } from 'dashboard/services/googleMap'

export const getFilteredDrums = (params) => {
  //Map and list
  let results = fetch
    .post(endpoints.drums.list, {
      body: JSON.stringify(params),
    })
    .then(async (response) => {
      let drumPromises = response.results.map(async (drum) => {
        drum.location.address = await getAddress(drum.location)
        return drum
      })
      response.results = await Promise.all(drumPromises)

      return response
    })

  return results
}

export const getDrums = (payload) => {
  const limit = payload.limit || 20

  return fetch(endpoints.drums.list + `?limit=${limit}`)
}

const getAddress = async (location) => {
  let address = location ? location.address : '–'
  if (location && location.fromDevice) {
    const result = await geocodeCoordinates(location.latitude, location.longitude)
    address = result && result.length > 0 ? result[0].formatted_address : '–'
  }

  return address
}
