import { getConfig } from 'dashboard/etc/getConfig'

import { DRUM_STATES } from '../constants/dataTypes'
import { SET_ZOOM, SET_CENTER, SET_MARKER, SET_MAP_INSTANCE, SET_BOUNDS, SET_DATA_TYPE } from '../constants/actions'

const config = getConfig()

const initialState = {
  zoom: config.map.zoom,
  center: {
    lat: config.map.center.latitude,
    lng: config.map.center.longitude,
  },
  bounds: null,
  instance: null,
  dataType: DRUM_STATES,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ZOOM:
      return {
        ...state,
        zoom: action.zoom > 16 ? 16 : action.zoom,
      }
    case SET_CENTER:
      return {
        ...state,
        center: {
          lng: action.longitude,
          lat: action.latitude,
        },
      }
    case SET_MARKER:
      return {
        ...state,
        marker: action.marker,
      }
    case SET_MAP_INSTANCE:
      return {
        ...state,
        instance: action.instance,
      }
    case SET_BOUNDS:
      return {
        ...state,
        bounds: action.bounds,
      }
    case SET_DATA_TYPE:
      return {
        ...state,
        dataType: action.dataType,
      }
    default:
      return state
  }
}
