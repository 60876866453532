import React from 'react'
import { injectIntl } from 'react-intl'
import { setFormatter } from 'dashboard/services/translateError'

class TranslateErrorConfigure extends React.Component {
  componentDidMount() {
    setFormatter(this.props.intl.formatMessage)
  }

  render() {
    return null
  }
}

export default injectIntl(TranslateErrorConfigure)
