import { QUERY_FILTER_SET_QUERY } from 'dashboard/constants/actions'

export default (state = '', action) => {
  switch (action.type) {
    case QUERY_FILTER_SET_QUERY:
      return action.query
    default:
      return state
  }
}
