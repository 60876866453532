export const SET_USER_AUTHENTICATED = 'dashboard/Auth/SET_USER_AUTHENTICATED'
export const SET_USER_NOT_AUTHENTICATED = 'dashboard/Auth/SET_USER_NOT_AUTHENTICATED'
export const SET_USER_ATTRIBUTES = 'dashboard/Auth/SET_USER_ATTRIBUTES'
export const SET_USER_GROUPS = 'dashboard/Auth/SET_USER_GROUPS'
export const SET_USER_ROLES = 'dashboard/Auth/SET_USER_ROLES'
export const SET_USER_PERMISSIONS = 'dashboard/Auth/SET_USER_PERMISSIONS'
export const SET_USER_COMPANY_ID = 'dashboard/Auth/SET_USER_COMPANY_ID'
export const SET_NEW_PASSWORD_REQUIRED = 'dashboard/Auth/SET_NEW_PASSWORD_REQUIRED'
export const SET_RESET_PASSWORD_REQUIRED = 'dashboard/Auth/SET_RESET_PASSWORD_REQUIRED'
export const SET_USER_STATE = 'dashboard/Auth/SET_USER_STATE'
export const CLEAN_STATE = 'dashboard/Auth/CLEAN_STATE'
export const PARTNER_KEY = 'dashboard/Auth/PARTNER_KEY'
export const COMPANY_CODE = 'dashboard/Auth/COMPANY_CODE'
