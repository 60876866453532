// Requests
export const WELCOME_TO_THE_SYSTEM = 'WELCOME_TO_THE_SYSTEM'
export const NEW_COMPANY_ASSOCIATED = 'NEW_COMPANY_ASSOCIATED'
export const NEW_USER_REQUEST = 'NEW_USER_REQUEST'
export const USER_REQUEST_ACCEPTED = 'USER_REQUEST_ACCEPTED'
export const USER_REQUEST_CANCELLED = 'USER_REQUEST_CANCELLED'
export const USER_REQUEST_REJECTED = 'USER_REQUEST_REJECTED'
export const PARTNER_LINKED = 'PARTNER_LINKED'

// Drums
export const DRUM_REJECTED = 'DRUM_REJECTED'
export const DRUM_READY_TO_RETURN = 'DRUM_READY_TO_RETURN'
export const DRUM_DAMAGED = 'DRUM_DAMAGED'
export const DRUM_MOVED_OUT = 'DRUM_MOVED_OUT'
export const DRUM_CABLE_CONSUMED = 'DRUM_CABLE_CONSUMED'
export const CABLE_SPLIT_SOURCE = 'CABLE_SPLIT_SOURCE'
export const CABLE_SPLIT_TARGET = 'CABLE_SPLIT_TARGET'
export const DRUM_CABLE_LOW = 'DRUM_CABLE_LOW'
export const DRUM_EXPIRED = 'DRUM_EXPIRED'

// Orders
export const ORDER_CREATED = 'ORDER_CREATED'
export const ORDER_EXPIRED = 'ORDER_EXPIRED'
export const ORDER_EXPIRES_TODAY = 'ORDER_EXPIRES_TODAY'
export const ORDER_EXPIRES_IN_X_DAYS = 'ORDER_EXPIRES_IN_X_DAYS'
export const ORDER_DELIVERED = 'ORDER_DELIVERED'
export const ORDER_DISPATCHED = 'ORDER_DISPATCHED'

// Deliveries
export const DELIVERY_ACCEPTED = 'DELIVERY_ACCEPTED'
export const DELIVERY_CREATED = 'DELIVERY_CREATED'
export const DELIVERY_ARRIVE_IN_X_HOURS = 'DELIVERY_ARRIVE_IN_X_HOURS'
export const DELIVERY_ARRIVE_IN_X_DAYS = 'DELIVERY_ARRIVE_IN_X_DAYS'

// Projects
export const PROJECT_CREATED = 'PROJECT_CREATED'
export const PROJECT_EXPIRES_IN_X_DAYS = 'PROJECT_EXPIRES_IN_X_DAYS'
