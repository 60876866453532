import {
  RECEIVE_DRUM_TYPES,
  DELETE_DRUM_TYPE,
  SET_QUERY_FILTER,
  SET_PAGING,
  SET_IS_LOADING_DRUM_TYPES,
  RECEIVE_DRUM_TYPE,
} from '../constants/actions'
import { combineReducers } from 'redux'
import defaultPagingValues from 'dashboard/constants/paging'

const listReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_DRUM_TYPES:
      return [...action.drumTypes]
    case DELETE_DRUM_TYPE:
      return state.filter((item) => item.id !== action.id)
    default:
      return state
  }
}

const currentReducer = (state = null, action) => {
  switch (action.type) {
    case RECEIVE_DRUM_TYPE:
      return { ...action.drumType }
    default:
      return state
  }
}

const isLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case SET_IS_LOADING_DRUM_TYPES:
      return action.state
    default:
      return state
  }
}

const queryFilterReducer = (query = '', action) => {
  switch (action.type) {
    case SET_QUERY_FILTER:
      return action.query
    default:
      return query
  }
}

const pagingReducer = (state = defaultPagingValues, action) => {
  switch (action.type) {
    case SET_PAGING:
      return {
        ...state,
        ...action.paging,
      }
    default:
      return state
  }
}

export default combineReducers({
  list: listReducer,
  current: currentReducer,
  isLoading: isLoadingReducer,
  queryFilter: queryFilterReducer,
  paging: pagingReducer,
})

/*
    case TOGGLE_FILTER_OPTION:
      let selected = [
        ...field.selected,
      ];
      let optionIndex = selected.findIndex(option => option.value === action.option.value);
      if (optionIndex === -1) {
        selected.push(action.option);
      } else {
        selected.splice(optionIndex, 1);
      }

*/
