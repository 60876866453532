import defaultPagingState from 'dashboard/constants/paging'

import {
  SET_MESSAGES_PAGING,
  MESSAGES_RECEIVED,
  SET_IS_FETCHING,
  SET_IS_FETCHING_MARK_READ,
} from '../constants/actions'

const defaultState = {
  isFetching: false,
  isFetchingMarkRead: false,
  messages: [],
  paging: defaultPagingState,
  activeMessage: null,
  count: null,
  queryFilter: '',
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.state,
      }
    case MESSAGES_RECEIVED:
      return {
        ...state,
        messages: action.messages,
      }
    case SET_MESSAGES_PAGING:
      return {
        ...state,
        paging: action.paging,
      }
    case SET_IS_FETCHING_MARK_READ:
      return {
        ...state,
        isFetchingMarkRead: action.state,
      }
    default:
      return state
  }
}
