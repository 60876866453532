import React from 'react'
import OfferMobileApp from '../component/OfferMobileApp'
import MobileDetect from 'mobile-detect'

const STORAGE_KEY = 'isOfferMobileAppClosed'

const isClosed = () => {
  if (typeof Storage !== 'undefined') {
    let isClosed = sessionStorage.getItem(STORAGE_KEY)
    if (isClosed === null) {
      isClosed = ''
      sessionStorage.setItem(STORAGE_KEY, isClosed)
    }
    return Boolean(isClosed)
  } else {
    return null
  }
}

const isMobile = () => {
  const detect = new MobileDetect(window.navigator.userAgent)
  return Boolean(detect.mobile())
}

class OfferMobileAppContainer extends React.Component {
  state = {
    show: isMobile() && !isClosed(),
  }

  close() {
    this.setState({
      show: false,
    })
    sessionStorage.setItem(STORAGE_KEY, '1')
  }

  render() {
    return this.state.show && <OfferMobileApp onClose={() => this.close()} />
  }
}

export default OfferMobileAppContainer
