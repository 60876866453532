import {
  RESET_ALL_FILTERS,
  RESET_FILTER,
  SELECT_ALL_IN_FILTER,
  SET_FILTER_IS_FETCHING,
  SET_FILTER_OPTIONS,
  SET_FILTER_VALUE,
  TOGGLE_FILTER_OPTION,
  APPLY_FILTER,
  RESET_APPLY_FILTER,
  SHOW_MORE_FILTER,
} from '../constants/actions'

const defaultField = {
  selected: [],
  options: [],
  value: null,
}

const fieldReducer = (field = defaultField, action) => {
  switch (action.type) {
    case RESET_FILTER:
      return {
        ...field,
        selected: [],
      }
    case SELECT_ALL_IN_FILTER:
      const selectedValues = field.selected.map((item) => item.value)
      return {
        ...field,
        selected: [
          ...field.selected,
          ...field.options.filter((fieldValue) => selectedValues.indexOf(fieldValue.value) === -1),
        ],
      }
    case SET_FILTER_OPTIONS:
      return {
        ...field,
        options: action.options,
      }
    case SET_FILTER_IS_FETCHING:
      return {
        ...field,
        isFetching: action.state,
      }
    case TOGGLE_FILTER_OPTION:
      let selected = [...field.selected]
      let optionIndex = selected.findIndex((option) => option.value === action.option.value)
      if (optionIndex === -1) {
        selected.push(action.option)
      } else {
        selected.splice(optionIndex, 1)
      }
      return {
        ...field,
        selected,
      }
    case SET_FILTER_VALUE:
      return {
        ...field,
        selected: action.value,
      }
    default:
      return field
  }
}

const filterReducer = (filter = {}, action) => {
  switch (action.type) {
    case RESET_FILTER:
    case SELECT_ALL_IN_FILTER:
    case SET_FILTER_IS_FETCHING:
    case SET_FILTER_OPTIONS:
    case SET_FILTER_VALUE:
    case TOGGLE_FILTER_OPTION:
      return {
        ...filter,
        [action.fieldName]: fieldReducer(filter[action.fieldName], action),
      }
    case APPLY_FILTER:
      return {
        ...filter,
        apply: true,
      }
    case RESET_APPLY_FILTER:
      return {
        ...filter,
        apply: false,
      }
    case SHOW_MORE_FILTER:
      return {
        ...filter,
        showMoreFilter: action.show,
      }
    case RESET_ALL_FILTERS:
      return Object.keys(filter).reduce((newFilter, key) => {
        newFilter[key] = fieldReducer(filter[key], { type: RESET_FILTER })
        return newFilter
      }, {})
    default:
      return filter
  }
}

export default (filters = {}, action) => {
  switch (action.type) {
    case RESET_ALL_FILTERS:
    case RESET_FILTER:
    case SELECT_ALL_IN_FILTER:
    case SET_FILTER_IS_FETCHING:
    case SET_FILTER_OPTIONS:
    case SET_FILTER_VALUE:
    case SHOW_MORE_FILTER:
    case TOGGLE_FILTER_OPTION:
      return {
        ...filters,
        [action.filterName]: filterReducer(filters[action.filterName], action),
      }
    case APPLY_FILTER:
      return {
        ...filters,
        [action.filterName]: filterReducer(filters[action.filterName], action),
      }
    case RESET_APPLY_FILTER:
      return {
        ...filters,
        [action.filterName]: filterReducer(filters[action.filterName], action),
      }
    default:
      return filters
  }
}
