import 'react-dates/initialize'
import { createBrowserHistory } from 'history'
import { Provider } from 'react-redux'
import firebase from 'firebase/app'
import React, { Component } from 'react'

import 'react-redux-toastr/src/styles/index.scss'

import BeautifyConfigure from './containers/configure/BeautifyConfigure'
import { getConfig } from './etc/getConfig'
import ConnectedIntlProvider from './containers/ConnectedIntlProvider'
import createStore from './store/createStore'
import FetchConfigure from './containers/configure/FetchConfigure'
import OfferMobileApp from './modules/OfferMobileApp'
import Router from './Router'
import TranslateErrorConfigure from './containers/configure/TranslateErrorConfigure'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { TriggersProvider, PublisherProvider } from 'reduxless/general/contexts'

const config = getConfig()
firebase.initializeApp(config.firebase)

const history = createBrowserHistory()

if (window.Cypress) {
  window.reactHistory = history
}

const store = createStore(history)

class App extends Component {
  componentDidMount() {
    loadReCaptcha(config.recaptchaSiteKey)
  }

  render() {
    return (
      <Provider store={store}>
        <TriggersProvider>
          <PublisherProvider>
            <ConnectedIntlProvider>
              <React.Fragment>
                <BeautifyConfigure />
                <FetchConfigure />
                <TranslateErrorConfigure />
                <OfferMobileApp />
                <Router history={history} />
              </React.Fragment>
            </ConnectedIntlProvider>
          </PublisherProvider>
        </TriggersProvider>
      </Provider>
    )
  }
}

export default App
