import React, { ReactNode, useState } from 'react'

interface Triggers {
  listReloadTrigger: number
  setListReloadTrigger: (trigger: number) => void
}

const initialState = {
  listReloadTrigger: Date.now(),
  setListReloadTrigger: (trigger: number) => {},
}

const TriggersContext = React.createContext<Triggers>(initialState)

export const TriggersProvider = ({ children }): ReactNode => {
  const [listReloadTrigger, setListReloadTrigger] = useState(Date.now())

  return (
    <TriggersContext.Provider value={{ listReloadTrigger, setListReloadTrigger }}>{children}</TriggersContext.Provider>
  )
}

export default TriggersContext
