import { PUT_POINTS_ON_MAP, REMOVE_POINT_FROM_MAP } from 'dashboard/constants/actions'

export default (point = null, action) => {
  switch (action.type) {
    case PUT_POINTS_ON_MAP:
      return [...action.point]
    case REMOVE_POINT_FROM_MAP:
      return null
    default:
      return point
  }
}
