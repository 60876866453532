import {
  IS_FETCHING_CONSUMPTION,
  IS_FETCHING_ORDERS,
  IS_FETCHING_PROJECT,
  IS_FETCHING_PROJECTS,
  RECEIVE_COMPANY_LOCATIONS,
  RECEIVE_COMPANY_USERS,
  RECEIVE_CONSUMPTION,
  RECEIVE_DRUMS,
  RECEIVE_ORDERS,
  RECEIVE_PROJECT,
  RECEIVE_PROJECTS,
  SET_DRUMS_PAGING,
  SET_DRUMS_QUERY,
  SET_PAGING,
  SET_QUERY_FILTER,
  TOGGLE_PROJECT_CHECKBOX,
  SET_ORDERS_QUERY,
  RESPONSIBLE_PERSON_ERROR,
  RESET_RESPONSIBLE_PERSON_ERROR,
} from '../constants/actions'
import defaultPagingState from 'dashboard/constants/paging'

const defaultState = {
  list: [],
  item: null,
  orders: [],
  consumption: [],
  companyLocations: [],
  isFetchingList: false,
  isFetchingItem: false,
  isFetchingOrder: false,
  ordersPaging: defaultPagingState,
  ordersQueryFilter: '',
  isFetchingConsumption: false,
  paging: defaultPagingState,
  queryFilter: '',
  drumsQueryFilter: '',
  drumsPaging: defaultPagingState,
  drums: [],
  checkedProjects: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case RECEIVE_PROJECTS:
      return {
        ...state,
        list: action.list,
      }
    case RECEIVE_PROJECT:
      return {
        ...state,
        item: action.item,
      }
    case RECEIVE_ORDERS:
      return {
        ...state,
        orders: action.orders,
      }
    case RECEIVE_CONSUMPTION:
      return {
        ...state,
        consumption: action.consumption,
      }
    case RECEIVE_COMPANY_LOCATIONS:
      return {
        ...state,
        companyLocations: action.companyLocations,
      }
    case IS_FETCHING_PROJECTS:
      return {
        ...state,
        isFetchingList: action.state,
      }
    case IS_FETCHING_PROJECT:
      return {
        ...state,
        isFetchingItem: action.state,
      }
    case IS_FETCHING_ORDERS:
      return {
        ...state,
        isFetchingOrder: action.state,
      }
    case IS_FETCHING_CONSUMPTION:
      return {
        ...state,
        isFetchingConsumption: action.state,
      }
    case SET_PAGING:
      return {
        ...state,
        paging: action.paging,
      }
    case SET_QUERY_FILTER:
      return {
        ...state,
        queryFilter: action.query,
      }
    case RECEIVE_COMPANY_USERS:
      return {
        ...state,
        companyUsers: action.users,
      }
    case RECEIVE_DRUMS:
      return {
        ...state,
        drums: action.drums,
      }
    case SET_DRUMS_PAGING:
      return {
        ...state,
        drumsPaging: action.paging,
      }
    case SET_DRUMS_QUERY:
      return {
        ...state,
        drumsQueryFilter: action.query,
      }
    case SET_ORDERS_QUERY:
      return {
        ...state,
        ordersQueryFilter: action.query,
      }

    case TOGGLE_PROJECT_CHECKBOX:
      let selected = [...state.checkedProjects]
      let checkedIndex = selected.findIndex((checked) => checked === action.projectId)
      if (checkedIndex === -1) {
        selected.push(action.projectId)
      } else {
        selected.splice(checkedIndex, 1)
      }
      return {
        ...state,
        checkedProjects: selected,
      }
    default:
      return state
  }
}
