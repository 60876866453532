export const RECEIVE_CABLE_TYPES = 'dashboard/CableManagement/RECEIVE_CABLE_TYPES'
export const RECEIVE_CABLE_TYPE = 'dashboard/CableManagement/RECEIVE_CABLE_TYPE'
export const DELETE_CABLE_TYPE = 'dashboard/CableManagement/DELETE_CABLE_TYPE'
export const SET_IS_LOADING_CABLE_TYPE = 'dashboard/CableManagement/SET_IS_LOADING_CABLE_TYPE'

export const SET_PAGING = 'dashboard/CableManagement/SET_PAGING'
export const SET_QUERY_FILTER = 'dashboard/CableManagement/SET_QUERY_FILTER'

export const SET_ATTACHING_DOCUMENT_IN_PROGRESS = 'dashboard/CableManagement/SET_ATTACHING_DOCUMENT_IN_PROGRESS'
export const CABLETYPE_DOCS_RECEIVED = 'dashboard/CableManagement/CABLETYPE_DOCS_RECEIVED'
export const SET_IS_FETCHING_DOC = 'dashboard/CableManagement/SET_IS_FETCHING_DOC'
