import {
  DELIVERIES_DETAILS_RECEIVED,
  DELIVERIES_RECEIVED,
  DRUM_PAGING_RECEIVED,
  PAGING_RECEIVED,
  RECEIVE_DRUMS,
  SET_DRUM_QUERY_FILTER,
  SET_DRUMS_IS_FETCHING,
  SET_IS_FETCHING,
  SET_QUERY_FILTER,
  SET_ATTACHING_DOCUMENT_IN_PROGRESS,
  DELIVERY_DOCS_RECEIVED,
  SET_IS_FETCHING_DOC,
} from '../constants/actions'
import defaultPagingState from '../../../constants/paging'

const defaultState = {
  isFetching: false,
  isFetchingDrums: false,
  isFetchingItem: false,

  deliveries: [],
  paging: defaultPagingState,
  queryFilter: '',
  drumsQueryFilter: '',
  drumsPaging: defaultPagingState,

  delivery: {},
  drums: [],

  isFetchingDocuments: false,
  documentUploadInProgress: false,
  documents: [],
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case DELIVERY_DOCS_RECEIVED:
      return {
        ...state,
        documents: action.documents,
      }
    case SET_IS_FETCHING:
      return {
        ...state,
        isFetching: action.state,
      }
    case SET_DRUMS_IS_FETCHING:
      return {
        ...state,
        isFetchingDrums: action.state,
      }

    case DELIVERIES_RECEIVED:
      return {
        ...state,
        deliveries: action.deliveries,
      }
    case RECEIVE_DRUMS:
      return {
        ...state,
        drums: action.drums,
      }
    case DELIVERIES_DETAILS_RECEIVED:
      return {
        ...state,
        delivery: action.delivery,
      }
    case PAGING_RECEIVED:
      return {
        ...state,
        paging: action.paging,
      }
    case SET_QUERY_FILTER:
      return {
        ...state,
        queryFilter: action.query,
      }
    case SET_DRUM_QUERY_FILTER:
      return {
        ...state,
        drumsQueryFilter: action.drumsQuery,
      }
    case DRUM_PAGING_RECEIVED:
      return {
        ...state,
        drumsPaging: action.paging,
      }
    case SET_ATTACHING_DOCUMENT_IN_PROGRESS:
      return {
        ...state,
        documentUploadInProgress: action.state,
      }
    case SET_IS_FETCHING_DOC:
      return {
        ...state,
        isFetchingDocuments: action.state,
      }
    default:
      return state
  }
}
