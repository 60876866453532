import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { routerMiddleware } from 'connected-react-router'
import mqtt from './middlewares/mqtt'
import aws from './middlewares/aws'
import createRootReducer from './reducers/index'
import gaPageViewMiddleware from './middlewares/googleAnalytics'

export default (history) => {
  const middleware = routerMiddleware(history)

  return createStore(
    createRootReducer(history),
    composeWithDevTools(compose(applyMiddleware(thunk, mqtt, aws, middleware, gaPageViewMiddleware)))
  )
}
