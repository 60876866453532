export const DRUM_NOT_FOUND = '733a20c3-58d9-411d-96fc-d2f4469c395c'
export const COMPANY_NOT_FOUND = 'b72d3ed0-e50f-4529-9cb7-cd5b02c88ce0'
export const COMPANY_LOCATION_NOT_FOUND = '8940cca7-53f7-4a96-8c6b-91c980108393'
export const COMPANY_LAST_LOCATION = '2cc40ebf-eb5c-4c5d-8d53-080077ec497c'
export const COMPANY_CODE_EXIST = '2a748f3c-33dc-4091-b272-69436ff9bbea'
export const NOT_COMPANY_DRUM = '0491940c-ddbb-4bea-a01a-321c587d1896'
export const SECOND_ADMIN_COMPANY = 'acd96753-fba7-4eb8-b85a-2f1c2bcbd328'
export const DRUM_CABLE_NOT_FOUND = '9481b6a5-1ac1-442b-80b8-af730a74a91c'
export const DRUM_STATE_WITH_CABLE = '305c181c-3fd6-4d1f-932a-e0d9e443dfd2'
export const GUEST_REQUEST_NOT_FOUND = 'a68bd350-fcb3-48ee-81e4-137531e5e345'
export const COGNITO_USER_NOT_FOUND = 'c63dab6c-3433-49fe-acea-780dff6a8952'
export const ROLE_NOT_FOUND = 'b831d05e-6cf0-4749-9bc6-28ae44281bb2'
export const USER_NOT_FOUND = '2539c583-48f2-4f04-ba84-29dd72b79be9'
export const USER_EMAIL_ALREADY_EXISTS = '5ec20ddb-4a8d-4944-9969-fff75919238f'
export const COMPANY_REQUEST_NOT_FOUND = 'cb6e9c3a-7d75-48e2-98b5-fe0fe2b35768'

export const COMPANY_REQUEST_IS_NOT_ALLOWED = 'ed089ab5-ea3a-43a0-9d7c-bb01a5660635'
export const COMPANY_REQUEST_LINKING_TO_ITSELF = '8c9eada9-d7b5-40d7-9653-b48c2c326908'
export const COMPANY_REQUEST_ALREADY_EXISTS = '20244a2a-d381-49a3-b921-b08beef0c6a1'

export const ORDER_EXISTS_FOR_PROJECT = '9972a123-70e8-4864-8e95-f4e32a23ba8e'

export const BAD_REQUEST = '6ffb2d9c-9e04-4c9c-8f21-bf3fa9f5d015'
export const ACCESS_DENIED = '22211ec3-7c60-4b84-9407-f91ddc429626'
export const UNEXPECTED_SERVER_ERROR = 'e22bc3da-7c0c-4cfa-8928-3ccbf9b2cce7'

export const AWS_USER_NOT_CONFIRMED = 'UserNotConfirmedException'
export const AWS_USER_NOT_AUTH = 'NotAuthorizedException'

export const CANT_DELETE_DRUM = 'aa711de7-ae0f-452e-a5b4-c00c66749096'
export const DRUM_USED_IN_ORDER = '48fe2639-70ab-47de-8cf9-b1eca914bec4'
export const CANT_DELETE_CABLE = 'b05b9a52-3b07-4598-a088-1951690acb96'
