import { defineMessages } from 'react-intl';

const messages = defineMessages({
  deliveryAccepted: {
    id: 'notificationTypes.DELIVERY_ACCEPTED',
    defaultMessage: 'Delivery accepted',
  },
  deliveryArriveInXDays: {
    id: 'notificationTypes.DELIVERY_ARRIVE_IN_X_DAYS',
    defaultMessage: 'Delivery arrives in X days',
  },
  deliveryArriveInXHours: {
    id: 'notificationTypes.DELIVERY_ARRIVE_IN_X_HOURS',
    defaultMessage: 'Delivery arrives in X hours',
  },
  deliveryCreated: {
    id: 'notificationTypes.DELIVERY_CREATED',
    defaultMessage: 'Delivery created',
  },
  drumCableConsumed: {
    id: 'notificationTypes.DRUM_CABLE_CONSUMED',
    defaultMessage: 'Cable length changed',
  },
  drumCableSplit: {
    id: 'notificationTypes.DRUM_CABLE_SPLIT',
    defaultMessage: 'Cable length changed',
  },
  drumCableLow: {
    id: 'notificationTypes.DRUM_CABLE_LOW',
    defaultMessage: 'Drum cable running low',
  },
  drumDamaged: {
    id: 'notificationTypes.DRUM_DAMAGED',
    defaultMessage: 'Drum damaged',
  },
  drumMovedOut: {
    id: 'notificationTypes.DRUM_MOVED_OUT',
    defaultMessage: 'Drum moved out',
  },
  drumReadyToReturn: {
    id: 'notificationTypes.DRUM_READY_TO_RETURN',
    defaultMessage: 'Drum ready to return',
  },
  drumRejected: {
    id: 'notificationTypes.DRUM_REJECTED',
    defaultMessage: 'Drum rejected',
  },
  drumAboutToExpire: {
    id: 'notificationTypes.DRUM_EXPIRED',
    defaultMessage: 'Drum will be deleted in 1 month',
  },
  newCompanyAssociated: {
    id: 'notificationTypes.NEW_COMPANY_ASSOCIATED',
    defaultMessage: 'New company associated',
  },
  newUserRequest: {
    id: 'notificationTypes.NEW_USER_REQUEST',
    defaultMessage: 'New user request',
  },
  orderCreated: {
    id: 'notificationTypes.ORDER_CREATED',
    defaultMessage: 'Order created',
  },
  orderDelivered: {
    id: 'notificationTypes.ORDER_DELIVERED',
    defaultMessage: 'Order delivered',
  },
  orderDispatched: {
    id: 'notificationTypes.ORDER_DISPATCHED',
    defaultMessage: 'Order dispatched',
  },
  orderExpired: {
    id: 'notificationTypes.ORDER_EXPIRED',
    defaultMessage: 'Order expired',
  },
  orderExpiresInXDays: {
    id: 'notificationTypes.ORDER_EXPIRES_IN_X_DAYS',
    defaultMessage: 'Order expires in x days',
  },
  orderExpiresToday: {
    id: 'notificationTypes.ORDER_EXPIRES_TODAY',
    defaultMessage: 'Order expires today',
  },
  projectCreated: {
    id: 'notificationTypes.PROJECT_CREATED',
    defaultMessage: 'Project created',
  },
  projectExpiresInXDays: {
    id: 'notificationTypes.PROJECT_EXPIRES_IN_X_DAYS',
    defaultMessage: 'Project expires in x days',
  },
  userRequestAccepted: {
    id: 'notificationTypes.USER_REQUEST_ACCEPTED',
    defaultMessage: 'User request accepted',
  },
  userRequestCancelled: {
    id: 'notificationTypes.USER_REQUEST_CANCELLED',
    defaultMessage: 'User request cancelled',
  },
  userRequestRejected: {
    id: 'notificationTypes.USER_REQUEST_REJECTED',
    defaultMessage: 'User request rejected',
  },
  welcomeToTheSystem: {
    id: 'notificationTypes.WELCOME_TO_THE_SYSTEM',
    defaultMessage: 'Welcome to the system',
  },
  partnerLinked: {
    id: 'notificationTypes.PARTNER_LINKED',
    defaultMessage: 'Company accepted invitation',
  },
});

export default messages;
