import { defineMessages } from 'react-intl';

const messages = defineMessages({
  expirationDate: {
    id: 'map-switcher.expiration-date',
    defaultMessage: 'Show expiration status',
  },
  drumStates: {
    id: 'map-switcher.drum-states',
    defaultMessage: 'Show drum status',
  },
  productionState: {
    id: 'map-switcher.production-state',
    defaultMessage: 'Drum Production',
  },
  emptyState: {
    id: 'map-switcher.empty-state',
    defaultMessage: 'Empty',
  },
  withCableState: {
    id: 'map-switcher.with-cable-state',
    defaultMessage: 'Drum with cable',
  },
  readyForPickUpState: {
    id: 'map-switcher.ready-for-pick-up-state',
    defaultMessage: 'Ready for delivery',
  },
  inTransitState: {
    id: 'map-switcher.in-transit-state',
    defaultMessage: 'In transit',
  },
  deliveredState: {
    id: 'map-switcher.delivered-state',
    defaultMessage: 'Delivered',
  },
  acceptedState: {
    id: 'map-switcher.accepted-state',
    defaultMessage: 'Accepted',
  },
  readyForReturnState: {
    id: 'map-switcher.ready-for-return-state',
    defaultMessage: 'Ready for return',
  },
  rejectedState: {
    id: 'map-switcher.rejected-state',
    defaultMessage: 'Rejected',
  },
  longExpiry: {
    id: 'map-switcher.long-expire',
    defaultMessage: 'More than 30 days',
  },
  shortExpiry: {
    id: 'map-switcher.short-expire',
    defaultMessage: 'Less than 30 days',
  },
  expired: {
    id: 'map-switcher.expired',
    defaultMessage: 'Rental period expired',
  },
  noExpiry: {
    id: 'map-switcher.no-expire',
    defaultMessage: 'No expiry date',
  },
  showOnlyMapDrums: {
    id: 'map-switcher.show-only-map-drums',
    defaultMessage: 'Only show drums in map view',
  },
});

export default messages;
