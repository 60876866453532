export const SET_IS_FETCHING = 'dashboard/Deliveries/SET_IS_FETCHING'
export const SET_IS_FETCHING_DOC = 'dashboard/Deliveries/SET_IS_FETCHING_DOC'
export const DELIVERIES_RECEIVED = 'dashboard/Deliveries/DELIVERIES_RECEIVED'
export const DELIVERIES_DETAILS_RECEIVED = 'dashboard/Deliveries/DELIVERIES_DETAILS_RECEIVED'
export const DELIVERY_DOCS_RECEIVED = 'dashboard/Deliveries/DELIVERY_DOCS_RECEIVED'
export const PAGING_RECEIVED = 'dashboard/Deliveries/PAGING_RECEIVED'
export const SET_QUERY_FILTER = 'dashboard/Deliveries/SET_QUERY_FILTER'
export const SET_DRUMS_IS_FETCHING = 'dashboard/Deliveries/SET_DRUMS_IS_FETCHING'
export const SET_DRUM_QUERY_FILTER = 'dashboard/Deliveries/SET_DRUM_QUERY_FILTER'
export const DRUM_PAGING_RECEIVED = 'dashboard/Deliveries/DRUM_PAGING_RECEIVED'
export const RECEIVE_DRUMS = 'dashboard/Deliveries/RECEIVE_DRUMS'
export const SET_ATTACHING_DOCUMENT_IN_PROGRESS = 'dashboard/Deliveries/SET_ATTACHING_DOCUMENT_IN_PROGRESS'
