import * as actions from '../constants/actions'
import * as userStates from '../constants/userStates'

const initialState = {
  isAuthenticated: undefined,
  isNewPasswordRequired: undefined,
  newPasswordContext: undefined,
  attributes: {},
  groups: undefined,
  companyId: undefined,
  partnerKey: undefined,
  companyCode: undefined,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_USER_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
      }
    case actions.SET_USER_NOT_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: false,
      }
    case actions.SET_USER_ATTRIBUTES:
      return {
        ...state,
        attributes: {
          ...state.attributes,
          ...action.attributes,
        },
      }
    case actions.SET_USER_GROUPS:
      return {
        ...state,
        groups: action.groups,
      }
    case actions.SET_USER_ROLES:
      return {
        ...state,
        roles: action.roles,
      }
    case actions.SET_USER_PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      }
    case actions.SET_NEW_PASSWORD_REQUIRED:
      return {
        ...state,
        isNewPasswordRequired: action.status,
        newPasswordContext: action.context,
      }
    case actions.SET_RESET_PASSWORD_REQUIRED:
      return {
        ...state,
        isResetPasswordRequired: action.status,
        resetPasswordContext: action.context,
      }
    case actions.SET_USER_STATE:
      let newState = {
        ...state,
        state: action.state,
        stateContext: action.context,
      }
      if (action.state === userStates.AUTHENTICATED) {
        newState = {
          ...newState,
          isAuthenticated: true,
        }
      } else if (action.state === userStates.NOT_AUTHENTICATED) {
        newState = {
          ...newState,
          isAuthenticated: false,
        }
      }
      return newState
    case actions.CLEAN_STATE:
      if (state.state === userStates.AUTHENTICATED) {
        return {
          ...state,
          stateContext: null,
        }
      } else {
        return {
          ...state,
          state: userStates.NOT_AUTHENTICATED,
          stateContext: null,
        }
      }
    case actions.PARTNER_KEY:
      return {
        ...state,
        partnerKey: action.state,
      }
    case actions.COMPANY_CODE:
      return {
        ...state,
        companyCode: action.state,
      }

    default:
      return state
  }
}
