import errorMessage from 'dashboard/translations/error'
import {
  COMPANY_NOT_FOUND,
  COMPANY_REQUEST_ALREADY_EXISTS,
  COMPANY_REQUEST_IS_NOT_ALLOWED,
  COMPANY_REQUEST_LINKING_TO_ITSELF,
  ORDER_EXISTS_FOR_PROJECT,
  UNEXPECTED_SERVER_ERROR,
  BAD_REQUEST,
  ACCESS_DENIED,
  DRUM_NOT_FOUND,
  COMPANY_LOCATION_NOT_FOUND,
  COMPANY_LAST_LOCATION,
  COMPANY_CODE_EXIST,
  CANT_DELETE_CABLE,
  CANT_DELETE_DRUM,
  DRUM_USED_IN_ORDER,
  USER_EMAIL_ALREADY_EXISTS,
} from 'dashboard/constants/errors'

const errorCodeToMessage = {
  backEnd_itemAlreadyExists: errorMessage.itemAlreadyExistsInTheDatabase,

  AliasExistsException: errorMessage.awsAliasExistsException,
  CodeDeliveryFailureException: errorMessage.awsCodeDeliveryFailureException,
  CodeMismatchException: errorMessage.awsCodeMismatchException,
  ExpiredCodeException: errorMessage.awsExpiredCodeException,
  InternalErrorException: errorMessage.awsInternalErrorException,
  InvalidEmailRoleAccessPolicyException: errorMessage.awsInvalidEmailRoleAccessPolicyException,
  InvalidLambdaResponseException: errorMessage.awsInvalidLambdaResponseException,
  InvalidParameterException: {
    default: errorMessage.awsInvalidParameterException,
    'Cannot reset password for the user as there is no registered/verified email or phone_number':
      errorMessage.awsUnverifiedEmailException,
  },
  InvalidPasswordException: errorMessage.awsInvalidPasswordException,
  TemporaryPasswordResent: errorMessage.awsTemporaryPasswordResent,
  InvalidSmsRoleAccessPolicyException: errorMessage.awsInvalidSmsRoleAccessPolicyException,
  InvalidSmsRoleTrustRelationshipException: errorMessage.awsInvalidSmsRoleTrustRelationshipException,
  InvalidUserPoolConfigurationException: errorMessage.awsInvalidUserPoolConfigurationException,
  LimitExceededException: errorMessage.awsLimitExceededException,
  MFAMethodNotFoundException: errorMessage.awsMFAMethodNotFoundException,
  NotAuthorizedException: {
    default: errorMessage.awsNotAuthorizedException,
    'Password attempts exceeded': errorMessage.awsPasswordAttemptsExceededException,
    'Incorrect username or password.': errorMessage.awsInvalidPasswordException,
    'Website access not permitted': errorMessage.awsWebsiteAccessNotPermitted,
    'User password cannot be reset in the current state.': errorMessage.awsCannotResetPassContactSupport,
    'Temporary password has expired and must be reset by an administrator.': errorMessage.awsTempPasswordExpired,
  },
  PasswordResetRequiredException: errorMessage.awsPasswordResetRequiredException,
  PreconditionNotMetException: errorMessage.awsPreconditionNotMetException,
  ResourceNotFoundException: errorMessage.awsResourceNotFoundException,
  TooManyFailedAttemptsException: errorMessage.awsTooManyFailedAttemptsException,
  TooManyRequestsException: errorMessage.awsTooManyRequestsException,
  UnexpectedLambdaException: errorMessage.awsUnexpectedLambdaException,
  UnsupportedUserStateException: errorMessage.awsUnsupportedUserStateException,
  UserImportInProgressException: errorMessage.awsUserImportInProgressException,
  UserLambdaValidationException: {
    default: errorMessage.awsUserLambdaValidationException,
    'PreAuthentication failed with error Possibly non-human request.': errorMessage.recaptchaFailed,
  },
  UsernameExistsException: errorMessage.awsUsernameExistsException,
  UserNotConfirmedException: errorMessage.awsUserNotConfirmedException,
  UserNotFoundException: errorMessage.awsUserNotFoundException,
  [COMPANY_NOT_FOUND]: errorMessage.noCompanyWithSuchCodeError,
  [COMPANY_REQUEST_ALREADY_EXISTS]: errorMessage.companyRequestAlreadyExists,
  [COMPANY_REQUEST_IS_NOT_ALLOWED]: errorMessage.companyRequestIsNotAllowed,
  [COMPANY_REQUEST_LINKING_TO_ITSELF]: errorMessage.companyRequestLinkingToItself,
  [ORDER_EXISTS_FOR_PROJECT]: errorMessage.orderExistsForProject,
  [UNEXPECTED_SERVER_ERROR]: errorMessage.commonMessage,
  [BAD_REQUEST]: errorMessage.badRequest,
  [ACCESS_DENIED]: errorMessage.accessDenied,
  [DRUM_NOT_FOUND]: errorMessage.drumNotFound,
  [COMPANY_LOCATION_NOT_FOUND]: errorMessage.companyLocationNotFound,
  [COMPANY_LAST_LOCATION]: errorMessage.companyLastLocation,
  [COMPANY_CODE_EXIST]: errorMessage.companyCodeExist,
  [CANT_DELETE_DRUM]: errorMessage.drumTypeCantBeDeleted,
  [DRUM_USED_IN_ORDER]: errorMessage.drumUsedInOrder,
  [CANT_DELETE_CABLE]: errorMessage.cableTypeCantBeDeleted,
  [USER_EMAIL_ALREADY_EXISTS]: errorMessage.awsUserEmailExists,
}

let formatMessage = null

const setFormatter = (formatter) => (formatMessage = formatter)

const translateError = (error) => {
  if (!error) {
    return error
  }
  if (error.id && error.defaultMessage) {
    return formatMessage(error)
  }
  if (error?.errors) {
    error = error.errors[0]
  }
  let message = ''
  console.log(error)
  const errorCode = error?.code ? error.code.replace('.', '_') : null
  if (errorCode && errorCodeToMessage[errorCode]) {
    const errorMessage = errorCodeToMessage[errorCode]
    if (!errorMessage.id) {
      message = formatMessage(errorMessage[error.message] || errorMessage['default'])
    } else {
      message = formatMessage(errorMessage, { x: error.objectId })
    }
    console.log(message)
  } else if (Boolean(error?.message)) {
    message = error.message
  } else {
    message = formatMessage(errorMessage.commonMessage)
  }
  return message
}

export default translateError

export { translateError, setFormatter }
