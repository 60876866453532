import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { configure } from 'dashboard/services/fetch'

class FetchConfigure extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
  }

  componentDidMount() {
    const { dispatch } = this.props

    configure({
      dispatch,
    })
  }

  render() {
    return null
  }
}

export default connect()(FetchConfigure)
