import { AWS_CONNECT, MQTT_RECONNECT } from 'dashboard/constants/actions'
import { config } from 'aws-sdk/index'
import { connect } from 'dashboard/store/actions/mqtt'
import { getAwsConfig, getConfig } from 'dashboard/etc/getConfig'
import * as SigV4Utils from 'dashboard/services/awsSign'

const awsConfig = getAwsConfig()
const __config = getConfig()

const awsMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case AWS_CONNECT:
      identifyAndConnect(store.dispatch)
      break
    case MQTT_RECONNECT:
      setTimeout(() => {
        if (__config.debug) {
          console.log('MQTT: Reconnect...')
        }
        identifyAndConnect(store.dispatch)
      }, 1000)
      break
    default:
      return next(action)
  }
}

export default awsMiddleware

function getSignedUrl() {
  const { iotEndpoint, awsRegion } = awsConfig.iot
  const { accessKeyId, secretAccessKey, sessionToken } = config.credentials

  return SigV4Utils.getSignedUrl(
    'wss',
    iotEndpoint,
    '/mqtt',
    'iotdevicegateway',
    awsRegion,
    accessKeyId,
    secretAccessKey,
    sessionToken
  )
}

function identifyAndConnect(dispatch) {
  if (!config.credentials) {
    console.error('Empty credentials')
    return
  }

  config.credentials.get((err) => {
    if (err) {
      console.error('[AWS]: ', err)
      return
    }
    dispatch(connect(getSignedUrl(), 2147483647))
  })
}
