import { IS_FETCHING_NOTIFICATION_PREFERENCES, NOTIFICATION_PREFERENCES_RECEIVED } from '../constants/actions'

const defaultState = {
  isFetchingNotification: false,
  notification: {},
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case NOTIFICATION_PREFERENCES_RECEIVED:
      return {
        ...state,
        notification: action.preferences,
      }
    case IS_FETCHING_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        isFetchingNotification: action.state,
      }
    default:
      return state
  }
}
