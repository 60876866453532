import { RECEIVE_JOIN_REQUESTS } from '../constants/actions'

export default (state = [], action) => {
  switch (action.type) {
    case RECEIVE_JOIN_REQUESTS:
      return [...action.requests]
    default:
      return state
  }
}
