import {
  LAST_DAMAGE_REPORT_RECEIVED,
  DRUM_STATISTICS_RECEIVED,
  /* RENTAL_EXPIRATION_STATISTICS_RECEIVED, */
  UPCOMING_DELIVERIES_RECEIVED,
  RENTAL_EXPIRATION_STATISTICS_BY_PROJECT_RECEIVED,
} from '../constants/actions'
import { combineReducers } from 'redux'

const lastDamageReport = (state = { condition: null }, action) => {
  switch (action.type) {
    case LAST_DAMAGE_REPORT_RECEIVED:
      return { condition: action.condition }
    default:
      return state
  }
}

const rentalExpiration = (state = {}, action) => {
  switch (action.type) {
    /* case RENTAL_EXPIRATION_STATISTICS_RECEIVED:
      return action.rentalExpirationStatistics */
    default:
      return state
  }
}

const rentalExpirationByProject = (state = [], action) => {
  switch (action.type) {
    case RENTAL_EXPIRATION_STATISTICS_BY_PROJECT_RECEIVED:
      return [...action.rentalExpirationStatistics]
    default:
      return state
  }
}

const drumStatistics = (state = {}, action) => {
  switch (action.type) {
    case DRUM_STATISTICS_RECEIVED:
      return action.drumStatistics
    default:
      return state
  }
}

const upcomingDeliveries = (state = { deliveries: [] }, action) => {
  switch (action.type) {
    case UPCOMING_DELIVERIES_RECEIVED:
      return { deliveries: action.upcomingDeliveries }
    default:
      return state
  }
}

export default combineReducers({
  lastDamageReport,
  rentalExpiration,
  drumStatistics,
  upcomingDeliveries,
  rentalExpirationByProject,
})
