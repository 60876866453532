import {
  DRUM_STATE_UPDATE,
  DRUM_STATE_UPDATE_FAILURE,
  DRUM_STATE_UPDATE_SUCCESS,
  RECEIVE_DRUM_SUMMARY,
  UPDATE_DEVICE_TELEMETRY,
  UPDATE_DRUM,
  MAP_SHOW_EVENTS,
} from 'dashboard/constants/actions'

const defaultSummary = {
  showEvents: false,
}

export default (summary = defaultSummary, action) => {
  switch (action.type) {
    case RECEIVE_DRUM_SUMMARY:
      return action.drumSummary
        ? {
            ...action.drumSummary,
            showEvents: summary.showEvents,
          }
        : defaultSummary
    case UPDATE_DEVICE_TELEMETRY:
      return summary && summary.drum.drumItemId === action.drumId
        ? {
            ...summary,
            device: {
              ...summary.device,
              telemetry: action.telemetry,
            },
          }
        : summary
    case UPDATE_DRUM:
      return summary && summary.drum.drumItemId === action.drum.drum.drumItemId ? { ...action.drum } : summary
    case DRUM_STATE_UPDATE:
      return { ...summary, isStateUpdating: true }
    case DRUM_STATE_UPDATE_SUCCESS:
      return { ...summary, isStateUpdating: false, state: action.newState }
    case DRUM_STATE_UPDATE_FAILURE:
      return { ...summary, isStateUpdating: false, stateUpdateError: action.message }
    case MAP_SHOW_EVENTS:
      return { ...summary, showEvents: action.showEvents }
    default:
      return summary
  }
}
