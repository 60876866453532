export default {
  home: '/',
  notFound: '/not-found',
  drums: {
    list: '/drums',
    summary: '/drums/:id',
    edit: '/drums/:id/edit',
    events: '/drums/:id/events',
    condition: '/drums/:id/condition/:conditionId',

    devices: '/drums/management/devices',
    devicesDetails: '/drums/management/devices/:id',
    devicesEdit: '/drums/management/devices/:id/edit',
  },
  dashboard: {
    home: '/dashboard',
  },
  events: {
    list: '/events',
  },
  handscanner: {
    list: '/handscanner',
  },
  auth: {
    signIn: '/login',
    relogin: '/relogin',
    newPassword: '/new-password',
    forgotPassword: '/forgot-password',
    resetPassword: '/reset-password',
    signUp: '/register',
    confirmLogin: '/confirm-login',
    confirmRegistration: '/confirm-registration',
  },
  profile: {
    home: '/profile',
    notificationSettings: '/notification-settings',
  },
  userManagement: {
    home: '/user-management',
    users: '/user-management/users',
    requests: '/user-management/requests',
    createUser: '/user-management/users/add',
    editUser: '/user-management/users/:userId/edit',
    details: '/user-management/users/:userId/details',
    deleteUser: '/user-management/users/:username/delete',
  },
  companyManagement: {
    home: '/company-management/',
    companies: '/company-management/companies',
    requests: '/company-management/requests',
    create: '/company-management/companies/add',
    edit: '/company-management/companies/:id/edit',
  },
  companyProfile: {
    home: '/company-profile/',
    details: '/company-profile/details',
    edit: '/company-profile/edit',
    employees: '/company-profile/employees',
    locations: '/company-profile/locations',
    locationAdd: '/company-profile/locations/add',
    locationEdit: '/company-profile/locations/:id/edit',
    locationView: '/company-profile/locations/:id',
  },
  associatedCompanies: {
    home: '/linked-companies/',
    companies: '/linked-companies/',
    requests: '/linked-companies/requests',
    newRequest: '/linked-companies/new-request',
  },
  drumManagement: {
    home: '/drum-management',
    drums: '/drum-management/drums',
    drumsCreate: '/drum-management/drums/add',
    drumsEdit: '/drum-management/drums/edit/:id',
  },
  cableProduction: {
    home: '/cable-production',
    production: '/cable-production/production',
    ready: '/cable-production/ready',
    edit: '/cable-production/production/edit/:id',
  },
  drumTypes: {
    list: '/drums/management/drum-types',
    details: '/drums/management/drum-types/:id/details',
    edit: '/drums/management/drum-types/:id/edit',
  },
  cableTypes: {
    list: '/drums/management/cable-types',
    details: '/drums/management/cable-types/:id/details',
    edit: '/drums/management/cable-types/:id/edit',
  },
  devices: {
    list: '/drums/management/devices',
    details: '/drums/management/devices/:id/details',
    edit: '/drums/management/devices/:id/edit',
  },
  guest: {
    home: '/guest',
    createCompany: '/guest/create-company',
    joinCompany: '/guest/join-company',
    request: '/guest/request',
  },
  projects: {
    home: '/projects',
    list: '/projects',
    create: '/projects/create',
    edit: '/projects/:id/edit',
    details: '/projects/:id/details',
    consumption: '/projects/:id/consumption',
    drums: '/projects/:id/drums',
    orders: '/projects/:id/orders',
    deliveries: '/projects/:id/deliveries',
  },
  orders: {
    home: '/orders',
    out: '/orders/out',
    in: '/orders/in',
    edit: '/orders/:id/edit',
    details: '/orders/:id/details',
    drums: '/orders/:id/drums',
    deliveries: '/orders/:id/deliveries',
  },
  deliveries: {
    home: '/deliveries',
    edit: '/deliveries/:id',
    details: '/deliveries/:id/details',
    drums: '/deliveries/:id/drums',
  },
  deliveries2: {
    home: '/deliveries2',
    edit: '/deliveries2/:id/edit',
    details: '/deliveries2/:id/details',
    drums: '/deliveries2/:id/drums',
  },
  notifications: {
    list: '/notifications',
    details: '/notifications/:id',
  },
  privacyPolicy: '/privacy-policy',
  terms: '/terms-and-conditions',
  claimManagement: {
    list: '/claim-reports',
    details: '/claim-reports/:id/details',
    drumDetails: '/claim-reports/:id/drum-details',
    orderDetails: '/claim-reports/:id/order-details',
    deliveryDetails: '/claim-reports/:id/delivery-details',
    drumHistory: '/claim-reports/:id/drum-history',
  },
}
