import { getConfig } from 'dashboard/etc/getConfig'
import firebase from 'firebase/app'
import { toastr } from 'react-redux-toastr'
import React from 'react'

import { registerToken } from '../modules/Notification/store/api'
import { SET_USER_ROLES_TO_SW } from '../modules/Notification/constants/actions'
import NotificationText from '../modules/Notification/components/NotificationText'

const config = getConfig()

const sendTokenToServer = (token) => {
  if (!isTokenSentToServer()) {
    registerToken(token)
      .then(() => setTokenSentToServer(true))
      .catch((e) => {
        console.log(e)
      })
  }
}

export const deleteFCMToken = () => {
  try {
    const _messaging = firebase.messaging()

    try {
      _messaging.usePublicVapidKey(config.firebase.vapidKey)
    } catch (e) {
      // console.log(e);
    }

    return _messaging
      .getToken()
      .then((token) => _messaging.deleteToken(token))
      .then(() => setTokenSentToServer(false))
      .catch((e) => console.log(e))
  } catch (e) {
    return Promise.resolve(null)
  }
}

const isTokenSentToServer = () => {
  return window.localStorage.getItem('sentToServer') === '1'
}

const setTokenSentToServer = (sent) => {
  window.localStorage.setItem('sentToServer', sent ? '1' : '0')
}

export const swPostMessage = (message) =>
  navigator.serviceWorker
    .getRegistrations()
    .then((serviceWorkerRegistrations) => {
      return serviceWorkerRegistrations[0]
    })
    .then((serviceWorkerRegistration) => {
      serviceWorkerRegistration.active.postMessage(JSON.stringify(message))
    })

const initPush = (userRoles) => {
  try {
    const _messaging = firebase.messaging()

    try {
      _messaging.usePublicVapidKey(config.firebase.vapidKey)
    } catch (e) {
      // console.log(e);
    }

    _messaging
      .requestPermission()
      .then(() => _messaging.getToken())
      .then((token) => {
        setTokenSentToServer(false)
        return sendTokenToServer(token)
      })
      .then(() => {
        swPostMessage({ type: SET_USER_ROLES_TO_SW, roles: userRoles || [] })
      })
      .catch((error) => {
        if (error.code === 'messaging/permission-blocked') {
          console.log('Please Unblock Notification Request Manually')
        } else {
          console.log('Error Occurred', error)
        }
      })

    _messaging.onMessage((payload) => {
      const message = JSON.parse(payload.data.INBOX)
      toastr.info('', { component: <NotificationText message={message} /> })
    })

    _messaging.onTokenRefresh(() => {
      _messaging
        .getToken()
        .then((refreshedToken) => {
          setTokenSentToServer(false)
          sendTokenToServer(refreshedToken)
        })
        .catch((err) => {
          console.log('Unable to retrieve refreshed token ', err)
        })
    })
  } catch (e) {
    console.log(e)
  }
}

export default initPush
