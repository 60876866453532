import { defineMessages } from 'react-intl';

const messages = defineMessages({
  offer: {
    id: 'offer-mobile.offer',
    defaultMessage: 'To see drum details please install the TrackMyDrum application',
  },
  or: {
    id: 'offer-mobile.or',
    defaultMessage: 'OR',
  },
  continue: {
    id: 'offer-mobile.continue',
    defaultMessage: 'Continue to the site',
  },
});

export default messages;
