import { defineMessages } from 'react-intl';

const messages = defineMessages({
  new: {
    id: 'drum-states.new',
    defaultMessage: 'New',
  },
  production: {
    id: 'drum-states.production',
    defaultMessage: 'Drum production',
  },
  empty: {
    id: 'drum-states.empty',
    defaultMessage: 'Empty',
  },
  withCable: {
    id: 'drum-states.with-cable',
    defaultMessage: 'Drum with cable',
  },
  readyForPickUp: {
    id: 'drum-states.ready-for-pick-up',
    defaultMessage: 'Ready for delivery',
  },
  incomingDrums: {
    id: 'drum-states.incoming-drums',
    defaultMessage: 'Incoming drums',
  },
  outgoingDrums: {
    id: 'drum-states.outgoing-drums',
    defaultMessage: 'Outgoing drums',
  },
  inTransit: {
    id: 'drum-states.in-transit',
    defaultMessage: 'In transit',
  },
  inTransitToCustomer: {
    id: 'drum-states.in-transit-to-customer',
    defaultMessage: 'To customer',
  },
  inTransitToVendor: {
    id: 'drum-states.in-transit-to-vendor',
    defaultMessage: 'To vendor',
  },
  delivered: {
    id: 'drum-states.delivered',
    defaultMessage: 'Delivered',
  },
  accepted: {
    id: 'drum-states.accepted',
    defaultMessage: 'Accepted',
  },
  readyForCollection: {
    id: 'drum-states.ready-for-collection',
    defaultMessage: 'Ready for return',
  },
  rejected: {
    id: 'drum-states.rejected',
    defaultMessage: 'Rejected',
  },
  readyForReturn: {
    id: 'drum-states.ready-for-return',
    defaultMessage: 'Ready for return',
  },
});

export default messages;
