import endpoints from 'dashboard/etc/endpoints'
import fetch from 'dashboard/services/fetch'

const fetchProfile = () => fetch(endpoints.user.profile)

const fetchCompany = () => fetch(endpoints.user.company)

const linkPartner = (partnerKey) =>
  fetch.post(endpoints.companyManagement.linkPartner, {
    body: JSON.stringify(partnerKey),
  })

export { fetchProfile, fetchCompany, linkPartner }
