import { RECEIVE_APPEND_DRUMS, RECEIVE_DRUMS, UPDATE_DEVICE_TELEMETRY, UPDATE_DRUM } from 'dashboard/constants/actions'
import { drumSummaryToShort } from 'dashboard/services/dataConverter'

export default (items = [], action) => {
  switch (action.type) {
    case RECEIVE_DRUMS:
      return [...action.drums]
    case RECEIVE_APPEND_DRUMS:
      return [...items, ...action.drums]
    case UPDATE_DEVICE_TELEMETRY:
      return items.map((item) => {
        if (item.id === action.drumId) {
          return {
            ...item,
            device: {
              ...item.device,
              telemetry: Object.assign({}, item.device.telemetry, action.telemetry),
            },
          }
        } else {
          return item
        }
      })
    case UPDATE_DRUM:
      return items.map((item) => {
        if (item.id === action.drum.drum.drumItemId) {
          return Object.assign({}, item, drumSummaryToShort(action.drum))
        } else {
          return item
        }
      })
    default:
      return items
  }
}
