import React, { ReactNode, useState } from 'react'

interface PublisherState {
  [key: string]: (args?: any) => void
}
interface PublisherInstanceCount {
  [key: string]: number
}
interface Publisher {
  publisherState: PublisherState
  setPublisherState: React.Dispatch<React.SetStateAction<PublisherState>>
  instances: PublisherInstanceCount
  setInstances: React.Dispatch<React.SetStateAction<PublisherInstanceCount>>
}

const initialState: Publisher = {
  publisherState: {},
  setPublisherState: () => {},
  instances: {},
  setInstances: () => {},
}

const PublisherContext = React.createContext<Publisher>(initialState)

export const PublisherProvider = ({ children }): ReactNode => {
  const [publisherState, setPublisherState] = useState<PublisherState>({})
  const [instances, setInstances] = useState<PublisherInstanceCount>({})

  return (
    <PublisherContext.Provider value={{ publisherState, setPublisherState, instances, setInstances }}>
      {children}
    </PublisherContext.Provider>
  )
}

export default PublisherContext
