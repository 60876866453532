import { defineMessages } from 'react-intl';

const messages = defineMessages({
  DRUM_DAMAGED: {
    id: 'inbox-notifications-vendor.DRUM_DAMAGED',
    defaultMessage: 'Drum {drum_name} has been reported as damaged',
  },
  DRUM_READY_TO_RETURN: {
    id: 'inbox-notifications-vendor.DRUM_READY_TO_RETURN',
    defaultMessage: 'Drum {drum_name} is ready to be picked up',
  },
  DRUM_REJECTED: {
    id: 'inbox-notifications-vendor.DRUM_REJECTED',
    defaultMessage: 'Drum {drum_name} has been rejected upon delivery by company {customer_name}',
  },
  DRUM_EXPIRED: {
    id: 'inbox-notifications-vendor.DRUM_EXPIRED',
    defaultMessage: 'Drum {drum_name} has expired',
  },
  DRUM_CABLE_CONSUMED: {
    id: 'inbox-notifications-vendor.DRUM_CABLE_CONSUMED',
    defaultMessage: 'Cable of the drum {drum_name} has been used',
  },
  DRUM_CABLE_LOW: {
    id: 'inbox-notifications-vendor.DRUM_CABLE_LOW',
    defaultMessage: 'Cable of the drum {drum_name} is running low',
  },
  DRUM_MOVED_OUT: {
    id: 'inbox-notifications-vendor.DRUM_MOVED_OUT',
    defaultMessage: 'Drum {drum_name} from the project {project_name} is moved from the current project location {x} meter(s) away',
  },
  ORDER_CREATED: {
    id: 'inbox-notifications-vendor.ORDER_CREATED',
    defaultMessage: 'Order {order_name} has been created for customer {customer_name}',
  },
  ORDER_DISPATCHED: {
    id: 'inbox-notifications-vendor.ORDER_DISPATCHED',
    defaultMessage: 'Order {order_name} has been dispatched for customer {customer_name}',
  },
  ORDER_DELIVERED: {
    id: 'inbox-notifications-vendor.ORDER_DELIVERED',
    defaultMessage: 'Order {order_name} has been delivered to customer {customer_name}',
  },
  ORDER_EXPIRES_IN_X_DAYS: {
    id: 'inbox-notifications-vendor.ORDER_EXPIRES_IN_X_DAYS',
    defaultMessage: 'Reminder! The order {order_name} from customer {customer_name} is expiring in {x} days',
  },
  ORDER_EXPIRES_TODAY: {
    id: 'inbox-notifications-vendor.ORDER_EXPIRES_TODAY',
    defaultMessage: 'Attention! The following order {order_name} from customer {customer_name} is expiring today',
  },
  ORDER_EXPIRED: {
    id: 'inbox-notifications-vendor.ORDER_EXPIRED',
    defaultMessage: 'Attention! The following order {order_name} from customer {customer_name} has been expired for {x} days',
  },
  DELIVERY_CREATED_TO_CUSTOMER: {
    id: 'inbox-notifications-vendor.DELIVERY_CREATED_TO_CUSTOMER',
    defaultMessage: 'A new delivery of order {order_name} has been created for customer {customer_name}',
  },
  DELIVERY_CREATED_TO_VENDOR: {
    id: 'inbox-notifications-vendor.DELIVERY_CREATED_TO_VENDOR',
    defaultMessage: 'A new delivery of order {order_name} has been created from customer {customer_name}',
  },
  DELIVERY_ACCEPTED: {
    id: 'inbox-notifications-vendor.DELIVERY_ACCEPTED',
    defaultMessage: 'Delivery of {order_name} for {customer_name} was partially/fully accepted',
  },
  DELIVERY_ARRIVE_IN_X_DAYS: {
    id: 'inbox-notifications-vendor.DELIVERY_ARRIVE_IN_X_DAYS',
    defaultMessage: 'Delivery of the order {order_name} is arriving in {x} days',
  },
  DELIVERY_ARRIVE_IN_X_HOURS: {
    id: 'inbox-notifications-vendor.DELIVERY_ARRIVE_IN_X_HOURS',
    defaultMessage: 'Delivery of the order {order_name} is arriving in {x} hours',
  },
});

export default messages;
