import mapValues from 'lodash/mapValues'
import xorBy from 'lodash/xorBy'
import isObject from 'lodash/isObject'
import unionBy from 'lodash/unionBy'
import {
  DRUM_FILTERS_CHANGE_FILTER_VALUE,
  DRUM_FILTERS_CLEAR_FILTER,
  DRUM_FILTERS_RESET_ALL,
  DRUM_FILTERS_SELECT_ALL_FILTER,
  DRUM_FILTERS_GET_VALUES_FOR_FILTER,
  DRUM_FILTERS_CLEAR_VALUES_FOR_FILTER,
  DRUM_FILTERS_SET_IS_LOADING,
} from 'dashboard/constants/actions'

const defaultFilters = {
  drumStates: {
    selected: [],
    values: [],
    total: 0,
  },
  drumTypes: {
    selected: [],
    values: [],
    isLoading: false,
    total: 0,
  },
  drumMaterials: {
    selected: [],
    values: [],
    total: 0,
  },
  cableTypes: {
    selected: [],
    values: [],
    isLoading: false,
    total: 0,
  },
  vendors: {
    selected: [],
    values: [],
    isLoading: false,
    total: 0,
  },
  logistics: {
    selected: [],
    values: [],
    isLoading: false,
    total: 0,
  },
  customers: {
    selected: [],
    values: [],
    isLoading: false,
    total: 0,
  },
}

export default (filters = defaultFilters, action) => {
  switch (action.type) {
    case DRUM_FILTERS_CHANGE_FILTER_VALUE: {
      const { filter, value: newValue } = action.data
      const currentFilter = filters[filter]
      const newFilterValue = isObject(currentFilter)
        ? {
            ...currentFilter,
            selected: xorBy(currentFilter.selected, [newValue], 'value'),
          }
        : newValue

      return {
        ...filters,
        [filter]: newFilterValue,
      }
    }

    case DRUM_FILTERS_CLEAR_FILTER: {
      const { filter } = action.data
      const newFilterValue = isObject(filters[filter])
        ? {
            ...filters[filter],
            selected: [],
          }
        : ''

      return {
        ...filters,
        [filter]: newFilterValue,
      }
    }

    case DRUM_FILTERS_RESET_ALL:
      return mapValues(filters, (filterDesc) => {
        return isObject(filterDesc)
          ? {
              ...filterDesc,
              selected: [],
            }
          : ''
      })

    case DRUM_FILTERS_GET_VALUES_FOR_FILTER:
      const { filter: f, values, total } = action.data

      return {
        ...filters,
        [f]: {
          ...filters[f],
          values,
          total,
        },
      }

    case DRUM_FILTERS_SELECT_ALL_FILTER:
      const { filter: filterForSelectAll } = action.data
      const currentFilterForSelectAll = filters[filterForSelectAll]

      return {
        ...filters,
        [filterForSelectAll]: {
          ...currentFilterForSelectAll,
          selected: unionBy(currentFilterForSelectAll.selected, currentFilterForSelectAll.values, 'value'),
        },
      }

    case DRUM_FILTERS_CLEAR_VALUES_FOR_FILTER:
      const { filter: filterForClearValues } = action.data
      const currentFilterForClearValues = filters[filterForClearValues]

      return {
        ...filters,
        [filterForClearValues]: {
          ...currentFilterForClearValues,
          values: [],
        },
      }

    case DRUM_FILTERS_SET_IS_LOADING:
      const { filter: filterForSetIsLoading, value: valueForSetIsLoading } = action.data
      const currentFilterForSetIsLoading = filters[filterForSetIsLoading]

      return {
        ...filters,
        [filterForSetIsLoading]: {
          ...currentFilterForSetIsLoading,
          isLoading: valueForSetIsLoading,
        },
      }

    default:
      return filters
  }
}
