import get from 'lodash/get'
import routes from 'dashboard/etc/routes'
import { toastr } from 'react-redux-toastr'
import {
  SET_COMPANY,
  SET_LOCATIONS,
  UPDATE_LOCATION,
  SET_PAGING,
  SET_QUERY_FILTER,
  RECEIVE_COMPANY_USERS,
  SET_EMPLOYEES_PAGING,
  SET_EMPLOYEES_QUERY_FILTER,
} from '../constants/actions'
import {
  createLocation as apiCreateLocation,
  deleteLocation as apiDeleteLocation,
  getCompany,
  getLocation,
  getLocations,
  updateLocation as apiUpdateLocation,
  deleteCompany as apiDeleteCompany,
} from './api'
import defaultPagingValues, { defaultPagingLimit } from 'dashboard/constants/paging'
import { getGuestUsersFromCompany } from '../../UserManagement/store/api'

export const setCompany = (company) => ({
  type: SET_COMPANY,
  company,
})

const setLocations = (locations) => ({
  type: SET_LOCATIONS,
  locations,
})

const updateLocationInList = (location) => ({
  type: UPDATE_LOCATION,
  location,
})

export const setQueryFilter = (query) => ({
  type: SET_QUERY_FILTER,
  query,
})

const receivePagingInfo = (paging) => ({
  type: SET_PAGING,
  paging,
})

export const fetchCompany = (companyId) => (dispatch) => {
  getCompany(companyId)
    .then((company) => dispatch(setCompany(company)))
    .catch((error) => toastr.error("Can't get company details"))
}

export const fetchLocations = ({ q = '', page = 0, limit = defaultPagingLimit, order }) => (dispatch) => {
  const params = {
    paging: {
      offset: page * limit,
      limit,
    },
    filter: {
      fullText: q,
      showEmpty: true,
    },
    order: order ?? {
      by: 'title',
      direction: 'desc',
    },
  }

  return getLocations(params)
    .then((json) => {
      dispatch(setLocations(json.results))
      dispatch(receivePagingInfo(get(json, 'paging', defaultPagingValues)))
    })
    .catch((error) => {
      dispatch(setLocations([]))
      dispatch(receivePagingInfo(defaultPagingValues))
      throw new Error(error.message)
    })
}

export const fetchLocation = (companyId, locationId) => (dispatch) =>
  getLocation(companyId, locationId)
    .then((location) => dispatch(updateLocationInList(location)))
    .catch((error) => toastr.error("Can't get company locations"))

export const deleteLocation = (companyId, locationId) => (dispatch) => apiDeleteLocation(companyId, locationId)

export const createLocation = (params) => (dispatch) => apiCreateLocation(params)

export const updateLocation = (companyId, locationId, params) => (dispatch) =>
  apiUpdateLocation(companyId, locationId, params)

export const deleteCompany = (companyId) => (dispatch) =>
  apiDeleteCompany(companyId).then(() => {
    window.location = routes.guest.home
  })

export const fetchCompanyUsers = ({ q = '', page = 0, limit = defaultPagingLimit }) => (dispatch) => {
  const params = {
    offset: page * limit,
    limit,
    q,
  }

  return getGuestUsersFromCompany(params)
    .then((json) => {
      dispatch(receivedCompanyUsers(json.results))
      dispatch(receiveEmployeesPagingInfo(get(json, 'paging', defaultPagingValues)))
    })
    .catch((error) => {
      dispatch(receivedCompanyUsers([]))
      throw new Error(error.message)
    })
}

export const setUsersQueryFilter = (query) => ({
  type: SET_EMPLOYEES_QUERY_FILTER,
  query,
})

const receivedCompanyUsers = (users) => ({
  type: RECEIVE_COMPANY_USERS,
  users,
})

const receiveEmployeesPagingInfo = (paging) => ({
  type: SET_EMPLOYEES_PAGING,
  paging,
})
