// sorted by workflow
export const EMPTY = 'EMPTY'
export const WITH_CABLE = 'WITH_CABLE'
export const READY_FOR_DELIVERY = 'READY_FOR_DELIVERY'
export const IN_TRANSIT = 'IN_TRANSIT'
export const ACCEPTED = 'ACCEPTED'
export const REJECTED = 'REJECTED'
export const READY_FOR_RETURN = 'READY_FOR_RETURN'

export const DAMAGE = 'DAMAGE'
export const TO_OWNER = 'TO_OWNER'
export const TO_RECIPIENT = 'TO_RECIPIENT'
