import { push } from 'connected-react-router'
import { SET_USER_ROLES, SET_USER_PERMISSIONS } from 'dashboard/modules/Auth/constants/actions'
import get from 'lodash/get'
import routes from 'dashboard/etc/routes'

import { AUTHENTICATED, NEW_PASSWORD_REQUIRED, RESET_PASSWORD_REQUIRED } from '../constants/awsCognito'

import {
  authenticate as cognitoAuthenticate,
  confirmRegistration as cognitoConfirmRegistration,
  forgotPassword as cognitoForgotPassword,
  newPasswordChallengeComplete as cognitoNewPasswordChallengeComplete,
  registerUser as cognitoRegisterUser,
  resendConfirmationCode as cognitoResendConfirmCode,
  resetPassword as cognitoResetPassword,
  resendTempPassword as cognitoResendTempPassword,
  signIn as cognitoSignIn,
  signOut as cognitoSignOut,
} from '../awsCognito'
import {
  CLEAN_STATE,
  SET_USER_ATTRIBUTES,
  SET_USER_COMPANY_ID,
  SET_USER_GROUPS,
  SET_USER_STATE,
  PARTNER_KEY,
  COMPANY_CODE,
} from '../constants/actions'
import * as userState from '../constants/userStates'
import { fetchProfile, linkPartner } from './api'
import { setCompany } from 'dashboard/modules/CompanyProfile/store/actions'
import { changeLocale } from 'dashboard/store/actions/locales'
import { configure as configureFetch } from 'dashboard/services/fetch'
import { USER_NOT_FOUND, AWS_USER_NOT_CONFIRMED } from 'dashboard/constants/errors'
import { deleteFCMToken } from '../../../services/firebase'
import initPush from '../../../services/firebase'
import { toastr } from 'react-redux-toastr'
import { resendVerificationEmail, verifyLogin } from '../../UserManagement/store/api'
import { GUEST } from '../../../constants/roles'
import { AWS_USER_NOT_AUTH } from '../../../constants/errors'
import errorMessage from '../../../translations/error'

const setPartnerKey = (state, context = null) => ({
  type: PARTNER_KEY,
  state,
  context,
})

const setCompanyCode = (state, context = null) => ({
  type: COMPANY_CODE,
  state,
  context,
})

const setUserState = (state, context = null) => ({
  type: SET_USER_STATE,
  state,
  context,
})

const cleanState = () => ({
  type: CLEAN_STATE,
})

const setNotAuthenticated = () => setUserState(userState.NOT_AUTHENTICATED)

const setAuthenticated = () => setUserState(userState.AUTHENTICATED)

const setNewPasswordRequired = (cognitoUser, attributes) =>
  setUserState(userState.NEW_PASSWORD_REQUIRED, {
    cognitoUser: cognitoUser,
    attributes: attributes,
  })

const setResetPasswordRequired = (username) =>
  setUserState(userState.RESET_PASSWORD_REQUIRED, {
    username: username,
  })

const setConfirmRegistrationRequired = (username, password) =>
  setUserState(userState.IS_NOT_CONFIRMED, {
    username: username,
    password: password,
  })

const setConfirmLoginWithEmailRequired = (username) =>
  setUserState(userState.EMAIL_VERIFICATION_REQUIRED, {
    username,
  })

const setUserAttributes = (attributes) => ({
  type: SET_USER_ATTRIBUTES,
  attributes,
})

const setUserGroups = (groups) => ({
  type: SET_USER_GROUPS,
  groups,
})

const setUserCompanyId = (companyId) => ({
  type: SET_USER_COMPANY_ID,
  companyId,
})

const setUserRoles = (roles) => ({
  type: SET_USER_ROLES,
  roles,
})

const setUserPermissions = (permissions) => ({
  type: SET_USER_PERMISSIONS,
  permissions,
})

const setUserInfo = (attributes) => (dispatch) => {
  return dispatch(setUserAttributes(attributes))
}

const authenticate = (partnerKey) => (dispatch) => {
  return cognitoAuthenticate()
    .then(() => dispatch(authenticateUser(partnerKey)))
    .catch(() => {
      dispatch(clearUserData())
    })
}

const signIn = (username, password, partnerKey, validationData) => (dispatch) => {
  return cognitoSignIn(username, password, validationData)
    .then(async ({ state, cognitoUser, attributes }) => {
      if (state === AUTHENTICATED) {
        await dispatch(authenticateUser(partnerKey)).catch((error) => {
          throw error
        })
      } else if (state === NEW_PASSWORD_REQUIRED) {
        dispatch(setNewPasswordRequired(cognitoUser, attributes))
      }
      return Promise.resolve(state)
    })
    .catch((error) => {
      if (error.code === AWS_USER_NOT_CONFIRMED) {
        dispatch(setConfirmRegistrationRequired(username, password))
      }
      throw error
    })
}

const authenticateUser = (partnerKey) => (dispatch) => {
  return dispatch(fetchUserData())
    .then((data) => {
      if (data.emailVerificationEnabled && data.verifyFromWeb && data.loginAttemptsLeft >= 0) {
        dispatch(setConfirmLoginWithEmailRequired(data.email))
        dispatch(push(routes.auth.confirmLogin))
      } else {
        dispatch(setAuthenticated())
        if (partnerKey) {
          dispatch(linkCompanyPartner(partnerKey))
        }
      }
    })
    .catch((error) => {
      dispatch(clearUserData())
      throw error
    })
}

const linkCompanyPartner = (partnerKey) => (dispatch) => {
  return linkPartner({
    partnerKey,
  })
    .then(() => {})
    .catch((error) => {
      throw error
    })
}

const fetchUserData = () => (dispatch) => {
  return fetchProfile()
    .catch((error) => {
      if (error.code === USER_NOT_FOUND) {
        toastr.error(error.message)
      }
      throw error
    })
    .then((data) => {
      if (data.role && data.role.name === GUEST) {
        const error = {
          id: AWS_USER_NOT_AUTH,
          code: AWS_USER_NOT_AUTH,
          message: errorMessage.awsWebsiteAccessNotPermitted,
        }
        throw error
      }

      const attributes = {
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        email: data.email,
        emailVerificationEnabled: data.emailVerificationEnabled,
        loginAttemptsLeft: data.loginAttemptsLeft,
        verifyFromWeb: data.verifyFromWeb,
        apiKey: data.apiKey,
      }
      const company = get(data, 'company', null)
      const lang = data.lang ? data.lang.toLowerCase().split(/[_-]+/)[0] : 'en'
      const roles = data.role ? [data.role.name] : null
      const permissions = data.role?.permissions ?? null

      dispatch(setUserAttributes(attributes))
      dispatch(setUserRoles(roles || []))
      dispatch(setUserPermissions(permissions || []))
      dispatch(setUserCompanyAndRoles(company))
      dispatch(changeLocaleIfNeeded(lang))
      initPush(roles)
      return attributes
    })
}

const setUserCompanyAndRoles = (company) => (dispatch) => {
  const groups = []
  if (company && company.type) {
    groups.push(company.type)
    configureFetch({
      companyId: company && company.companyId,
    })
  }

  dispatch(setCompany(company))
  dispatch(setUserGroups(groups))
}

const changeLocaleIfNeeded = (lang) => (dispatch) => {
  if (localStorage.getItem('selectedLocale') !== lang) {
    dispatch(changeLocale(lang))
  }
}

const unauthenticateUser = () => (dispatch) =>
  new Promise((resolve) => {
    dispatch(setUserAttributes(undefined))
    dispatch(setUserGroups(undefined))
    dispatch(setUserCompanyId(undefined))
    resolve()
  })

const clearUserData = () => (dispatch) => {
  Promise.resolve(dispatch(setNotAuthenticated()))
    .then(() => dispatch(unauthenticateUser()))
    .then(() => cognitoSignOut())
    .then(() => deleteFCMToken())
}

const signOut = () => (dispatch) => {
  Promise.resolve(dispatch(clearUserData())).finally(() => window.location.reload())
}

const newPasswordChallengeComplete = (cognitoUser, newPassword, attributes) => (dispatch) => {
  return cognitoNewPasswordChallengeComplete(cognitoUser, newPassword, attributes).then(() =>
    dispatch(authenticateUser())
  )
}

const forgotPassword = (username) => (dispatch) => {
  return cognitoForgotPassword(username).then((data) => {
    if (data.state === RESET_PASSWORD_REQUIRED) {
      dispatch(setResetPasswordRequired(username))
    }
  })
}

const resetPassword = (username, verificationCode, newPassword) => (dispatch) => {
  return cognitoResetPassword(username, verificationCode, newPassword).then(() =>
    dispatch(signIn(username, newPassword))
  )
}

const resendTempPassword = (username) => {
  return Promise.resolve(cognitoResendTempPassword(username))
}

const registerUser = (payload) => (dispatch) => {
  return cognitoRegisterUser(payload).then(() =>
    dispatch(setConfirmRegistrationRequired(payload.email, payload.password))
  )
}

const confirmRegistration = (username, confirmCode) => (dispatch) => {
  return cognitoConfirmRegistration(username, confirmCode)
}

export const confirmLogin = (code, recaptchaToken) => (dispatch) => {
  return verifyLogin(code, recaptchaToken).then(() => {
    dispatch(setAuthenticated())
  })
}

export const resendLoginConfirmEmail = () => (dispatch) => {
  return resendVerificationEmail()
}

const resendConfirmationCode = (username) => (dispatch) => {
  return cognitoResendConfirmCode(username)
}

export {
  authenticate,
  authenticateUser,
  signIn,
  signOut,
  cleanState,
  newPasswordChallengeComplete,
  forgotPassword,
  resetPassword,
  resendTempPassword,
  registerUser,
  confirmRegistration,
  resendConfirmationCode,
  setUserInfo,
  fetchUserData,
  setPartnerKey,
  setCompanyCode,
}
