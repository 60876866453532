import { Button } from 'reactstrap'
import { getConfig } from 'dashboard/etc/getConfig'
import { injectIntl } from 'react-intl'
import MobileDetect from 'mobile-detect'
import offerMessages from 'dashboard/translations/offerMobile'
import PropTypes from 'prop-types'
import React from 'react'

import '../style/style.scss'
import { ReactComponent as AppStore } from '../images/appStore.svg'
import { ReactComponent as GooglePlay } from '../images/googlePlay.svg'
import { ReactComponent as Logo } from '../images/logo.svg'

const config = getConfig()

const isAndroid = () => {
  const detect = new MobileDetect(window.navigator.userAgent)
  return Boolean(detect.mobile()) && detect.os() === 'AndroidOS'
}

const isIOS = () => {
  const detect = new MobileDetect(window.navigator.userAgent)
  return Boolean(detect.mobile()) && detect.os() === 'iOS'
}

const goToAppStore = () => window.location.assign(config.appStore)
const goToGooglePlay = () => window.location.assign(config.googlePlay)

const OfferMobileApp = ({ onClose, intl: { formatMessage } }) => {
  return (
    <div className="app-offer">
      <div>
        <Logo className="app-offer__logo" />
      </div>
      <div>{formatMessage(offerMessages.offer)}</div>
      <div>
        {isAndroid() && <GooglePlay onClick={() => goToGooglePlay()} className="app-offer__google" />}
        {isIOS() && <AppStore onClick={() => goToAppStore()} className="app-offer__apple" />}
      </div>
      <div>
        {formatMessage(offerMessages.or)}
        <br />
        <Button color="link" onClick={onClose}>
          {formatMessage(offerMessages.continue)}
        </Button>
      </div>
    </div>
  )
}

OfferMobileApp.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default injectIntl(OfferMobileApp)
