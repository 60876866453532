import { DRUM_PAGING_IS_FETCHING, DRUM_PAGING_SET_OFFSET, RECEIVE_DRUMS_TOTAL_COUNT } from 'dashboard/constants/actions'

export default (paging = { offset: 0, limit: 20, totalDrumsCount: 0, isFetching: false }, action) => {
  switch (action.type) {
    case DRUM_PAGING_SET_OFFSET:
      return {
        ...paging,
        offset: action.offset,
      }
    case DRUM_PAGING_IS_FETCHING:
      return {
        ...paging,
        isFetching: action.isFetching,
      }
    case RECEIVE_DRUMS_TOTAL_COUNT:
      return {
        ...paging,
        totalDrumsCount: action.totalDrumsCount,
      }
    default:
      return paging
  }
}
