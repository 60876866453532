import {
  RECEIVE_DRUMS,
  RECEIVE_CURRENT_DRUM,
  DELETE_DRUM,
  SET_PAGING,
  SET_UPLOADING_IN_PROGRESS,
} from '../constants/actions'
import { combineReducers } from 'redux'
import { SET_QUERY_FILTER } from 'dashboard/modules/DrumManagement/constants/actions'
import defaultPagingValues from 'dashboard/constants/paging'

const listReducer = (state = [], action) => {
  switch (action.type) {
    case RECEIVE_DRUMS:
      return [...action.drums]
    case DELETE_DRUM:
      return state.filter((item) => item.id !== action.id)
    default:
      return state
  }
}

const currentReducer = (state = {}, action) => {
  switch (action.type) {
    case RECEIVE_CURRENT_DRUM:
      return { ...action.drum }
    default:
      return state
  }
}

const queryFilterReducer = (query = '', action) => {
  switch (action.type) {
    case SET_QUERY_FILTER:
      return action.query
    default:
      return query
  }
}

const pagingReducer = (state = defaultPagingValues, action) => {
  switch (action.type) {
    case SET_PAGING:
      return {
        ...state,
        ...action.paging,
      }
    default:
      return state
  }
}

const uploadingReducer = (state = { uploadingInProgress: false }, action) => {
  switch (action.type) {
    case SET_UPLOADING_IN_PROGRESS:
      return {
        ...state,
        uploadingInProgress: action.state,
      }
    default:
      return state
  }
}

export default combineReducers({
  list: listReducer,
  current: currentReducer,
  queryFilter: queryFilterReducer,
  paging: pagingReducer,
  fileUploading: uploadingReducer,
})
