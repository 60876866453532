export const IS_FETCHING_CLAIMS = 'dashboard/ClaimManagement/IS_FETCHING_CLAIMS'
export const IS_FETCHING_CLAIM = 'dashboard/ClaimManagement/IS_FETCHING_CLAIM'

export const RECEIVE_CLAIMS = 'dashboard/ClaimManagement/RECEIVE_CLAIMS'
export const RECEIVE_CLAIM = 'dashboard/ClaimManagement/RECEIVE_CLAIM'
export const RECEIVE_CLAIM_DRUM = 'dashboard/ClaimManagement/RECEIVE_CLAIM_DRUM'
export const RECEIVE_CLAIM_DELIVERY = 'dashboard/ClaimManagement/RECEIVE_CLAIM_DELIVERY'
export const IS_FETCHING_CLAIM_DRUM = 'dashboard/ClaimManagement/IS_FETCHING_CLAIM_DRUM'
export const IS_FETCHING_CLAIM_DELIVERY = 'dashboard/ClaimManagement/IS_FETCHING_CLAIM_DELIVERY'
export const RECEIVE_CLAIM_ORDER = 'dashboard/ClaimManagement/RECEIVE_CLAIM_ORDER'
export const IS_FETCHING_CLAIM_ORDER = 'dashboard/ClaimManagement/IS_FETCHING_CLAIM_ORDER'
export const SET_PAGING = 'dashboard/ClaimManagement/SET_PAGING'
export const SET_QUERY_FILTER = 'dashboard/ClaimManagement/SET_QUERY_FILTER'

/*
export const IS_FETCHING_DELIVERIES = "dashboard/ClaimManagement/IS_FETCHING_DELIVERIES";
export const RECEIVE_ORDERS = "dashboard/ClaimManagement/RECEIVE_ORDERS";
export const RECEIVE_ORDER = "dashboard/ClaimManagement/RECEIVE_ORDER";
export const RECEIVE_PROJECTS = "dashboard/ClaimManagement/RECEIVE_PROJECTS";
export const RECEIVE_DELIVERIES = "dashboard/ClaimManagement/RECEIVE_DELIVERIES";
export const RECEIVE_VENDOR_LOCATIONS = "dashboard/ClaimManagement/RECEIVE_VENDOR_LOCATIONS";
export const RECEIVE_CUSTOMER_INFO = "dashboard/ClaimManagement/RECEIVE_CUSTOMER_INFO";
export const RECEIVE_COMPANY_USERS = "dashboard/ClaimManagement/RECEIVE_COMPANY_USERS";
export const RESPONSIBLE_PERSON_ERROR = "dashboard/ClaimManagement/RESPONSIBLE_PERSON_ERROR";
export const RESET_RESPONSIBLE_PERSON_ERROR = "dashboard/ClaimManagement/RESET_RESPONSIBLE_PERSON_ERROR";
export const SET_PAGING = "dashboard/ClaimManagement/SET_PAGING";
export const SET_QUERY_FILTER = "dashboard/ClaimManagement/SET_QUERY_FILTER";
export const SHOW_DRUMS_MODAL = "dashboard/ClaimManagement/SHOW_DRUMS_MODAL";
export const HIDE_DRUMS_MODAL = "dashboard/ClaimManagement/HIDE_DRUMS_MODAL";

export const SET_DRUMS_IS_FETCHING = "dashboard/ClaimManagement/SET_DRUMS_IS_FETCHING";
export const RECEIVE_DRUMS = "dashboard/ClaimManagement/RECEIVE_DRUMS";
export const SET_DRUMS_PAGING = "dashboard/ClaimManagement/SET_DRUMS_PAGING";
export const SET_DRUMS_QUERY = "dashboard/ClaimManagement/SET_DRUMS_QUERY";
*/
