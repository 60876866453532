import { combineReducers } from 'redux'
//import { routerReducer } from "react-router-redux";
import { reducer as toastrReducer } from 'react-redux-toastr'
import { reducer as formReducer } from 'redux-form'
import { connectRouter } from 'connected-react-router'

import drums from './drums'
import locales from './locales'
import dashboard from './dashboard'
import authReducer from 'dashboard/modules/Auth/store/reducer'
import guestReducer from 'dashboard/modules/Guest/store/reducer'
import {
  companyLocationsReducer,
  companyReducer,
  employeesReducer,
} from 'dashboard/modules/CompanyProfile/store/reducer'
import mapReducer from 'dashboard/modules/Map/store/reducer'
import joinRequestReducer from 'dashboard/modules/UserManagement/store/reducer'
import companyUsersReducer from 'dashboard/modules/UserManagement/store/userReducer'
import drumManagementReducer from 'dashboard/modules/DrumManagement/store/reducer'
import drumTypeManagementReducer from 'dashboard/modules/DrumTypeManagement/store/reducer'
import cableManagementReducer from 'dashboard/modules/CableManagement/store/reducer'
import associatedCompaniesReducer from 'dashboard/modules/AssociatedCompanies/store/reducer'
import projectsReducer from 'dashboard/modules/Projects/store/reducer'
import ordersReducer from 'dashboard/modules/Orders/store/reducer'
import filterReducer from 'dashboard/modules/Filter/store/reducer'
import cableProductionReducer from 'dashboard/modules/CableProduction/store/reducer'
import drumConditions from 'dashboard/modules/DrumCondition/store/reducer'
import deliveries from 'dashboard/modules/Deliveries/store/reducer'
import preferences from 'dashboard/modules/Settings/store/reducer'
import notifications from 'dashboard/modules/Notification/store/reducer'
import deviceManagement from 'dashboard/modules/DeviceManagement/store/reducer'
import widgets from 'dashboard/modules/Dashboard/store/reducer'
import claimManagementReducer from 'dashboard/modules/ClaimManagement/store/reducer'

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    locales,
    drums,
    drumManagement: drumManagementReducer,
    drumTypeManagement: drumTypeManagementReducer,
    cableManagement: cableManagementReducer,
    associatedCompanies: associatedCompaniesReducer,
    dashboard,
    user: (state, action) => {
      let newState = state
      newState = authReducer(newState, action)
      newState = guestReducer(newState, action)
      return newState
    },
    company: (state, action) => {
      let newState = state
      newState = companyReducer(newState, action)

      if (newState) {
        newState = {
          ...newState,
          locations: companyLocationsReducer(newState.locations, action),
        }
      }

      return newState
    },
    toastr: toastrReducer,
    form: formReducer,
    map: mapReducer,
    joinRequests: joinRequestReducer,
    companyUsers: companyUsersReducer,
    projects: projectsReducer,
    orders: ordersReducer,
    filters: filterReducer,
    cableProduction: cableProductionReducer,
    claims: claimManagementReducer,
    drumConditions,
    employees: employeesReducer,
    deliveries,
    notifications,
    preferences,
    deviceManagement,
    widgets,
  })
