import { push } from 'connected-react-router'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import customerTemplates from '../../../translations/notificationTemplatesCustomer'
import commonTemplates from '../../../translations/notificationTemplatesCommon'
import navigationMessages from '../../../translations/sidebar'
import React, { Component } from 'react'
import vendorTemplates from '../../../translations/notificationTemplatesVendor'

import { DELIVERY_CREATED } from '../../../constants/notificationTypes'
import { getValuesFromMessage } from '../../../services/notificationMessageMapper'
import routes from '../../../etc/routes'

import '../style/style.scss'

class NotificationText extends Component {
  redirectToUserRequests = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.goTo(routes.userManagement.requests)
  }

  redirectToLinkedCompanies = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.props.goTo(routes.associatedCompanies.requests)
  }

  getTransformedValue(key, rawValues) {
    switch (key) {
      case 'user_join_requests':
        return (
          <a href="#" onClick={this.redirectToUserRequests}>
            <b>
              <FormattedMessage {...navigationMessages.userManagement} />
            </b>
          </a>
        )
      case 'company-link-requests':
        return (
          <a href="#" onClick={this.redirectToLinkedCompanies}>
            <b>
              <FormattedMessage {...navigationMessages.companyProfile} />
            </b>
          </a>
        )
      default:
        return <b>{rawValues[key]}</b>
    }
  }

  render() {
    const { message } = this.props
    let messageTemplates = commonTemplates
    let templateName = `${message.notificationType}`.toUpperCase()
    // TODO: need to really rework this template logic
    if (templateName === DELIVERY_CREATED) {
      messageTemplates = vendorTemplates
      templateName = 'DELIVERY_CREATED_TO_CUSTOMER'
    } else if (templateName === 'DELIVERY_ACCEPTED') {
      messageTemplates = customerTemplates
    }
    if (message.body.accessibilityType === 'OWNER') {
      messageTemplates = vendorTemplates
    }
    if (message.body.accessibilityType === 'RECIPIENT') {
      messageTemplates = customerTemplates
    }
    const rawValues = getValuesFromMessage(message)
    const values = {}

    Object.keys(rawValues).forEach((key) => (values[key] = this.getTransformedValue(key, rawValues)))

    const textResult = messageTemplates[templateName] ? (
      <FormattedMessage {...messageTemplates[templateName]} values={values} />
    ) : (
      templateName
    )

    return <span>{textResult}</span>
  }
}

const mapStateToProps = ({ user }) => ({
  userGroups: user.roles || [], // TODO: not needed/used anymore
})

const mapDispatchToProps = (dispatch) => ({
  goTo: (url) => dispatch(push(url)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationText)
