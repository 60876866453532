import { defineMessages } from 'react-intl';

const messages = defineMessages({
  DRUM_DAMAGED: {
    id: 'inbox-notifications-customer.DRUM_DAMAGED',
    defaultMessage: 'Drum {drum_name} has just been reported as damaged',
  },
  DRUM_READY_TO_RETURN: {
    id: 'inbox-notifications-customer.DRUM_READY_TO_RETURN',
    defaultMessage: 'Drum {drum_name} is ready to be picked up',
  },
  DRUM_REJECTED: {
    id: 'inbox-notifications-customer.DRUM_REJECTED',
    defaultMessage: 'Drum {drum_name} has been rejected upon delivery',
  },
  DRUM_EXPIRED: {
    id: 'inbox-notifications-customer.DRUM_EXPIRED',
    defaultMessage: 'Drum {drum_name} has expired',
  },
  DRUM_CABLE_CONSUMED: {
    id: 'inbox-notifications-customer.DRUM_CABLE_CONSUMED',
    defaultMessage: 'Cable of the drum {drum_name} has been used',
  },
  DRUM_CABLE_LOW: {
    id: 'inbox-notifications-customer.DRUM_CABLE_LOW',
    defaultMessage: 'Cable of the drum {drum_name} is running low',
  },
  DRUM_MOVED_OUT: {
    id: 'inbox-notifications-customer.DRUM_MOVED_OUT',
    defaultMessage: 'Drum {drum_name} from project {project_name} is moved from the current project location {x} meter(s) away',
  },
  ORDER_CREATED: {
    id: 'inbox-notifications-customer.ORDER_CREATED',
    defaultMessage: 'Your order {order_name} has been successfully created',
  },
  ORDER_DISPATCHED: {
    id: 'inbox-notifications-customer.ORDER_DISPATCHED',
    defaultMessage: 'Your order {order_name} from the vendor {vendor_name} has been dispatched',
  },
  ORDER_DELIVERED: {
    id: 'inbox-notifications-customer.ORDER_DELIVERED',
    defaultMessage: 'Your order {order_name} from the vendor {vendor_name} is delivered',
  },
  ORDER_EXPIRES_IN_X_DAYS: {
    id: 'inbox-notifications-customer.ORDER_EXPIRES_IN_X_DAYS',
    defaultMessage: 'Your order {order_name} from the vendor {vendor_name} is expiring in {x} days.',
  },
  ORDER_EXPIRES_TODAY: {
    id: 'inbox-notifications-customer.ORDER_EXPIRES_TODAY',
    defaultMessage: 'Your order {order_name} has been expired today',
  },
  ORDER_EXPIRED: {
    id: 'inbox-notifications-customer.ORDER_EXPIRED',
    defaultMessage: 'Your order {order_name} has been expired since {x} days',
  },
  DELIVERY_CREATED_TO_CUSTOMER: {
    id: 'inbox-notifications-customer.DELIVERY_CREATED_TO_CUSTOMER',
    defaultMessage: 'There has been created a new delivery from vendor {vendor_name}',
  },
  DELIVERY_CREATED_TO_VENDOR: {
    id: 'inbox-notifications-customer.DELIVERY_CREATED_TO_VENDOR',
    defaultMessage: 'There has been created a new delivery for vendor {vendor_name}',
  },
  DELIVERY_ACCEPTED: {
    id: 'inbox-notifications-customer.DELIVERY_ACCEPTED',
    defaultMessage: 'Delivery of order {order_name} is fully/partially accepted',
  },
  DELIVERY_ARRIVE_IN_X_DAYS: {
    id: 'inbox-notifications-customer.DELIVERY_ARRIVE_IN_X_DAYS',
    defaultMessage: 'Delivery of order {order_name} is arriving in {x} days.',
  },
  DELIVERY_ARRIVE_IN_X_HOURS: {
    id: 'inbox-notifications-customer.DELIVERY_ARRIVE_IN_X_HOURS',
    defaultMessage: 'Delivery of order {order_name} is arriving in {x} hours.',
  },
});

export default messages;
