import {
  RECEIVE_APPEND_DRUMS,
  RECEIVE_DRUMS,
  RECEIVE_DRUMS_TOTAL_COUNT,
  RECEIVE_MAP_DRUMS,
  UPDATE_DEVICE_TELEMETRY,
  UPDATE_DRUM,
} from 'dashboard/constants/actions'
import { defaultPagingLimit } from 'dashboard/constants/paging'
import endpoints from 'dashboard/etc/endpoints'
import fetch from 'dashboard/services/fetch'
import get from 'lodash/get'

import { getFilteredDrums } from './api'
import { requestDrumSummary } from './summary'

const receivedDrums = (drums = []) => ({
  type: RECEIVE_DRUMS,
  drums,
})

const receivedAppendDrums = (drums = []) => ({
  type: RECEIVE_APPEND_DRUMS,
  drums,
})

const receivedMapDrums = (drums = []) => ({
  type: RECEIVE_MAP_DRUMS,
  drums,
})

const receiveTotalDrumsCount = (totalDrumsCount) => ({
  type: RECEIVE_DRUMS_TOTAL_COUNT,
  totalDrumsCount,
})

const handleFetchError = (error) => {
  console.log('An error occurred.', error)
}

export const requestDrums = () =>
  fetch(endpoints.drums.list, {
    method: 'GET',
    mode: 'cors',
  }).catch(handleFetchError)

/**
 * paramsForFilterQuery = {
 *   vendors: Array of strings,
 *   logistics: Array of strings,
 *   customers: Array of strings,
 *   cableTypes: Array of strings,
 *   drumTypes: Array of strings,
 *   drumStates: Array of strings,
 *   drumMaterials: Array of strings
 *   q: String
 * }
 * */
export const fetchFilteredDrums = (props) => (dispatch) =>
  getFilteredDrums(props)
    .then((data) => {
      if (data.paging.offset === 0) {
        dispatch(receivedDrums(data.results))
      } else {
        dispatch(receivedAppendDrums(data.results))
      }

      dispatch(receiveTotalDrumsCount(get(data, 'paging.total', 0)))
    })
    .catch((error) => {
      dispatch(receivedDrums([]))
      dispatch(receiveTotalDrumsCount(0))
      throw new Error(error.message)
    })

/**
 * paramsForFilterQuery = {
 *   vendors: Array of strings,
 *   logistics: Array of strings,
 *   customers: Array of strings,
 *   cableTypes: Array of strings,
 *   drumTypes: Array of strings,
 *   drumStates: Array of strings,
 *   drumMaterials: Array of strings
 *   q: String
 * }
 * */
export const fetchFilteredMapDrums = (props) => (dispatch) =>
  getFilteredDrums(props)
    .then((data) => {
      dispatch(receivedMapDrums(data.results))
    })
    .catch((error) => {
      dispatch(receivedMapDrums([]))
      throw new Error(error.message)
    })

export const fetchDrums = (props) => (dispatch) => {
  getFilteredDrums(props).then((json) => {
    dispatch(receivedDrums(json.results))
    // drumsFetchTimer = setTimeout(() => dispatch(fetchDrums()), config.polling.drumsInterval);
  })
}

export const updateDeviceTelemetry = (drumId, telemetry) => ({
  type: UPDATE_DEVICE_TELEMETRY,
  drumId,
  telemetry,
})

export const updateDrum = (drum) => ({
  type: UPDATE_DRUM,
  drum,
})

export const refetchDrumInList = (drumId) => {
  return (dispatch) => {
    requestDrumSummary(drumId, dispatch).then((json) => {
      dispatch(updateDrum(json.result))
    })
  }
}

export const getFirstDrums = (count = defaultPagingLimit) => {
  const filterRequest = {
    paging: {
      limit: count,
      offset: 0,
    },
    filter: {
      hasLocation: true,
    },
  }

  return getFilteredDrums(filterRequest).then((data) => data.results)
}
