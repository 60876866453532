import endpoints from 'dashboard/etc/endpoints'
import fetch from 'dashboard/services/fetch'

export const getCompany = (companyId) =>
  fetch(endpoints.companyManagement.item.replace('{id}', companyId), {
    method: 'GET',
    mode: 'cors',
  })

export const updateCompany = (companyId, params) =>
  fetch.put(endpoints.companyManagement.item.replace('{id}', companyId), { body: JSON.stringify(params) })

export const getLocations = (params) =>
  fetch.post(endpoints.companyManagement.locations, {
    body: JSON.stringify(params),
  })

export const getLocation = (companyId, locationId) =>
  fetch(locationPath(companyId, locationId), {
    method: 'GET',
    mode: 'cors',
  })

export const createLocation = (params) => {
  const requestHeaders = new Headers()
  const payload = { ...params }

  requestHeaders.append('Accept', 'application/json')
  requestHeaders.append('Content-Type', 'application/json')

  return fetch(endpoints.companyManagement.locationCreate, {
    method: 'POST',
    headers: requestHeaders,
    mode: 'cors',
    body: JSON.stringify(payload),
  })
}

export const updateLocation = (companyId, locationId, params) => {
  const requestHeaders = new Headers()
  const payload = {
    address: params.address,
    latitude: params.latitude,
    longitude: params.longitude,
    title: params.title,
  }

  requestHeaders.append('Accept', 'application/json')
  requestHeaders.append('Content-Type', 'application/json')

  return fetch(locationPath(companyId, locationId), {
    method: 'PUT',
    headers: requestHeaders,
    mode: 'cors',
    body: JSON.stringify(payload),
  })
}

export const deleteLocation = (companyId, locationId) =>
  fetch(locationPath(companyId, locationId), {
    method: 'DELETE',
    mode: 'cors',
  })

export const deleteCompany = (companyId) =>
  fetch(endpoints.companyManagement.item.replace('{id}', companyId), {
    method: 'DELETE',
    mode: 'cors',
  })

const locationsPath = (companyId) => endpoints.companyManagement.locationCreate.replace('{id}', companyId)

const locationPath = (companyId, locationId) =>
  endpoints.companyManagement.location.replace('{id}', companyId).replace('{locationId}', locationId)
