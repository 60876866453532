export const defaultPagingLimit = 20
export const defaultPagingLimitDashboard = 5
export const defaultPagingLimitFilter = 5
export const defaultPagingLimitModal = 10

export default {
  offset: 0,
  limit: defaultPagingLimit,
  total: 0,
}
