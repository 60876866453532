import get from 'lodash/get'
import has from 'lodash/has'
import {
  MQTT_CONNECT,
  MQTT_CONNECTED,
  MQTT_DISCONNECT,
  MQTT_DISCONNECTED,
  MQTT_RECONNECT,
} from 'dashboard/constants/actions'
import { refetchDrumInList, updateDeviceTelemetry } from 'dashboard/store/actions/drums/items'

export const connect = (url, reconnectPeriod) => ({
  type: MQTT_CONNECT,
  payload: {
    url,
    reconnectPeriod,
  },
})

export const connected = () => ({
  type: MQTT_CONNECTED,
})

export const disconnect = () => ({
  type: MQTT_DISCONNECT,
})

export const disconnected = () => ({
  type: MQTT_DISCONNECTED,
})

export const reconnect = () => ({
  type: MQTT_RECONNECT,
})

export const receiveData = (data) => {
  return (dispatch) => {
    if (has(data, 'current.state.reported')) {
      const telemetry = get(data, 'current.state.reported')
      if (telemetry.drumId) {
        dispatch(updateDeviceTelemetry(telemetry.drumId, telemetry))
        dispatch(refetchDrumInList(telemetry.drumId))
      }
    }
  }
}
