export const AWS_CONNECT = 'AWS_CONNECT'

export const MQTT_CONNECT = 'MQTT_CONNECT'
export const MQTT_CONNECTED = 'MQTT_CONNECTED'
export const MQTT_DISCONNECT = 'MQTT_DISCONNECT'
export const MQTT_DISCONNECTED = 'MQTT_DISCONNECTED'
export const MQTT_RECONNECT = 'MQTT_RECONNECT'

export const UPDATE_DEVICE_TELEMETRY = 'UPDATE_DEVICE_TELEMETRY'
export const UPDATE_DRUM = 'UPDATE_DRUM'

export const RECEIVE_DRUMS = 'RECEIVE_DRUMS'
export const RECEIVE_DRUMS_TOTAL_COUNT = 'RECEIVE_DRUMS_TOTAL_COUNT'
export const RECEIVE_APPEND_DRUMS = 'RECEIVE_APPEND_DRUMS'
export const RECEIVE_MAP_DRUMS = 'RECEIVE_MAP_DRUMS'
export const RECEIVE_DRUM_SUMMARY = 'RECEIVE_DRUM_SUMMARY'
export const RECEIVE_DRUM_EVENTS = 'RECEIVE_DRUM_EVENTS'

export const RECEIVE_COMPANIES_FOR_MANAGEMENT = 'RECEIVE_COMPANIES_FOR_MANAGEMENT'
export const RECEIVE_CURRENT_COMPANIES = 'RECEIVE_CURRENT_COMPANIES'
export const DELETE_COMPANY_FOR_MANAGEMENT = 'DELETE_COMPANY_FOR_MANAGEMENT'

export const DRUM_FILTERS_RESET_ALL = 'DRUM_FILTERS_RESET_ALL'
export const DRUM_FILTERS_CLEAR_FILTER = 'DRUM_FILTERS_REMOVE'
export const DRUM_FILTERS_CHANGE_FILTER_VALUE = 'DRUM_FILTERS_CHANGE_FILTER_VALUE'
export const DRUM_FILTERS_SELECT_ALL_FILTER = 'DRUM_FILTERS_SELECT_ALL_FILTER'
export const DRUM_FILTERS_GET_VALUES_FOR_FILTER = 'DRUM_FILTERS_GET_VALUES_FOR_FILTER'
export const DRUM_FILTERS_CLEAR_VALUES_FOR_FILTER = 'DRUM_FILTERS_CLEAR_VALUES_FOR_FILTER'
export const DRUM_FILTERS_SET_IS_LOADING = 'DRUM_FILTERS_SET_IS_LOADING'

export const QUERY_FILTER_SET_QUERY = 'QUERY_FILTER_SET_QUERY'

export const DRUM_PAGING_SET_OFFSET = 'DRUM_PAGING_SET_OFFSET'
export const DRUM_PAGING_IS_FETCHING = 'DRUM_PAGING_IS_FETCHING'

export const PUT_POINTS_ON_MAP = 'PUT_POINTS_ON_MAP'
export const REMOVE_POINT_FROM_MAP = 'REMOVE_POINT_FROM_MAP'

export const RECEIVE_PLANTS = 'RECEIVE_PLANTS'

export const RECEIVE_DRIVERS = 'RECEIVE_DRIVERS'

export const RECEIVE_TRUCKS = 'RECEIVE_TRUCKS'

export const DRUM_STATE_UPDATE = 'DRUM_STATE_UPDATE'
export const DRUM_STATE_UPDATE_SUCCESS = 'DRUM_STATE_UPDATE_SUCCESS'
export const DRUM_STATE_UPDATE_FAILURE = 'DRUM_STATE_UPDATE_FAILURE'

export const CHANGE_LOCALE = 'CHANGE_LOCALE'

export const DASHBOARD_RECEIVE_DRUMS = 'DASHBOARD_RECEIVE_DRUMS'

export const MAP_SHOW_EVENTS = 'MAP_SHOW_EVENTS'
