import defaultPagingValues from 'dashboard/constants/paging'
import {
  DEVICE_DATA_RECEIVED,
  DEVICES_DATA_RECEIVED,
  DEVICES_PAGING_RECEIVED,
  SET_QUERY_FILTER,
} from '../constants/actions'

const defaultState = {
  list: [],
  paging: defaultPagingValues,
  device: null,
  queryFilter: '',
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case DEVICES_PAGING_RECEIVED:
      return {
        ...state,
        paging: action.paging,
      }
    case DEVICES_DATA_RECEIVED:
      return {
        ...state,
        list: action.data,
      }
    case DEVICE_DATA_RECEIVED:
      return {
        ...state,
        device: action.data,
      }
    case SET_QUERY_FILTER:
      return {
        ...state,
        queryFilter: action.query,
      }
    default:
      return state
  }
}
