import { DASHBOARD_RECEIVE_DRUMS } from 'dashboard/constants/actions'

export default (items = [], action) => {
  switch (action.type) {
    case DASHBOARD_RECEIVE_DRUMS:
      return [...action.drums]
    default:
      return items
  }
}
