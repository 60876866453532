import React from 'react'
import beautifyConstant from 'dashboard/services/beautifyConstant'
import { injectIntl } from 'react-intl'

class BeautifyConfigure extends React.Component {
  componentDidMount() {
    beautifyConstant.setFormatter(this.props.intl.formatMessage)
  }

  render() {
    return null
  }
}

export default injectIntl(BeautifyConfigure)
