import { connected, disconnected, receiveData, reconnect } from 'dashboard/store/actions/mqtt'
import { getAwsConfig, getConfig } from 'dashboard/etc/getConfig'
import { MQTT_CONNECT, MQTT_DISCONNECT } from 'dashboard/constants/actions'
import mqtt from 'mqtt'

const awsConfig = getAwsConfig()
const config = getConfig()

const mqttMiddleware = (function () {
  let client = null
  let completeStop = false

  const onConnect = (dispatch) => {
    if (config.debug) {
      console.log('MQTT: client connected')
    }
    dispatch(connected())
    client.subscribe(awsConfig.iot.mqttTopic)
    setTimeout(() => {
      client.end()
    }, 270000) // 4.5 minutes
  }

  const onClose = (dispatch) => {
    if (config.debug) {
      console.log('MQTT: client disconnected')
    }
    client.end()
    dispatch(reconnect())
  }

  const onMessage = (topic, msg, dispatch) => {
    const message = msg.toString()
    if (config.debug) {
      console.info('MQTT: Message recieved.\nTopic: %s\nPayload: %s', topic, message)
    }
    if (topic === config.mqttTopic) {
      const data = JSON.parse(message)
      dispatch(receiveData(data))
    }
  }

  return (store) => {
    return (next) => (action) => {
      switch (action.type) {
        case MQTT_CONNECT:
          if (client !== null) {
            client.end()
          }
          const url = action.payload.url
          const reconnectPeriod = action.payload.reconnectPeriod
          client = mqtt.connect(url, { reconnectPeriod })

          client.on('connect', () => {
            onConnect(store.dispatch)
          })

          client.on('close', () => {
            if (!completeStop) {
              onClose(store.dispatch)
            } else {
              completeStop = false
              store.dispatch(disconnected())
            }
          })

          client.on('message', (topic, msg) => {
            onMessage(topic, msg, store.dispatch)
          })

          client.on('error', (error) => {
            console.log(error)
          })
          break
        case MQTT_DISCONNECT:
          if (client !== null) {
            completeStop = true
            client.end()
          }
          client = null
          break
        default:
          return next(action)
      }
    }
  }
})()

export default mqttMiddleware
