import {
  DRUM_STATE_UPDATE,
  DRUM_STATE_UPDATE_FAILURE,
  DRUM_STATE_UPDATE_SUCCESS,
  RECEIVE_DRUM_SUMMARY,
  MAP_SHOW_EVENTS,
} from 'dashboard/constants/actions'
import endpoints from 'dashboard/etc/endpoints'
import { changeDrumState } from 'dashboard/services/api'
import fetch from 'dashboard/services/fetch'
import { toastr } from 'react-redux-toastr'
import { geocodeCoordinates } from 'dashboard/services/googleMap'

let drumSummaryFetchTimer = null

const clearTimer = () => {
  if (drumSummaryFetchTimer) {
    clearTimeout(drumSummaryFetchTimer)
    drumSummaryFetchTimer = null
  }
}

const handleFetchError = (error) => console.log('An error occured.', error)

const action = (type) => ({
  type,
})

export const receivedDrumSummary = (drumSummary) => ({
  type: RECEIVE_DRUM_SUMMARY,
  drumSummary,
})

const drumStateUpdateError = (error) => ({
  type: DRUM_STATE_UPDATE_FAILURE,
  message: error,
})

const drumStateUpdateSuccess = (newState) => ({
  type: DRUM_STATE_UPDATE_SUCCESS,
  newState,
})

export const clearDrumSummary = () => {
  clearTimer()
  return receivedDrumSummary(null)
}

export const requestDrumSummary = (id) => fetch(endpoints.drums.summary.replace('{id}', id)).catch(handleFetchError)

export const fetchDrumSummary = (id) => (dispatch) =>
  requestDrumSummary(id).then((summary) => {
    if (summary.location && summary.location.fromDevice) {
      geocodeCoordinates(summary.location.latitude, summary.location.longitude).then((res) => {
        summary.location.address = res && res.length > 0 ? res[0].formatted_address : '–'
        dispatch(receivedDrumSummary(summary))
      })
    } else {
      dispatch(receivedDrumSummary(summary))
    }
  })

export const setDrumState = (drumId, state) => (dispatch) => {
  dispatch(action(DRUM_STATE_UPDATE))
  changeDrumState(drumId, state)
    .then(() => {
      dispatch(drumStateUpdateSuccess(state))
      dispatch(fetchDrumSummary(drumId))
      toastr.success('Status updated')
    })
    .catch((error) => {
      dispatch(drumStateUpdateError(error.message))
      toastr.error('Status not updated', error.message)
    })
}

export const showEventMarkers = (showValue) => ({
  type: MAP_SHOW_EVENTS,
  showEvents: showValue,
})
