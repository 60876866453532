import { defineMessages } from 'react-intl';

const messages = defineMessages({
  accepted: {
    id: 'order-item-states.accepted',
    defaultMessage: 'Accepted',
  },
  created: {
    id: 'order-item-states.created',
    defaultMessage: 'Created',
  },
  rejected: {
    id: 'order-item-states.rejected',
    defaultMessage: 'Rejected',
  },
  open: {
    id: 'order-item-states.open',
    defaultMessage: 'Open',
  },
  fulfilled: {
    id: 'order-item-states.fulfilled',
    defaultMessage: 'Fulfilled',
  },
  released: {
    id: 'order-item-states.released',
    defaultMessage: 'Released',
  },
  closed: {
    id: 'order-item-states.closed',
    defaultMessage: 'Closed',
  },
  canceled: {
    id: 'order-item-states.canceled',
    defaultMessage: 'Canceled',
  },
});

export default messages;
